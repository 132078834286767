interface Permission {
    action: string;
    subjectClass: string;
}

export const hasAnyPermission = (
    permissions: Permission[],
    actions: string[] | string,
    subjectClasses: string[] | string
): boolean => {
    const actionsArray = Array.isArray(actions) ? actions : [actions];
    const subjectClassesArray = Array.isArray(subjectClasses) ? subjectClasses : [subjectClasses];

    if (actionsArray.includes('view')) {
        return permissions.some((permission) =>
            subjectClassesArray.includes(permission.subjectClass)
        );
    } else {
        return permissions.some((permission) =>
            actionsArray.includes(permission.action) &&
            subjectClassesArray.includes(permission.subjectClass)
        );
    }
};
