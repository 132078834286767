import React from 'react';
import {ElementProps, DropdownElement} from "types/documentTemplateElement";
import CustomSelect from "../inputs/CustomSelect";

interface DropdownElementProps extends ElementProps<DropdownElement> {
}

const DropdownElementComponent: React.FC<DropdownElementProps> = ({element, updateElement}) => {
    return (
        <div className="w-full m-8">
            <div className="flex flex-col gap-[0.625rem] w-full">
                {element.showLabel && (
                <label htmlFor="roleId"
                       className="w-full block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                    {element.label}
                </label>)}
                <CustomSelect
                    options={element.options.map((option) => ({label: option, value: option}))}
                    isMulti={element.isMulti}
                    onChange={() => {
                    }}
                    value={element.isMulti ? [] : ""}
                />
            </div>
        </div>
    );
};

export default DropdownElementComponent;
