import  React from 'react';



const UserIcon = () => (

    <svg width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="users">
            <path id="Vector"
                  d="M16 21V19C16 17.9391 15.5786 16.9217 14.8284 16.1716C14.0783 15.4214 13.0609 15 12 15H6C4.93913 15 3.92172 15.4214 3.17157 16.1716C2.42143 16.9217 2 17.9391 2 19V21"
                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_2"
                  d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z"
                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_3"
                  d="M22 21V19C21.9993 18.1137 21.7044 17.2528 21.1614 16.5523C20.6184 15.8519 19.8581 15.3516 19 15.13"
                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_4"
                  d="M16 3.13C16.8604 3.35031 17.623 3.85071 18.1676 4.55232C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89318 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88"
                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
    </svg>
);
export default UserIcon;
