import { createEntitySlice } from 'utils/createEntitySlice';
import { TrainingThunks } from './trainingThunks';
import { Training } from 'types/training';

export interface TrainingState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    trainings: Training[];
    training: Training | null;
    isEditing: boolean;
    activeTab: string;
    pagy: any;
}

const initialState: Partial<TrainingState> = {
    trainings: [],
    training: null,
    isEditing: false,
    activeTab: 'All',
    pagy: {},
};

const trainingSlice = createEntitySlice<Training>({
    name: 'training',
    initialState,
    thunks: TrainingThunks,
    entityKey: 'training',
    entitiesKey: 'trainings',
    reducers: {
    },
});

export const { setEntity: setTraining, setIsEditing, setActiveTab } = trainingSlice.actions;
export default trainingSlice.reducer;
