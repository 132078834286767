import { HrDocumentService } from 'services/hrDocumentService';
import { createEntityThunks } from 'utils/createEntityThunks';
import { HrDocument } from 'types/hrDocument';

const hrDocumentFactory = (data: any) => new HrDocument(data);

export const HrDocumentThunks = createEntityThunks<HrDocument>(
    'hrDocument',
    HrDocumentService,
    { activate: true, deactivate: true },
    hrDocumentFactory
)
