
import {createEntitySlice} from "utils/createEntitySlice";
import {UserMedicalDocument} from "types/userMedicalDocument";
import {UserMedicalDocumentThunks} from "features/userMedicalDocument/userMedicalDocumentThunks";

export interface UserMedicalDocumentState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    userMedicalDocuments?: any;
    refresh: number;
    pagy?: any;
    userMedicalDocument?: UserMedicalDocument | null;
    isEditing?: boolean;

}

const initialState: UserMedicalDocumentState = {
    status: 'idle',
    error: null,
    userMedicalDocuments: [],
    refresh: 0,
    userMedicalDocument: null,
    pagy: {},
    isEditing: false,

};
const userMedicalDocumentSlice = createEntitySlice<UserMedicalDocument>({
    name: 'document',
    initialState,
    thunks: UserMedicalDocumentThunks,
    entityKey: 'userMedicalDocument',
    entitiesKey: 'userMedicalDocuments',
    reducers: {
    },
});


export const { setEntity: setUserMedicalDocument, setIsEditing} = userMedicalDocumentSlice.actions;
export default userMedicalDocumentSlice.reducer;
