import React, {useEffect, useState} from 'react';
import PasswordInput from "components/inputs/PasswordInput";
import {validatePassword} from "utils/validators";
import {HSStaticMethods} from "preline";
import DataCheckIcon from "assets/images/icons/DataCheckIcon";
import DataUnCheckIcon from "assets/images/icons/DataUnCheckIcon";

interface SetupPasswordProps {
    formData: {
        password: string;
        confirmPassword: string;
    };
    setFormData: (data: (prevState: any) => any) => void;
}
const SetupPassword: React.FC<SetupPasswordProps> = (
    {
        formData,
        setFormData
    }
) => {

    const [errors] = useState<string[]>([]);


    const [passwordError, setPasswordError] = useState<boolean>(false);
    const [passwordConfirmError, setPasswordConfirmError] = useState<boolean>(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('');
    const [showPassword, setShowPassword] = useState(false);


    const [isFormValid, setIsFormValid] = useState(false);
    const [showValidations, setShowValidations] = useState(false);


    useEffect(() => {
        HSStaticMethods.autoInit();
    }, []);

    useEffect(() => {
        const {password, confirmPassword} = formData;
        const isPasswordValid = isPasswordStrong(password);
        const isConfirmPasswordValid = password === confirmPassword;


        setIsFormValid(isPasswordValid && isConfirmPasswordValid && formData.password !== '' && formData.confirmPassword !== '');
        if (password && confirmPassword) {
            if (password === confirmPassword) {
                setPasswordError(false);
                setPasswordConfirmError(false);
            } else {
                setPasswordError(true);
                setPasswordConfirmError(true);
            }
        }
    }, [formData]);


    const isPasswordStrong = (password: string) => {
        if (password === '') return true;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        const hasMinLength = password.length >= 8;

        return hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar && hasMinLength;
    };


    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {id, value} = e.target;
        const {password, confirmPassword} = formData;
        setFormData(prevState => ({
            ...prevState,
            [id]: value,
        }));
        if (validatePassword(value) && password === confirmPassword) {
            setPasswordError(false);
        }
    };

    const onPasswordBlur = () => {
        setShowValidations(false)
        const {password, confirmPassword} = formData;
        const isPasswordValid = isPasswordStrong(password);
        const isConfirmPasswordValid = password === confirmPassword;

        if (!isPasswordValid) {
            setPasswordError(true);
            setPasswordErrorMessage('Password is not strong enough');
        }
    }

    const onPasswordFocus = () => {
        setShowValidations(true);
        setPasswordError(false);
        setPasswordErrorMessage('');
    }



    return (
        <div className="mt-6 needs-validation self-stretch w-full" >
            <div className="flex flex-col space-y-6">
                <PasswordInput
                    label="Password"
                    password={formData.password}
                    onPasswordChange={handlePasswordChange}
                    passwordError={passwordError}
                    showPassword={showPassword}
                    onToggleShowPassword={() => setShowPassword(!showPassword)}
                    inputId="password"
                    errorMessage={passwordErrorMessage}
                    onFocus={onPasswordFocus}
                    onBlur={onPasswordBlur}
                />
                <div className={`relative ${showValidations ? '' : 'hidden'}`}>
                    <div className="absolute z-10 w-full p-4 bg-white rounded-lg shadow  border dark:bg-neutral-800">
                        <div className="flex mb-2">
                            <div className="flex-1">
                                <div id="hs-strong-password" data-hs-strong-password='{
                                     "target": "#password",
                                     "hints": "#hs-strong-password-hints",
                                     "stripClasses": "hs-strong-password:opacity-100 hs-strong-password-accepted:bg-cyan-400 h-2 flex-auto rounded-full bg-cyan-400 opacity-30 mx-1" }
                                     ' className="flex mt-2 -mx-1"></div>
                            </div>
                        </div>
                        <div id="hs-strong-password-hints" className="mb-3">
                            <div>
                                <span className="text-sm text-gray-800 dark:text-neutral-200">Level: </span>
                                <span
                                    data-hs-strong-password-hints-weakness-text='["Empty", "Weak", "Medium", "Strong", "Very Strong", "Super Strong"]'
                                    className="text-sm font-semibold text-gray-800 dark:text-neutral-200"></span>
                            </div>
                            <h4 className="my-2 text-sm font-semibold text-gray-800 dark:text-white">
                                Your password must contain:
                            </h4>
                            <ul className="space-y-1 text-sm font-light text-gray-500 dark:text-neutral-500">
                                <li data-hs-strong-password-hints-rule-text="min-length"
                                    className="hs-strong-password-active:text-green-400 flex items-center gap-x-2">
                                <span className="hidden" data-check="">
                                  <DataCheckIcon/>
                                </span>
                                    <span data-uncheck="">
                                    <DataUnCheckIcon/>
                                </span>
                                    Minimum number of characters is 6.
                                </li>
                                <li data-hs-strong-password-hints-rule-text="lowercase"
                                    className="hs-strong-password-active:text-green-400 flex items-center gap-x-2">
                                <span className="hidden" data-check="">
                                  <DataCheckIcon/>
                                </span>
                                    <span data-uncheck="">
                                    <DataUnCheckIcon/>
                                </span>
                                    Should contain lowercase.
                                </li>
                                <li data-hs-strong-password-hints-rule-text="uppercase"
                                    className="hs-strong-password-active:text-green-400 flex items-center gap-x-2">
                                <span className="hidden" data-check="">
                                  <DataCheckIcon/>
                                </span>
                                    <span data-uncheck="">
                                   <DataUnCheckIcon/>
                                 </span>
                                    Should contain uppercase.
                                </li>
                                <li data-hs-strong-password-hints-rule-text="numbers"
                                    className="hs-strong-password-active:text-green-400 flex items-center gap-x-2">
                                <span className="hidden" data-check="">
                                  <DataCheckIcon/>
                                </span>
                                    <span data-uncheck="">
                                  <DataUnCheckIcon/>
                                </span>
                                    Should contain numbers.
                                </li>
                                <li data-hs-strong-password-hints-rule-text="special-characters"
                                    className="hs-strong-password-active:text-green-400 flex items-center gap-x-2">
                                <span className="hidden" data-check="">
                                    <DataCheckIcon/>
                                </span>
                                    <span data-uncheck="">
                                    <DataUnCheckIcon/>
                                </span>
                                    Should contain special characters.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <PasswordInput
                    label="Confirm Password"
                    password={formData.confirmPassword}
                    onPasswordChange={handlePasswordChange}
                    passwordError={passwordConfirmError}
                    errorMessage={passwordConfirmError ? 'Passwords do not match' : ''}
                    showPassword={showPassword}
                    onToggleShowPassword={() => setShowPassword(!showPassword)}
                    inputId="confirmPassword"
                />
            </div>
            {errors.length > 0 && (
                <div className="text-xs mt-2 text-red-500">
                    <ul>
                        {errors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default SetupPassword;
