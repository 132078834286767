import React from 'react';

const BarsDownMenuIcon = () => (

    <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="list-end">
            <path id="Vector" d="M9.33333 7H1.75" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_2" d="M9.33333 3.5H1.75" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_3" d="M5.83333 10.5H1.75" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_4"
                  d="M12.25 3.5V9.33333C12.25 9.64275 12.1271 9.9395 11.9083 10.1583C11.6895 10.3771 11.3928 10.5 11.0833 10.5H8.16667"
                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_5" d="M9.33334 9.33337L8.16667 10.5L9.33334 11.6667" stroke="black" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </g>
    </svg>


);
export default BarsDownMenuIcon;
