import React, { useState} from "react";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import TableCell from "components/table/TableCell";
import { Location } from "types/location";
import Table from "components/table/Table";
import { SearchType } from "types/search";
import { LocationThunks } from "./locationThunks";
import { setIsEditing, setLocation } from "features/location/locationSlice";
import BlueButton from "components/BlueButton";
import PlusIcon from "assets/images/icons/PlusIcon";
import { setActiveTab } from "features/location/locationSlice";
import {formatPhoneNumber} from "utils/caseConverter";
import CheckCircleIcon from "assets/images/icons/CheckCircleIcon";
import XCircleIcon from "assets/images/icons/XCircleIcon";
import {useDropdownActionMenu} from "hooks/useDropdownActionMenu";
import DotsMenu from "components/DotsMenu";
import TrashIcon from "assets/images/icons/TrashIcon";

const LocationTable: React.FC<{ openDrawer: () => void }> = ({ openDrawer }) => {
    const locations = useSelector((state: RootState) => state.location.locations);
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.location.pagy);
    const activeTab = useSelector((state: RootState) => state.location.activeTab);
    const loading = useSelector((state: RootState) => state.location.status === 'loading');
    const {
        setDotsMenuIsOpen
    } = useDropdownActionMenu();

    
    interface VisibleColumnsType {
        name: boolean;
        address: boolean;
        city: boolean;
        state: boolean;
        staff: boolean;
        zip: boolean;
        phoneNumber: boolean;
        timeZone: boolean;
        npi: boolean;
        taxId: boolean;
        taxonomyCode: boolean;
        description: boolean;
        status: boolean;
    }

    const [visibleColumns, setVisibleColumns] = useState<VisibleColumnsType>({
        name: true,
        address: true,
        city: true,
        state: true,
        staff: true,
        zip: true,
        phoneNumber: true,
        timeZone: true,
        npi: true,
        taxId: true,
        taxonomyCode: true,
        description: true,
        status: true,
    });

    const columnOptions = [
        { label: 'Name', value: 'name', isVisible: visibleColumns.name },
        { label: 'Description', value: 'description', isVisible: visibleColumns.description },
        { label: 'Status', value: 'status', isVisible: visibleColumns.status },
        { label: 'Address', value: 'address', isVisible: visibleColumns.address },
        { label: 'Phone number', value: 'phoneNumber', isVisible: visibleColumns.phoneNumber },
        { label: 'Time zone', value: 'timeZone', isVisible: visibleColumns.timeZone },
        { label: 'Taxonomy code', value: 'taxonomyCode', isVisible: visibleColumns.taxonomyCode },
        { label: 'Tax ID', value: 'taxId', isVisible: visibleColumns.taxId },
        { label: 'NPI', value: 'npi', isVisible: visibleColumns.npi },


    ];

    const searchByOptions = [
        { label: 'Name', value: 'name', operator: 'like' },
        { label: 'Address', value: 'address', operator: 'like' },

    ];

    const fetchData = async ({ page, search, sortField, sortDirection, rowsPerPage }: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        if (activeTab === 'Active' || activeTab === 'Inactive') {
            search.where.status = {
                operator: '==',
                value: activeTab.toLowerCase()
            };
        } else {
            search.where.status = {
                operator: '==',
                value: ''
            }
        }
        await dispatch(LocationThunks.index({ page, search, sortField, sortDirection, rowsPerPage }));
    };

    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };

    const handleOpenNewLocationDrawer = () => {
        dispatch(setLocation({ name: '', address: '', city: '', state: '', zip: '', phoneNumber: '', timeZone: '', npi: '', taxId: '', taxonomyCode: '', locationType: '', description: '' }));
        dispatch(setIsEditing(true));
        openDrawer();
    };

    const handleOpenDrawer = (id: string) => async () => {
        dispatch(setIsEditing(false));
        openDrawer();
        await dispatch(LocationThunks.show(id));
    };

    const handleDeactivate = (locationId: string) => {
        const location = new Location({id: locationId, status: 'inactive'})
        dispatch(LocationThunks.deactivate(locationId));
        setDotsMenuIsOpen(null);
    };

    const handleReactivate = (locationId: string) => {
        const location = new Location({id: locationId, status: 'active'})
        dispatch(LocationThunks.activate(locationId));
        setDotsMenuIsOpen(null);
    };

    const handleDelete = (locationId: string) => {
        dispatch(LocationThunks.delete(locationId) as any);
        setDotsMenuIsOpen(null);
    }

    const handleEdit = (locationId: string) => {
        dispatch(setIsEditing(true));
        openDrawer();
        dispatch(LocationThunks.show(locationId) as any);
    }


    return (
        <Table
            fetchData={fetchData}
            pagy={pagy}
            pageable={true}
            recordsName={'Locations'}
            searchByOptions={searchByOptions}
            activeTab={activeTab}
            setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
            tabs={['All', 'Active', 'Inactive']}
            columnOptions={columnOptions}
            onColumnToggle={handleColumnToggle}
            columns={true}
            loading={loading}
            blueButton={<BlueButton onClick={handleOpenNewLocationDrawer} label="" icon={<PlusIcon />} />}
        >
            <thead>
            <tr>
                <th scope="col" className="px-3  pt-3.5  flex justify-center">
                    <input type="checkbox"
                           className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"/>
                </th>
                {visibleColumns.name && <TableHeader label="Name" sortBy="name"/>}
                {visibleColumns.description && <TableHeader label="Description" sortBy="address"/>}
                {visibleColumns.status && <TableHeader label="Status" sortBy="status"/>}
                {visibleColumns.address && <TableHeader label="Address"/>}
                {visibleColumns.phoneNumber && <TableHeader label="Phone number" sortBy="phoneNumber"/>}
                {visibleColumns.timeZone && <TableHeader label="Time zone"/>}
                {visibleColumns.taxonomyCode && <TableHeader label="Taxonomy code"/>}
                {visibleColumns.taxId && <TableHeader label="Tax ID"/>}
                {visibleColumns.npi && <TableHeader label="NPI"/>}

                <TableHeader label=""/>
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
            {locations.map((location: Location) => {
                    const userOptions = [];
                    userOptions.push({
                        label: "Edit",
                        onClick: () => handleEdit(location.id),
                        hoverClass: "hover:bg-cyan-100",
                    });

                    if (location.status === "Active") {
                        userOptions.push({
                            label: "Deactivate",
                            onClick: () => handleDeactivate(location.id),
                        hoverClass: "hover:bg-red-100 hover:text-red-800",
                     });
                    }
                    if (location.status === "Inactive") {
                        userOptions.push({
                            label: "Activate",
                            onClick: () => handleReactivate(location.id),
                            hoverClass: "hover:bg-cyan-100",
                        });
                    }
                userOptions.push({
                    label: "Delete",
                    onClick: () => handleDelete(location.id),
                    icon: <TrashIcon/>,
                    hoverClass: "hover:bg-red-100 hover:text-red-800",
                });
                return (

                    <TableRow
                        key={location.id}
                    >

                        <td scope="col" className="pt-3.5 flex justify-center">
                            <input
                                type="checkbox"
                                className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                onClick={(e) => e.stopPropagation()}/>
                        </td>

                        {visibleColumns.name &&
                            <TableCell>
                                <div className=" relative group">
                                    <div className="w-full flex items-center gap-x-3">
                                        <div className="flex-grow">
                                        <span className="text-sm  cursor-pointer font-medium dark:text-neutral-200 underline text-cyan-600"
                                              onClick={handleOpenDrawer(location.id)}
                                        >
                                            {location.name}

                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </TableCell>}
                        {visibleColumns.description && (
                            <TableCell className="whitespace-nowrap overflow-hidden text-ellipsis">
                                <div style={{ maxWidth: '150px' }} className="whitespace-nowrap overflow-hidden text-ellipsis">
                                    {location.description}
                                </div>
                            </TableCell>
                        )}
                        {visibleColumns.status && (
                            <TableCell>
                            <span
                                className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-28
                                    ${location.status === 'Active' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-700'}`}
                            >
                                {location.status === 'Active' ? <CheckCircleIcon className="mr-2"/> :
                                    <XCircleIcon className="mr-2"/>}

                                <div className="pl-1">
                                    {location.status === 'Active' ? location.status : 'Inactive'}
                                </div>
                            </span>
                            </TableCell>)}
                        {visibleColumns.address && <TableCell>{location.address}</TableCell>}
                        {visibleColumns.phoneNumber && <TableCell>{formatPhoneNumber(location.phoneNumber)}</TableCell>}
                        {visibleColumns.timeZone && <TableCell>{location.timeZone}</TableCell>}
                        {visibleColumns.taxonomyCode && <TableCell>{location.taxonomyCode}</TableCell>}
                        {visibleColumns.taxId && <TableCell>{location.taxId}</TableCell>}
                        {visibleColumns.npi && <TableCell>{location.npi}</TableCell>}



                        <TableCell>
                            <DotsMenu options={userOptions}/>
                        </TableCell>
                    </TableRow>
                )
                }
            )}
            </tbody>
        </Table>
    );
};

export default LocationTable;
