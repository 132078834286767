import apiClient from "services/apiClient";
import {UserInvitation} from "types/userInvitation";
import {createEntityService} from "../utils/createEntityService";

export const BaseUserInvitationService = createEntityService<UserInvitation>({
    baseUrl: 'api/v1/user_invitations',
    entityName: 'userInvitation',
});
export class UserInvitationService extends BaseUserInvitationService {

    static cancel = async (id: string) => {
        const response = await apiClient.patch(`api/v1/user_invitations/${id}/cancel`);
        return response.data;
    };
    static resend = async (id: string) => {
        const response = await apiClient.post(`api/v1/user_invitations/${id}/resend`);
        return response.data;
    };

    static showByToken = async (token: string) => {
        const response = await apiClient.get(`api/v1/user_invitations/show_by_token?token=${token}`);
        return UserInvitation.fromJson(response.data);
    };
}




