import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom';
import Login from 'pages/LogIn/LogInPage';
import Home from 'pages/Home';
import SecondStepVerificationPage from 'pages/LogIn/SecondStepVerificationPage';
import PrivateRoute from 'components/PrivateRoute';
import ToastContainer from "components/ToastContainer";
import "preline/preline";
import {IStaticMethods} from "preline/preline";
import {HSStrongPassword} from "preline/preline";
import {HSOverlay} from "preline/preline";
import AcceptInvitationPage from "pages/Auth/AcceptInvitationPage";
import {HSStaticMethods, HSTooltip} from "preline";
import ResetPasswordPage from "pages/LogIn/ResetPasswordPage";
import ForgotPasswordPage from "pages/LogIn/ForgotPasswordPage";
import PinSetUpPage from "pages/Auth/PinSetUpPage";
import useIdleTimer from "./hooks/useIdleTimer";
import LockScreen from "./components/LockScreen";
import UserPage from "./pages/User/UserPage";
import {HSDropdown} from "preline";
import {HSAccordion} from "preline/preline";
import {HSTabs} from "preline/preline";
import AuthLayout from "./layouts/AuthLayout";
import SignaturePage from "./pages/Auth/SignaturePage";
import backgroundLogIn from "assets/images/backgroundLogIn.png";
import DashboardLayout from "./layouts/MainLayout";
import LocationsPage from "./pages/Location/LocationsPage";
import ProgramsPage from "./pages/Program/ProgramsPage";
import CertificationPage from "./pages/Certification/CertificationPage";
import PayersPage from "./pages/Payer/PayersPage";
import HrPage from "./pages/Hr/HrPage";
import HrUserPage from "./pages/Hr/Users/HrUserPage";
import AddCredentialsPage from "./pages/Auth/AddCredentialsPage";
import ConfirmEmailPage from "./pages/Auth/ConfirmEmailPage";
import TrainingsPage from "./pages/Training/TrainingsPage";
import HrDocumentsPage from "./pages/HrDocument/HrDocumentsPage";
import BackgroundDocumentsPage from "./pages/BackgroundDocument/BackgroundDocumentsPage";
import JobRolesPage from "./pages/jobRoles/JobRolesPage";
import MedicalDocumentsPage from "./pages/MedicalDocument/MedicalDocumentsPage";
import HrLockScreen from "./components/HrLockScreen";
import DocumentTemplatesPage from "./pages/DocumentTemplate/DocumentTemplatesPage";
import DocumentTemplateFormPage from "./pages/DocumentTemplate/DocumentTemplateFormPage";
import {TitleProvider} from "./components/TitleContext";
import DashboardPage from "./pages/Dashboard/DashboardPage";

HSStaticMethods.autoInit();
declare global {
    interface Window {
        HSStaticMethods: IStaticMethods;
        HSPinInput: any;
        HSStrongPassword: HSStrongPassword;
        HSOverlay: HSOverlay;
        HSDropdown: HSDropdown;
        HSAccordion: HSAccordion;
        HSTooltip: HSTooltip;
        HSTabs: HSTabs;
        HSPopover: any;
        HSGProms: any;
    }
}

const InitComponent: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        window.HSStaticMethods.autoInit();
    }, [location.pathname]);

    return null;
};

const App: React.FC = () => {
    useIdleTimer();
    return (
        <Router>
            <InitComponent/>
            <div>
                <ToastContainer/>
                <TitleProvider>
                    <Routes>
                        <Route element={<AuthLayout backgroundSrc={backgroundLogIn}/>}>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/forgot-password" element={<ForgotPasswordPage/>}/>
                            <Route path="/second-step-verification" element={<SecondStepVerificationPage/>}/>
                            <Route path="/reset-password/:token" element={<ResetPasswordPage/>}/>
                        </Route>
                        <Route element={<AuthLayout backgroundSrc={backgroundLogIn}/>}>
                            <Route path="/accept-invitation/:token" element={<AcceptInvitationPage/>}/>
                            <Route path="/add-credentials/:token" element={<AddCredentialsPage/>}/>
                            <Route path="/pin-setup/:token" element={<PinSetUpPage/>}/>
                            <Route path="/signature/:token" element={<SignaturePage/>}/>
                            <Route path="/confirm-Email" element={<ConfirmEmailPage/>}/>

                        </Route>
                        <Route element={<DashboardLayout/>}>
                            <Route path="/" element={<PrivateRoute element={<Home/>}/>}/>
                            <Route path="/users" element={<PrivateRoute element={<UserPage/>}/>}/>
                            <Route path="/hr" element={<PrivateRoute element={<HrPage/>}/>}/>
                            <Route path="/hr/users/:userId" element={<PrivateRoute element={<HrUserPage/>}/>}/>
                            <Route path="/home" element={<PrivateRoute element={<Home/>}/>}/>
                            <Route path="/job-roles" element={<PrivateRoute element={<JobRolesPage/>}/>}/>
                            <Route path="/certification" element={<PrivateRoute element={<CertificationPage/>}/>}/>
                            <Route path="/location" element={<PrivateRoute element={<LocationsPage/>}/>}/>
                            <Route path="/program" element={<PrivateRoute element={<ProgramsPage/>}/>}/>
                            <Route path="/payer" element={<PrivateRoute element={<PayersPage/>}/>}/>
                            <Route path="/trainings" element={<PrivateRoute element={<TrainingsPage/>}/>}/>
                            <Route path="/hr-document" element={<PrivateRoute element={<HrDocumentsPage/>}/>}/>
                            <Route path="/background-document" element={<BackgroundDocumentsPage/>}/>
                            <Route path="/medical-document" element={<MedicalDocumentsPage/>}/>
                            <Route path="/document-templates" element={<DocumentTemplatesPage/>}/>
                            <Route path="/dashboard" element={<DashboardPage/>}/>
                            <Route
                                path="/document-templates/new"
                                element={<PrivateRoute element={<DocumentTemplateFormPage/>}/>}
                            />
                            <Route
                                path="/document-templates/:id"
                                element={<PrivateRoute element={<DocumentTemplateFormPage/>}/>}
                            />
                        </Route>

                    </Routes>
                </TitleProvider>
            </div>
            <LockScreen/>
            <HrLockScreen/>
        </Router>
    );
};

export default App;
