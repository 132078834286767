import React from "react";

interface TextInputProps {
    id: string;
    type: string;
    label: string;
    value: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void | undefined;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void | undefined;
    error?: string | undefined;
    required?: boolean;
    placeholder?: string;
    tabIndex?: number;
    disabled?: boolean;
    optional?: string;
    icon?: React.ReactNode;
}

const TextInput: React.FC<TextInputProps> = ({
                                                 id,
                                                 type,
                                                 label,
                                                 value,
                                                 onChange,
                                                 onBlur,
                                                 error,
                                                 placeholder,
                                                 tabIndex,
                                                 disabled,
                                                 optional,
                                                 required,
                                                 icon
                                             }) => {
    return (
        <div className="flex flex-col items-start gap-[0.625rem]">
            <div className="flex justify-between w-full">
                <label
                    htmlFor={id}
                    className="block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                    {label}
                </label>
                <span className="text-gray-400 text-sm">{optional}</span>
            </div>
            {icon ? (
                <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        {icon}
                    </div>
                    <input
                        type={type}
                        id={id}
                        data-testid={`${id}-field`}
                        value={value}
                        onChange={onChange}
                        className={`w-full py-2.5 pl-10 pr-3 block focus:outline-cyan-400 border border-slate-200  text-gray-500 rounded-lg text-sm font-light placeholder:text-gray-400 
                            disabled:bg-slate-100  disabled:text-slate-300 disabled:border-0 disabled:pointer-events-none 
                            dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60  
                            dark:focus:ring-neutral-600 ${error ? "border-red-500" : "border-gray-200"}`}
                        placeholder={placeholder}
                        required={required}
                        aria-describedby={`${id}-error`}
                        tabIndex={tabIndex}
                        disabled={disabled}
                        onBlur={onBlur}
                    />
                </div>
            ):(

            <input
                type={type}
                id={id}
                data-testid={`${id}-field`}
                value={value}
                onChange={onChange}
                className={`w-full py-2.5 px-3 block focus:outline-cyan-400 border border-slate-200  text-gray-500 rounded-lg text-sm font-light placeholder:text-gray-400 
                            disabled:bg-slate-100  disabled:text-slate-300 disabled:border-0 disabled:pointer-events-none 
                            dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60  
                            dark:focus:ring-neutral-600 ${error ? "border-red-500" : "border-gray-200"}`}
                placeholder={placeholder}
                required={required}
                aria-describedby={`${id}-error`}
                tabIndex={tabIndex}
                disabled={disabled}
                onBlur={onBlur}
            />
            )}

            {error && (
                <p className="text-xs text-red-600 " id={`${id}-error`}>
                    {error}
                </p>
            )}
        </div>
    );
};

export default TextInput;
