import React from 'react';
import {
    DocumentElement, DropdownElement,
    MultiLineTextInputElement,
    TextBlockElement,
    TextInputElement,
    PhoneNumberInputElement,
    YesNoInputElement,
    DateInputElement,
} from 'types/documentTemplateElement';
import TextInputElementComponent from "./TextInputElementComponent";
import TextBlockElementComponent from "./TextBlockElementComponent";
import MultiLineTextInputElementComponent from "./MultiLineTextInputElementComponent";
import DropdownElementComponent from "./DropdownElementComponent";
import PhoneNumberInputElementComponent from "./PhoneNumberInputElementComponent";
import YesNoInputElementComponent from "./YesNoInputElementComponent";
import DateInputElementComponent from "./DateInputElementComponent";

interface RenderElementProps {
    element: DocumentElement;
    updateElement: (updatedElement: DocumentElement) => void;
}

const RenderElement: React.FC<RenderElementProps> = ({element, updateElement}) => {
    switch (element.type) {
        case 'TextBlock':
            return (
                <TextBlockElementComponent
                    element={element as TextBlockElement}
                    updateElement={updateElement as (updatedElement: TextBlockElement) => void}
                />
            );
        case 'TextInput':
            return (
                <TextInputElementComponent
                    element={element as TextInputElement}
                    updateElement={updateElement as (updatedElement: TextInputElement) => void}
                />
            );
        case 'MultiLineTextInput':
            return (
                <MultiLineTextInputElementComponent
                    element={element as MultiLineTextInputElement}
                    updateElement={updateElement as (updatedElement: MultiLineTextInputElement) => void}
                />
            );
        case 'PhoneNumberInput':
            return (
                <PhoneNumberInputElementComponent
                    element={element as PhoneNumberInputElement}
                    updateElement={updateElement as (updatedElement: PhoneNumberInputElement) => void}
                />
            );

        case 'YesNoInput':
            return (
                <YesNoInputElementComponent
                    element={element as YesNoInputElement}
                    updateElement={updateElement as (updatedElement: YesNoInputElement) => void}
                />
            );
        case 'DateInput':
            return (
                <DateInputElementComponent
                    element={element as DateInputElement}
                    updateElement={updateElement as (updatedElement: DateInputElement) => void}
                />
            );
        // Agrega otros casos aquí
        case 'Dropdown':
            return <DropdownElementComponent
                element={element as DropdownElement}
                updateElement={updateElement as (updatedElement: DropdownElement) => void}
            />

        default:
            return null;
    }
};

export default RenderElement;
