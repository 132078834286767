import React, {useContext, useEffect, useState} from "react";
import Drawer from "components/Drawer";
import BackgroundDocumentTable from "features/backgroundDocument/BackgroundDocumentTable";
import BackgroundDocumentForm from "features/backgroundDocument/BackgroundDocumentForm";
import {TitleContext} from "components/TitleContext";


const BackgroundDocumentsPage: React.FC = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);
    const { setTitle } = useContext(TitleContext);

    useEffect(() => {
        setTitle(
            <div>
                <div className="text-slate-600 text-lg font-medium font-['Inter']  ">
                    Background Documents
                </div>
                <div
                    className="text-slate-400 text-sm font-light font-['Inter'] leading-normal ">
                    Manage your company’s background documents
                </div>
            </div>
        );
    }, [setTitle]);

    return (
        <div className="">
            <div className="relative overflow-hidden">

                <div className="relative">
                    <BackgroundDocumentTable openDrawer={openDrawer}/>
                    <Drawer isOpen={isDrawerOpen} onClose={closeDrawer}>
                        <BackgroundDocumentForm closeDrawer={closeDrawer}/>
                        </Drawer>
                    </div>

            </div>
        </div>

    );
};

export default BackgroundDocumentsPage;
