import { createEntityThunks } from 'utils/createEntityThunks';
import {JobRole} from "../../types/jobRole";
import {JobRoleService} from "../../services/jobRoleService";

const jobRoleFactory = (data: any) => new JobRole(data);

export const JobRoleThunks = createEntityThunks<JobRole>(
    'jobRole',
    JobRoleService,
    { activate: true, deactivate: true },
    jobRoleFactory
)
