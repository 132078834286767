import React from "react";
import {useSelector} from "react-redux";
import usePinInput from "hooks/usePinInput";
import SubmitButton from "components/SubmitButton";
import {showToast} from "components/ToastContainer";
import {RootState} from "store/store";
import useAppDispatch from "hooks/useAppDispatch";
import {AuthThunks} from "features/auth/authThunks";

const LockScreen = () => {
  const dispatch = useAppDispatch();
  const status = useSelector((state: RootState) => state.auth.status);
  const isLocked = useSelector((state: RootState) => state.auth.isLocked);
  const {
    firstInputRef,
    pin,
    setPin,
    error,
    isFormValid,
    handlePinChange,
    handleKeyDown,
    handlePaste,
  } = usePinInput({initialPinLength: 4, pinPrefix: ""});

  const handleForgotPinCode = () => {
    dispatch(AuthThunks.forgotPinCode());
    if (status === "succeeded") {
      showToast("Your Pin has been sent to your email", "success");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const fullPin = pin.join("");
    setPin(["", "", "", ""]);
    await dispatch(AuthThunks.unlockScreen(fullPin));
  };

  if (!isLocked) return null;

  return (
    <div className="fixed inset-0 bg-sky-100 bg-opacity-20 backdrop-blur-md flex items-center justify-center z-100">
      <div className="flex-col justify-center items-center inline-flex">
        <div className="w-[370px] h-[400px] p-[30px] bg-white rounded-lg border border-sky-100 flex-col justify-start items-start flex">
          <div className="self-stretch  flex-col justify-start items-start gap-1 flex">
            <div className="w-full text-xl font-semibold tracking-normal text-slate-800">
              Unlock your session
            </div>
            <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
              Use your 4-digit unlock pin to continue your work.
            </div>
          </div>
          <div className="w-full ">
            <div className="w-full flex flex-cols-4 justify-center gap-2">
              {pin.map((data, index) => (
                <input
                  data-hs-pin-input
                  key={index}
                  id={`pin-input-${index}`}
                  type="text"
                  maxLength={1}
                  ref={index === 0 ? firstInputRef : null}
                  className={`mt-12 w-[48px] h-[48px] shadow border bg-white text-center rounded-lg text-sm placeholder:text-gray-400 outline-cyan-400 
                                                disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 
                                                dark:placeholder:text-white/60 dark:focus:ring-neutral-600 ${error ? "border-red-500" : "border-slate-200"}`}
                  value={data}
                  onChange={e => handlePinChange(e.target.value, index)}
                  onKeyDown={e => handleKeyDown(e, index)}
                  onPaste={handlePaste}
                  onFocus={e => e.target.select()}
                />
              ))}
            </div>
            <div className="pt-0">
              <SubmitButton
                onClick={handleSubmit}
                label="Unlock"
                disabled={status === "loading" || !isFormValid}
              />
            </div>
            <div
              className="self-center mt-6 mb-6 text-sm font-light tracking-normal leading-5 text-center underline text-zinc-400 hover:text-cyan-400"
              onClick={handleForgotPinCode}
              style={{cursor: "pointer"}}>
              Forgot your pin?
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockScreen;
