import { CredentialService } from 'services/credentialService';
import { createEntityThunks } from 'utils/createEntityThunks';
import { Credential } from 'types/credential';

const credentialFactory = (data: any) => new Credential(data);

export const CredentialThunks = createEntityThunks<Credential>(
    'credential',
    CredentialService,
    { activate: true, deactivate: true },
    credentialFactory
)
