import { PayerService } from 'services/payerService';
import { createEntityThunks } from 'utils/createEntityThunks';
import { Payer } from 'types/payer';

const payerFactory = (data: any) => new Payer(data);

export const PayerThunks = createEntityThunks<Payer>(
    'payer',
    PayerService,
    { activate: true, deactivate: true },
    payerFactory
);
