import React from 'react';
import Select, {components, DropdownIndicatorProps} from 'react-select';
import ChevronDownIcon from "assets/images/icons/ChevronDownIcon";


const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        borderColor: '#e2e8f0',
        boxShadow: '0 ',
        borderRadius: '0.5rem',
        backgroundColor: 'white',
        color: '#6B7280',
        minHeight: '2.6rem',
        fontWeight: '300',
        fontSize: '0.875rem',
        '&:hover': {
            borderColor: '#22d3ee',
        },
        display: 'flex',
        alignItems: 'center',
        svg: {
            color: '#6B7280',
            fontSize: '1rem',
            fontWeight: '600',
            marginRight: '8px',
            strokeWidth: '0.5px',

        },
        ...(state.isDisabled && {
            backgroundColor: '#F1F5F9', // bg-slate-100
            color: '#D1D5DB', // text-slate-300
            border: '0', // border-0
            pointerEvents: 'none', // pointer-events-none
        }),

    }),
    placeholder: (provided: any, state: any) => ({
        ...provided,
        color: '#9CA3AF',
        ...(state.isDisabled && {
            color: '#D1D5DB', // text-slate-300
        }),
    }),

    singleValue: (provided: any, state: any) => ({
        ...provided,
        color: '#6B7280',
        fontSize: '0.875rem',
        ...(state.isDisabled && {
            color: '#D1D5DB', // text-slate-300
        }),
    }),
    multiValue: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: '#FFFFFF',
        borderRadius: '0.75rem',
        border: '1px solid #E2E8F0',
        color: '#6B7280',
        fontSize: '0.75rem',
        ...(state.isDisabled && {
            backgroundColor: '#F1F5F9', // bg-slate-100
            color: '#D1D5DB', // text-slate-300
            border: '0', // border-0
            pointerEvents: 'none', // pointer-events-none
        }),

    }),
    multiValueLabel: (provided: any, state: any) => ({
        ...provided,
        color: '#6B7280',
        paddingLeft: '10px',
        fontSize: '0.75rem',
        ...(state.isDisabled && {
            backgroundColor: '#F1F5F9', // bg-slate-100
            color: '#D1D5DB', // text-slate-300
            border: '0', // border-0
            pointerEvents: 'none', // pointer-events-none
        }),
    }),
    multiValueRemove: (provided: any,state: any) => ({
        ...provided,
        backgroundColor: '#e5e7eb',
        borderRadius: '100%',
        border: '1px solid #E2E8F0',
        width: '20px',
        height: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#1f2937;',
        cursor: 'pointer',
        padding: '',
        marginLeft: '4px',
        marginRight: '4px',
        marginTop: '4px',
        marginBottom: '2px',
        boxSizing: 'border-box',
        svg: {
            width: '12px',
            height: '12px',
            display: 'block',
            margin: 'auto',

        },
        ':hover': {

            backgroundColor: '#cffafe',
            color: '#f9fafb',
        },
        ...(state.isDisabled && {
            color: '#D1D5DB', // text-slate-300
            backgroundColor: 'none', // bg-slate-100
            pointerEvents: 'none', // pointer-events-none
        }),

    }),

    option: (provided: any, state: any) => ({
        ...provided,
        padding: '0.5rem',
        borderRadius: '0.5rem',
        fontSize: '0.75rem',
        backgroundColor: state.isSelected ? '#ecfeff' : state.isFocused ? '#ecfeff' : 'white',
        color: state.isSelected ? '#6B7280' : '#6B7280',
        boxSizing: 'border-box',
        width: 'calc(100% - 10px)',
        margin: '5px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ':hover': {
            backgroundColor: '#ecfeff',
        },
    }),
    menuPortalTarget: (provided: any) => ({
        ...provided,
        zIndex: 9999,
    }),

    menu: (provided: any) => ({
        ...provided,
        borderRadius: '0.75rem',
        border: '1px solid #e2e8f0',
        borderColor: '#e2e8f0',
        boxShadow: ' 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
        overflow: 'hidden',
    }),
    dropdownIndicator: (provided: any) => ({
        ...provided,
        padding: '5px',
        color: '#6b7280',
        svg: {
            strokeWidth: '0.7',
        },
    }),
    indicatorSeparator: () => ({
        display: 'none',

    }),

    clearIndicator: (provided: any) => ({
        ...provided,
        padding: '5px',
        color: '#d1d5db',
        svg: {
            strokeWidth: '0',
            color: '#d1d5db',
        },
    }),

};

const DropdownIndicator = (props: DropdownIndicatorProps<{}, false>) => {
    return (
        <components.DropdownIndicator {...props}>
            <ChevronDownIcon/>
        </components.DropdownIndicator>
    );
};


interface CustomSelectProps {
    options?: any,
    onChange?: (selectedOptions: any) => void,
    isMulti?: boolean,
    menuPortalTarget?: any,
    value?: any,
    disabled?: boolean
}

const CustomSelect = ({options, isMulti, onChange, value, menuPortalTarget, disabled}: CustomSelectProps) => (
    <Select
        isDisabled={disabled}
        components={{DropdownIndicator}}
        styles={customStyles}
        options={options}
        menuPortalTarget={menuPortalTarget}
        isMulti={isMulti as any}
        onChange={onChange}
        placeholder=""
        value={
            isMulti
                ? options.filter((option: any) => value?.includes(option.value))
                : options.find((option: any) => option.value === value) || null
        }
    />
);

export default CustomSelect;
