
import {createEntitySlice} from "utils/createEntitySlice";
import {UserBackgroundDocument} from "types/userBackgroundDocument";
import {UserBackgroundDocumentThunks} from "features/userBackgroundDocument/userBackgroundDocumentThunks";

export interface UserBackgroundDocumentState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    userBackgroundDocuments?: any;
    refresh: number;
    pagy?: any;
    userBackgroundDocument?: UserBackgroundDocument | null;
    isEditing?: boolean;

}

const initialState: UserBackgroundDocumentState = {
    status: 'idle',
    error: null,
    userBackgroundDocuments: [],
    refresh: 0,
    userBackgroundDocument: null,
    pagy: {},
    isEditing: false,

};
const userBackgroundDocumentSlice = createEntitySlice<UserBackgroundDocument>({
    name: 'document',
    initialState,
    thunks: UserBackgroundDocumentThunks,
    entityKey: 'userBackgroundDocument',
    entitiesKey: 'userBackgroundDocuments',
    reducers: {
    },
});


export const { setEntity: setUserBackgroundDocument, setIsEditing} = userBackgroundDocumentSlice.actions;
export {initialState as userBackgroundDocumentInitialState};
export default userBackgroundDocumentSlice.reducer;
