import React from "react";

interface RadioGroupProps {
    label: string;
    options: { id: string; label: string; value: boolean }[];
    selectedValue: boolean | undefined;
    onChange: (value: boolean) => void;
    required?: boolean;
}

const RadioInput: React.FC<RadioGroupProps> = ({ label, options, selectedValue, onChange,required }) => {
    return (
        <div className="flex flex-col w-full gap-[0.625rem]">
            <label className="mb-2 block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                {label}
            </label>
            <div className="flex gap-4 items-center">
                {options.map((option) => (
                    <div key={option.id} className="flex items-center gap-x-4">
                        <input
                            type="radio"
                            id={option.id}
                            checked={selectedValue === option.value}
                            onChange={() => onChange(option.value)}
                            required={required}
                            className="bg-transparent border-gray-200 focus:ring-white focus:ring-offset-0 dark:border-neutral-700 dark:focus:ring-neutral-800"
                        />
                        <label htmlFor={option.id} className="text-slate-800 text-sm font-semibold dark:text-white">
                            {option.label}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RadioInput;
