import { createEntitySlice } from 'utils/createEntitySlice';
import { CredentialThunks } from './credentialThunks';
import { Credential } from 'types/credential';

export interface CredentialState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    credentials?: any;
    pagy?: any;
    credential?: any;
    isEditing?: boolean;
    activeTab?: string;

}

const initialState: CredentialState = {
    status: 'idle',
    error: null,
    credentials: [],
    credential: {name: '', nameAlias: ''},
    pagy: {},
};

const credentialSlice = createEntitySlice<Credential>({
    name: 'certification',
    initialState,
    thunks: CredentialThunks,
    entityKey: 'credential',
    entitiesKey: 'credentials',
    reducers: {
    },
});

export const {setEntity: setCredential,setActiveTab, setIsEditing} = credentialSlice.actions;
export {initialState as credentialInitialState};
export default credentialSlice.reducer;

