import React, {useEffect, useState} from "react";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import TableCell from "components/table/TableCell";
import {JobRole} from "types/jobRole";
import Table from "components/table/Table";
import {SearchType} from "types/search";
import {JobRoleThunks} from "./jobRoleThunks";
import {setJobRole,setActiveTab} from "features/jobRole/jobRoleSlice";
import BlueButton from "components/BlueButton";
import PlusIcon from "assets/images/icons/PlusIcon";
import {useDropdownActionMenu} from "hooks/useDropdownActionMenu";
import DotsMenu from "components/DotsMenu";
import CheckCircleIcon from "../../assets/images/icons/CheckCircleIcon";
import XCircleIcon from "../../assets/images/icons/XCircleIcon";
import TrashIcon from "../../assets/images/icons/TrashIcon";

const JobRoleTable: React.FC<{ openDrawer: () => void }> = ({openDrawer}) => {
  const jobRoles = useSelector((state: RootState) => state.jobRole.jobRoles);
  const dispatch = useDispatch<AppDispatch>();
  const pagy = useSelector((state: RootState) => state.jobRole.pagy);
  const {setDotsMenuIsOpen} = useDropdownActionMenu();
  const activeTab = useSelector((state: RootState) => state.jobRole.activeTab);

  const loading = useSelector((state: RootState) => state.jobRole.status === 'loading');

  const [visibleColumns, setVisibleColumns] = useState({
    id: true,
    name: true,
    description: true,
    status: true,
  });

  interface VisibleColumnsType {
    id: boolean,
    name: boolean,
    description: boolean,
    status: boolean,
  }
  const searchByOptions = [
    {label: 'Name', value: 'name', operator: 'like'},
  ];

  const columnOptions = [
    {label: 'ID', value: 'id', isVisible: visibleColumns.id},
    {label: 'Name', value: 'name', isVisible: visibleColumns.name},
    {label: 'Status', value: 'status', isVisible: visibleColumns.status},
    {label: 'Description', value: 'description', isVisible: visibleColumns.description}
  ];

  const fetchData = async ({page, search, sortField, sortDirection, rowsPerPage}: {
    page: number;
    search: SearchType<{ [key: string]: { operator: string, value: string } }>;
    sortField: string;
    sortDirection: string;
    rowsPerPage: number
  }): Promise<void> => {
    if (activeTab === 'Active' || activeTab === 'Inactive') {
      search.where.status = {
        operator: '==',
        value: activeTab.toLowerCase()
      };
    } else {
      delete search.where.status;
    }
    await dispatch(JobRoleThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
  };

  const handleColumnToggle = (column: string) => {
    if (column in visibleColumns) {
      setVisibleColumns((prev) => ({
        ...prev,
        [column]: !prev[column as keyof VisibleColumnsType],
      }));
    }
  };

  const handleOpenDrawer = (id: string) => async () => {
    openDrawer();
    await dispatch(JobRoleThunks.show(id));
  };

  const handleDeactivate = (jobRoleId: string) => {
    dispatch(JobRoleThunks.deactivate(jobRoleId));
    setDotsMenuIsOpen(null);
  };

  const handleReactivate = (jobRoleId: string) => {
    dispatch(JobRoleThunks.activate(jobRoleId));
    setDotsMenuIsOpen(null);
  };

  const handleOpenNewJobRoleDrawer = () => {
    dispatch(setJobRole({
      id: '',
      name: '',
      description: '',
      status: '',
    }));
    openDrawer();
  };
  const handleDelete = async (id: string) => {
    await dispatch(JobRoleThunks.delete(id));
    setDotsMenuIsOpen(null);
  };

  return (
      <Table
          fetchData={fetchData}
          pagy={pagy}
          pageable={true}
          recordsName={'Documents'}
          activeTab={activeTab}
          setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
          tabs={['All', 'Active', 'Inactive']}
          searchable={true}
          columns={true}
          columnOptions={columnOptions}
          onColumnToggle={handleColumnToggle}
          loading={loading}
          searchByOptions={searchByOptions}
          blueButton={<BlueButton onClick={handleOpenNewJobRoleDrawer} label="" icon={<PlusIcon/>}/>}
      >
        <thead>
        <tr>
          <th scope="col" className="px-3  pt-3.5  flex justify-center">
            <input type="checkbox"
                   className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600
                                      dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"/>
          </th>
          {visibleColumns.id && <TableHeader label="ID" sortBy="id"/>}
          {visibleColumns.name && <TableHeader label="Name" sortBy="name"/>}
          {visibleColumns.status && <TableHeader label="Status" sortBy="status"/>}
          {visibleColumns.description && <TableHeader label="Description"/>}
        </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
        {jobRoles.map((jobRole: JobRole) => {
              const userOptions = [];
              userOptions.push({
                label: "Edit",
                onClick: handleOpenDrawer(jobRole.id),
                hoverClass: "hover:bg-cyan-100",
              });
              if (jobRole.status === "Active") {
                userOptions.push({
                  label: "Deactivate",
                  onClick: () => handleDeactivate(jobRole.id),
                  hoverClass: "hover:bg-red-100 hover:text-red-800",
                });
              }
              if (jobRole.status === "Inactive") {
                userOptions.push({
                  label: "Activate",
                  onClick: () => handleReactivate(jobRole.id),
                  hoverClass: "hover:bg-cyan-100",
                });

              }
              userOptions.push({
                label: "Delete",
                onClick: () => handleDelete(jobRole.id),
                icon: <TrashIcon/>,
                hoverClass: "hover:bg-red-100 hover:text-red-800",
              });

              return (
                  <TableRow
                      key={jobRole.id}
                  >
                    <td scope="col" className="pt-3.5 flex justify-center">
                      <input
                          type="checkbox"
                          className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600
                                       dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                          onClick={(e) => e.stopPropagation()}/>
                    </td>
                    {visibleColumns.id && <TableCell>{jobRole.id}</TableCell>}
                    {visibleColumns.name &&
                        <TableCell>
                          <div className=" relative group">
                            <div className="w-full flex items-center gap-x-3">
                              <div className="flex-grow">
                                        <span
                                            className="text-sm  cursor-pointer font-medium dark:text-neutral-200 underline text-cyan-600"
                                            onClick={handleOpenDrawer(jobRole.id)}
                                        >
                                            {jobRole.name}
                                        </span>
                              </div>
                            </div>
                          </div>
                        </TableCell>}
                    {visibleColumns.status && (
                        <TableCell>
                            <span
                                className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-28
                                    ${jobRole.status === 'Active' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-700'}`}
                            >
                                {jobRole.status === 'Active' ? <CheckCircleIcon className="mr-2"/> :
                                    <XCircleIcon className="mr-2"/>}

                              <div className="pl-1">
                                    {jobRole.status === 'Active' ? jobRole.status : 'Inactive'}
                                </div>
                            </span>
                        </TableCell>)}

                    {visibleColumns.description && (
                        <TableCell className="whitespace-nowrap overflow-hidden text-ellipsis">
                          <div style={{ maxWidth: '150px' }} className="whitespace-nowrap overflow-hidden text-ellipsis">
                            {jobRole.description}
                          </div>
                        </TableCell>
                    )}
                    <TableCell>
                      <DotsMenu options={userOptions}/>
                    </TableCell>
                  </TableRow>
              )
            }
        )}
        </tbody>
      </Table>
  );
};

export default JobRoleTable;
