import React, { useEffect, useRef, useState } from 'react';
import ChevronDownIcon from "../assets/images/icons/ChevronDownIcon";

const HeaderChangeApp: React.FC = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedApp, setSelectedApp] = useState('Davis HR'); // Aplicación seleccionada por defecto
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleAppSelect = (appName: string) => {
        setSelectedApp(appName);
        setIsDropdownOpen(false);
    };

    return (
        <div className="flex lg:justify-end items-center">
            <div className="relative inline-block">
                <button
                    id="dropdown-app-button"
                    type="button"
                    onClick={toggleDropdown}
                    className="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs rounded-lg border border-gray-200 bg-white text-gray-800
                        hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700
                        dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 relative"
                >
                    <div className="flex flex-col">
                        <span>{selectedApp}</span>
                    </div>
                    <ChevronDownIcon />
                </button>
                {isDropdownOpen && (
                    <div
                        ref={dropdownRef}
                        className="absolute right-0 mt-2 w-32 bg-white rounded-xl shadow-lg z-10
                            dark:bg-neutral-900"
                    >
                        <div className="p-1">
                            <button
                                key="Davis HR"
                                type="button"
                                onClick={() => handleAppSelect('Davis HR')}
                                className={`w-full flex items-center gap-x-3 py-1.5 px-3 rounded-lg text-[13px] ${
                                    selectedApp === 'Davis HR' ? 'bg-cyan-100 text-gray-800' : 'text-gray-800'
                                } disabled:opacity-50
                                    hover:bg-cyan-100 hover:text-gray-800 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-cyan-100`}
                            >
                                Davis HR
                            </button>
                        </div>
                        <div className="px-1">
                            <button
                                key="Davis EMR"
                                type="button"
                                onClick={() => handleAppSelect('Davis EMR')}
                                className={`w-full flex items-center gap-x-3 py-1.5 px-3 rounded-lg text-[13px] ${
                                    selectedApp === 'Davis EMR' ? 'bg-cyan-100 text-gray-800' : 'text-gray-800'
                                } disabled:opacity-50
                                    hover:bg-cyan-100 hover:text-gray-800 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-cyan-100`}
                            >
                                Davis EMR
                            </button>
                        </div>
                        <div className="p-1">
                            <button
                                key="Davis App3"
                                type="button"
                                onClick={() => handleAppSelect('Davis App3')}
                                className={`w-full flex items-center gap-x-3 py-1.5 px-3 rounded-lg text-[13px] ${
                                    selectedApp === 'Davis App3' ? 'bg-cyan-100 text-gray-800' : 'text-gray-800'
                                } disabled:opacity-50
                                    hover:bg-cyan-100 hover:text-gray-800 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-cyan-100`}
                            >
                                Davis App3
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default HeaderChangeApp;
