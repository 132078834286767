export interface CredentialInterface {
    id: string;
    name: string;
    description: string;
    licensingAuthority: string;
    expires: boolean;
    remindHr: boolean;
    hrReminderDays: number;
    remindUser: boolean;
    userReminderDays: number;
    requiresTraining: boolean;
    trainingLinks: string[];
    status?: string;
}

export class Credential implements CredentialInterface {
    id: string;
    name: string;
    description: string;
    licensingAuthority: string;
    expires: boolean;
    remindHr: boolean;
    hrReminderDays: number;
    remindUser: boolean;
    userReminderDays: number;
    requiresTraining: boolean;
    trainingLinks: string[];
    status?: any;

    constructor(data: Partial<CredentialInterface>) {
        this.id = data.id!;
        this.name = data.name!;
        this.licensingAuthority = data.licensingAuthority!;
        this.description = data.description!;
        this.expires = data.expires!;
        this.remindHr = data.remindHr!;
        this.hrReminderDays = data.hrReminderDays!;
        this.remindUser = data.remindUser!;
        this.userReminderDays = data.userReminderDays!;
        this.requiresTraining = data.requiresTraining!;
        this.trainingLinks = data.trainingLinks!;
        this.status = data.status;
    }

    toJson(): CredentialInterface {
        return {
            id: this.id,
            name: this.name,
            licensingAuthority: this.licensingAuthority,
            description: this.description,
            expires: this.expires,
            remindHr: this.remindHr,
            hrReminderDays: this.hrReminderDays,
            remindUser: this.remindUser,
            userReminderDays: this.userReminderDays,
            requiresTraining: this.requiresTraining,
            trainingLinks: this.trainingLinks,
            status: this.status?.toLowerCase(),

        };
    }
}
