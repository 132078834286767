import { createEntitySlice } from 'utils/createEntitySlice';
import { BackgroundDocumentThunks } from './backgroundDocumentThunks';
import { BackgroundDocument } from 'types/backgroundDocument';

export interface BackgroundDocumentState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    backgroundDocuments: BackgroundDocument[];
    backgroundDocument: BackgroundDocument | null;
    isEditing: boolean;
    activeTab: string;
    pagy: any;
}

const initialState: Partial<BackgroundDocumentState> = {
    backgroundDocuments: [],
    backgroundDocument: null,
    isEditing: false,
    activeTab: 'All',
    pagy: {},
};

const backgroundDocumentSlice = createEntitySlice<BackgroundDocument>({
    name: 'Document',
    initialState,
    thunks: BackgroundDocumentThunks,
    entityKey: 'backgroundDocument',
    entitiesKey: 'backgroundDocuments',
    reducers: {
    },
});

export const { setEntity: setBackgroundDocument, setIsEditing, setActiveTab } = backgroundDocumentSlice.actions;
export default backgroundDocumentSlice.reducer;
