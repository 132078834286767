import { createEntityService } from 'utils/createEntityService';
import { UserHrDocument } from 'types/userHrDocument';

export const UserHrDocumentService = createEntityService<UserHrDocument>({
    baseUrl: 'api/v1/user_hr_documents',
    entityName: 'userHrDocument',
    methodConfigs: {
        create: {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
        update: {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    }
});
