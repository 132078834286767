import React, {useEffect, useState} from "react";
import XIcon from "../../assets/images/icons/XIcon";
import PasswordInput from "../../components/inputs/PasswordInput";

import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import {UserThunks} from "features/user/userThunks";
import {showToast} from "components/ToastContainer";
import SubmitButton from "../../components/SubmitButton";
import DataCheckIcon from "../../assets/images/icons/DataCheckIcon";
import DataUnCheckIcon from "../../assets/images/icons/DataUnCheckIcon";

interface UpdatePasswordProps {
    onClose: () => void;
}

const UpdatePassword: React.FC<UpdatePasswordProps> = ({onClose}) => {
    const dispatch = useDispatch<AppDispatch>();
    const [passwordError, setPasswordError] = useState(false);
    const [passwordConfirmError, setPasswordConfirmError] = useState("");
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showValidations, setShowValidations] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const user = useSelector((state: RootState) => state.auth.user);
    const [formData, setFormData] = useState({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    });

    useEffect(() => {
        const {newPassword, confirmNewPassword} = formData;
        const isPasswordValid = isPasswordStrong(newPassword);
        const isConfirmPasswordValid = newPassword === confirmNewPassword;

        setIsSaveDisabled(
            !isPasswordValid ||
            !isConfirmPasswordValid ||
            newPassword === "" ||
            confirmNewPassword === ""
        );
    }, [formData]);

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {id, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));

        if (id === "newPassword") {
            isPasswordStrong(value);
        }

        if (id === "confirmNewPassword") {
            if (value !== formData.newPassword) {
                setPasswordConfirmError("Passwords do not match");
            } else {
                setPasswordConfirmError("");
            }
        }
    };

    const isPasswordStrong = (password: string) => {
        if (password === '') return true;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        const hasMinLength = password.length >= 8;

        const isValid = hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar && hasMinLength;

        if (!isValid) {
            setPasswordError(true);
            setPasswordErrorMessage('Password is not strong enough');
        } else {
            setPasswordError(false);
            setPasswordErrorMessage('');
        }

        return isValid;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (passwordError || passwordConfirmError) {
            showToast("Please fix the errors before submitting.", "error");
            return;
        }

        try {
            await dispatch(
                UserThunks.changePassword({
                    id: user?.id || "",
                    oldPassword: formData.oldPassword,
                    newPassword: formData.newPassword,
                })
            ).unwrap();
            handleClose();
        } catch (error) {
        }
        onClose();
    };

    const handleClose = () => {
        onClose();

    };

    const onPasswordFocus = () => {
        setShowValidations(true);
        setPasswordError(false);
        setPasswordErrorMessage('');
    };

    const onPasswordBlur = () => {
        setShowValidations(false);
    };

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-1 flex flex-col">
                <div
                    className="h-auto p-6 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full"
                >
                    <div className="flex flex-col justify-start items-start gap-1">
                        <div className="text-lg font-semibold tracking-normal text-cyan-800">
                            Password
                        </div>

                        <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                            Update your user password
                        </div>
                    </div>
                    <button
                        onClick={handleClose}
                        className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2"
                    >
                        <XIcon/>
                    </button>
                </div>
                <div className="h-full px-6 pb-6 ">
                    <div className="mt-5">
                        <form onSubmit={handleSubmit}>
                            <div className="self-stretch w-full">
                                <div className="flex flex-col space-y-6">
                                    <div className="w-full">
                                        <PasswordInput
                                            label="Old Password"
                                            password={formData.oldPassword}
                                            onPasswordChange={handlePasswordChange}
                                            passwordError={false}
                                            showPassword={showPassword}
                                            onToggleShowPassword={() => setShowPassword(!showPassword)}
                                            inputId="oldPassword"

                                        />
                                    </div>
                                    <div className="w-full">
                                        <PasswordInput
                                            label="New Password"
                                            password={formData.newPassword}
                                            onPasswordChange={handlePasswordChange}
                                            passwordError={passwordError}
                                            showPassword={showPassword}
                                            onToggleShowPassword={() => setShowPassword(!showPassword)}
                                            inputId="newPassword"
                                            errorMessage={passwordErrorMessage}
                                            onFocus={onPasswordFocus}
                                            onBlur={onPasswordBlur}
                                        />
                                    </div>
                                    <div className={`relative ${showValidations ? '' : 'hidden'}`}>
                                        <div className="absolute z-10 w-full p-4 bg-white rounded-lg shadow  border dark:bg-neutral-800">
                                            <div className="flex mb-2">
                                                <div className="flex-1">
                                                    <div id="hs-strong-password" data-hs-strong-password='{
                                                         "target": "#newPassword",
                                                         "hints": "#hs-strong-password-hints",
                                                         "stripClasses": "hs-strong-password:opacity-100 hs-strong-password-accepted:bg-cyan-400 h-2 flex-auto rounded-full bg-cyan-400 opacity-30 mx-1" }
                                                         ' className="flex mt-2 -mx-1"></div>
                                                </div>
                                            </div>
                                            <div id="hs-strong-password-hints" className="mb-3">
                                                <div>
                                                <span
                                                    className="text-sm text-gray-800 dark:text-neutral-200">Level: </span>
                                                    <span
                                                        data-hs-strong-password-hints-weakness-text='["Empty", "Weak", "Medium", "Strong", "Very Strong", "Super Strong"]'
                                                        className="text-sm font-semibold text-gray-800 dark:text-neutral-200"></span>
                                                </div>
                                                <h4 className="my-2 text-sm font-semibold text-gray-800 dark:text-white">
                                                    Your password must contain:
                                                </h4>
                                                <ul className="space-y-1 text-sm font-light text-gray-500 dark:text-neutral-500">
                                                    <li data-hs-strong-password-hints-rule-text="min-length"
                                                        className="hs-strong-password-active:text-green-400 flex items-center gap-x-2">
                                                         <span className="hidden" data-check="">
                                                           <DataCheckIcon/>
                                                         </span>
                                                        <span data-uncheck="">
                                                             <DataUnCheckIcon/>
                                                         </span>
                                                        Minimum number of characters is 6.
                                                    </li>
                                                    <li data-hs-strong-password-hints-rule-text="lowercase"
                                                        className="hs-strong-password-active:text-green-400 flex items-center gap-x-2">
                                                         <span className="hidden" data-check="">
                                                           <DataCheckIcon/>
                                                         </span>
                                                        <span data-uncheck="">
                                                             <DataUnCheckIcon/>
                                                         </span>
                                                        Should contain lowercase.
                                                    </li>
                                                    <li data-hs-strong-password-hints-rule-text="uppercase"
                                                        className="hs-strong-password-active:text-green-400 flex items-center gap-x-2">
                                                        <span className="hidden" data-check="">
                                                          <DataCheckIcon/>
                                                        </span>
                                                        <span data-uncheck="">
                                                           <DataUnCheckIcon/>
                                                         </span>
                                                        Should contain uppercase.
                                                    </li>
                                                    <li data-hs-strong-password-hints-rule-text="numbers"
                                                                                className="hs-strong-password-active:text-green-400 flex items-center gap-x-2">
                                                        <span className="hidden" data-check="">
                                                          <DataCheckIcon/>
                                                        </span>
                                                        <span data-uncheck="">
                                                          <DataUnCheckIcon/>
                                                        </span>
                                                        Should contain numbers.
                                                    </li>
                                                    <li data-hs-strong-password-hints-rule-text="special-characters"
                                                        className="hs-strong-password-active:text-green-400 flex items-center gap-x-2">
                                                        <span className="hidden" data-check="">
                                                            <DataCheckIcon/>
                                                        </span>
                                                        <span data-uncheck="">
                                                            <DataUnCheckIcon/>
                                                        </span>
                                                        Should contain special characters.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <PasswordInput
                                            label="Confirm New Password"
                                            password={formData.confirmNewPassword}
                                            onPasswordChange={handlePasswordChange}
                                            passwordError={passwordConfirmError !== ""}
                                            errorMessage={passwordConfirmError}
                                            showPassword={showPassword}
                                            onToggleShowPassword={() => setShowPassword(!showPassword)}
                                            inputId="confirmNewPassword"
                                        />
                                    </div>
                                </div>
                                {errors.length > 0 && (
                                    <div className="text-xs mt-2 text-red-500">
                                        <ul>
                                            {errors.map((error, index) => (
                                                <li key={index}>{error}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
                <div className="border-t border-slate-200 w-full">
                    <div className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <SubmitButton
                            onClick={handleSubmit}
                            label="Update Password"
                            disabled={isSaveDisabled}
                        />
                    </div>
                    <div className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <button
                            type="button"
                            onClick={handleClose}
                            className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400"
                        >
                            Never mind
                        </button>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default UpdatePassword;
