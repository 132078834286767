import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store/store';
import {UserInvitation, UserInvitationInterface} from 'types/userInvitation';
import {validateEmail} from "utils/validators";
import TextInput from "components/inputs/TextInput";
import {User} from "types/user";
import {UserInvitationThunks} from "features/userInvitation/userInvitationThunk";
import SSNInput from "components/inputs/SSNInput";
import PhoneNumberInput from "components/inputs/PhoneNumberInput";
import SubmitButton from "components/SubmitButton";
import XIcon from "assets/images/icons/XIcon";
import RadioInputHorizontal from "components/inputs/RadioInputHorizontal";
import {UserThunks} from "features/user/userThunks";
import CustomSelect from "../../components/inputs/CustomSelect";
import {JobRole} from "../../types/jobRole";
import {PermissionSchemeGroup} from "../../types/permissionSchemeGroup";
import {Permission} from "../../types/permission";
import {PermissionScheme} from "../../types/permissionScheme";
import {Role} from "../../types/role";
import {PermissionSchemeGroupThunks} from "../role/permissionSchemeGroupThunks";
import {JobRoleThunks} from "../jobRole/jobRoleThunks";
import {SearchType} from "../../types/search";
import {UserService} from "../../services/userService";
import {Location} from "../../types/location";
import {LocationThunks} from "../location/locationThunks";


interface InvitationsFormProps {
    closeDrawer: () => void;
    isDrawerOpen: boolean;
    source: 'UserTable' | 'HrTable';
}

const InvitationsForm: React.FC<InvitationsFormProps> = ({closeDrawer, isDrawerOpen, source}) => {
    const dispatch = useDispatch<AppDispatch>();
    const [emailError, setEmailError] = useState('');
    const [ssn, setSSN] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const jobRoles = useSelector((state: RootState) => state.jobRole.jobRoles);
    const [supervisorOptions, setSupervisorOptions] = useState([]);
    const permissionSchemeGroups = useSelector((state: RootState) => state.role?.permissionSchemeGroups);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const location = useSelector((state: RootState) => state.location.locations);

    const locationOptions = location.map((location: Location) => ({
        value: location.id,
        label: location.name
    }));

    const jobRolesOptions = jobRoles.map((jobRole: JobRole) => ({
        value: jobRole.id,
        label: jobRole.name
    }));
    const supervisorSelectOptions = supervisorOptions.map((user: User) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
    }));

    const [formData, setFormData] = useState<UserInvitationInterface>({
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        personalEmail: '',
        ssn: '',
        invite: undefined,
        supervisorIds: [],
        jobRoleIds: [],
        userType: 'user',
        role: new Role({}),
        isSupervisor: undefined,
        staffMember: undefined,
        locationIds: []

    });
    const status = useSelector((state: RootState) => state.userInvitation.status);
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        const loadSelectData = async () => {
            try {
                await dispatch(JobRoleThunks.index());
                await dispatch(LocationThunks.index());
                await dispatch(PermissionSchemeGroupThunks.index());
                const search: SearchType<{ [key: string]: any }> = {
                    where: {
                        'is_supervisor': {
                            operator: '==',
                            value: 'true'
                        }
                    }
                }

                const response = await UserService.index(1, search, '', '', 1000);
                setSupervisorOptions(response.data);
            } catch (err) {
            }
        };
        loadSelectData();
    }, [dispatch]);

    useEffect(() => {
        setIsFormValid(
            formData.email !== '' &&
            formData.firstName !== '' &&
            formData.lastName !== '' &&
            formData.personalEmail !== ''

        );
    }, [formData]);

    useEffect(() => {
        setFormData({
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            personalEmail: '',
            ssn: '',
            invite: undefined,
            supervisorIds: [],
            jobRoleIds: [],
            userType: 'user',
            role: new Role({}),
            isSupervisor: undefined,
            staffMember: undefined

        });
        setSSN('')
        setPhoneNumber('')
        setIsSubmitting(false);
    }, [isDrawerOpen]);

    const handleRadioChange = (field: string, value: boolean) => {
        setFormData((prevState: UserInvitationInterface) => ({
            ...prevState,
            [field]: value,
        }));
    };
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {id, checked} = e.target;
        const [subjectClass, _, action] = id.split('_');
        if (!subjectClass) return;
        const permissions = formData.role?.permissions ? [...formData.role?.permissions] : [];
        if (checked) {
            permissions.push({subjectClass, action} as Permission);
            if (action === 'manage') {
                const permissionSchemeGroup = permissionSchemeGroups.find((permissionSchemeGroup: PermissionSchemeGroup) => permissionSchemeGroup.subjectClass === subjectClass);
                if (permissionSchemeGroup) {
                    permissionSchemeGroup.permissionSchemes?.forEach((permission: PermissionScheme) => {
                        const index = permissions.findIndex((p: Permission) => p.subjectClass === subjectClass && p.action === permission.action);
                        if (index === -1) {
                            permissions.push({subjectClass, action: permission.action} as Permission);
                        }
                    })
                }
            }
        } else {
            const index = permissions.findIndex((permission: Permission) => permission.subjectClass === subjectClass
                && permission.action === action);
            if (index !== -1) {
                if (permissions[index].id) {
                    const permission = new Permission({...permissions[index], _destroy: true});
                    permissions.splice(index, 1);
                    permissions.push(permission);
                } else {
                    permissions.splice(index, 1);
                }

            }
        }
        setFormData((prevData) => ({
            ...prevData,
            role: new Role({...formData.role, permissions})
        }));
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {id, value} = e.target;
        if (id === 'email') {
            if (validateEmail(value)) {
                setEmailError('');
            } else {
                setEmailError('Please enter a valid email address.');
            }
        }
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        if (emailError) return;
        const parsedData = {
            ...formData
        }
        if (parsedData.invite || source === 'UserTable'){
            const invitation = new UserInvitation(parsedData);
            dispatch(UserInvitationThunks.create(invitation)).then(
                () => {
                    setFormData({
                        email: '',
                        firstName: '',
                        lastName: '',
                        phoneNumber: '',
                        personalEmail: '',
                        ssn: '',
                        invite: undefined,
                        supervisorIds: [],
                        jobRoleIds: [],
                        userType: 'user',
                        staffMember: undefined,
                        role: new Role({})

                    });
                    setIsSubmitting(false);
                    closeDrawer();
                }
            );
        } else {
            const user = new User(parsedData);
            dispatch(UserThunks.create(user)).then(
                () => {
                    setFormData({
                        email: '',
                        firstName: '',
                        lastName: '',
                        phoneNumber: '',
                        personalEmail: '',
                        ssn: '',
                        invite: false,
                        staffMember: false,
                        supervisorIds: [],
                        jobRoleIds: [],
                        userType: 'user',
                        role: new Role({})
                    });
                    setIsSubmitting(false);
                    closeDrawer();
                }
            );
        }

    };

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-1 flex flex-col">
                <div
                    className="h-auto p-6 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
                    <div className="flex flex-col justify-start items-start gap-1">
                        <div className="text-lg font-semibold tracking-normal text-cyan-800">
                            {source === 'UserTable'
                                ? 'New user registration'
                                : 'Add a new staff member'}
                        </div>

                        <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                            The user will receive a link to setup their account and create a password.
                        </div>
                    </div>
                    <button onClick={closeDrawer}
                            className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
                        <XIcon/>
                    </button>
                </div>
                <div className="p-4 sm:p-7 px-4 sm:px-6 w-full">
                    <form>
                        <div className="flex w-full flex-col space-y-6 items-center">
                            <div className="grid grid-cols-2 gap-4 w-full ">
                                <TextInput
                                    id="firstName"
                                    type="text"
                                    label="First Name"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                />
                                <TextInput
                                    id="lastName"
                                    type="text"
                                    label="Last Name"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="flex flex-col gap-[0.625rem] w-full">
                                <label htmlFor="roleId"
                                       className="w-full block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                                    Role(s)
                                </label>
                                <CustomSelect
                                    options={jobRolesOptions}
                                    isMulti={true}
                                    onChange={(selectedOptions) => {
                                        const selectedValues = selectedOptions.map((option: any) => option.value);
                                        setFormData((prevData: any) => ({
                                            ...prevData,
                                            jobRoleIds: selectedValues
                                        }));
                                    }}
                                    value={formData.jobRoleIds}
                                />
                            </div>
                            {source === 'HrTable' && (
                                <div className="flex flex-col gap-[0.625rem] w-full">
                                    <label htmlFor="roleId"
                                           className="w-full block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                                        Supervisor(s)
                                    </label>
                                    <CustomSelect
                                        options={supervisorSelectOptions}
                                        isMulti={true}
                                        onChange={(selectedOptions) => {
                                            const selectedValues = selectedOptions.map((option: any) => option.value);
                                            setFormData((prevData: any) => ({
                                                ...prevData,
                                                supervisorIds: selectedValues
                                            }));
                                        }}
                                        value={formData.supervisorIds}
                                    />
                                </div>
                            )}
                            <div className="w-full">
                                <TextInput
                                    id="email"
                                    error={emailError}
                                    type="text"
                                    label=" Company email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="w-full">
                                <TextInput
                                    id="personalEmail"
                                    type="text"
                                    label="Personal Email"
                                    value={formData.personalEmail}
                                    onChange={handleChange}
                                />
                            </div>
                            {source === 'HrTable' && (
                                <>
                                    <div className="w-full">
                                        <PhoneNumberInput
                                            id="phoneNumber"
                                            label="Mobile phone"
                                            value={formData.phoneNumber || ""}
                                            onChange={handleChange}
                                            phoneNumber={phoneNumber}
                                            setPhoneNumber={setPhoneNumber}
                                        />
                                    </div>
                                    <div className="w-full ">
                                        <SSNInput
                                            id="ssn"
                                            setSSN={setSSN}
                                            ssn={ssn}
                                            label="SSN"
                                            value={formData.ssn || ""}
                                            onChange={(e) => handleChange(e)}
                                        />

                                    </div>
                                    <div className="w-full">
                                        <RadioInputHorizontal
                                            label="Mark as supervisor?"
                                            options={[
                                                {id: 'isSupervisorYes', label: 'Yes', value: true},
                                                {id: 'isSupervisorNo', label: 'No', value: false}
                                            ]}
                                            selectedValue={formData.isSupervisor}
                                            onChange={(value) =>
                                                handleRadioChange('isSupervisor', value)
                                            }
                                        />
                                    </div>
                                    <div className="w-full ">
                                        <RadioInputHorizontal
                                            label="Invite user to setup their account?"
                                            options={[
                                                {id: 'inviteYes', label: 'Yes', value: true},
                                                {id: 'inviteNo', label: 'No', value: false}
                                            ]}
                                            selectedValue={formData.invite}
                                            onChange={(value) => handleRadioChange('invite', value)}
                                        />
                                    </div>
                                    {formData.userType === 'user' && formData.invite === true && (
                                        <div className="w-full">
                                            <div className="flex justify-between items-center mb-3">
                                                <h3 className="text-slate-800 text-sm font-semibold dark:text-neutral-200">
                                                    Permissions
                                                </h3>
                                                <div
                                                    className="flex justify-center items-center space-x-8 text-xs pr-3 font-normal text-slate-400 dark:text-neutral-200">
                                                    <span>View</span>
                                                    <span>Create</span>
                                                    <span>Update</span>
                                                    <span>Delete</span>
                                                </div>
                                            </div>
                                            <div
                                                className="bg-white dark:bg-neutral-800 w-full rounded-lg  border divide-y divide-slate-200 ">

                                                {permissionSchemeGroups.map((permissionSchemeGroup: PermissionSchemeGroup) => (
                                                    <div key={permissionSchemeGroup.subjectClass}
                                                         className="flex justify-between items-center mb-3 space-y-3 ">
                                                        <span
                                                            className="text-xs font-normal pl-3 pt-3 text-slate-600 dark:text-neutral-200">
                                                            {permissionSchemeGroup.nameAlias}
                                                        </span>
                                                        <div className="flex space-x-14 pr-6  ">
                                                            {['view', 'create', 'update', 'delete'].map((action) => (
                                                                <div key={action} className="flex items-center">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`${permissionSchemeGroup.subjectClass}_permission_${action}`}
                                                                        name={`permission_${action}`}
                                                                        checked={!!formData.role?.permissions?.find((p: Permission) => p.subjectClass === permissionSchemeGroup.subjectClass && p.action === action && !p._destroy)}
                                                                        onChange={handleCheckboxChange}
                                                                        disabled={!!formData.role?.permissions?.find((p: Permission) => p.subjectClass === permissionSchemeGroup.subjectClass && p.action === 'manage' && !p._destroy) && action !== 'manage'}
                                                                        className="border-gray-200 rounded text-cyan-400 focus:ring-cyan-300 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                </>
                            )}
                            {source === 'UserTable' && (
                                <div className="w-full ">
                                    <RadioInputHorizontal
                                        label="Is this user also a staff member?"
                                        options={[
                                            {id: 'staffMemberYes', label: 'Yes', value: true},
                                            {id: 'staffMemberNo', label: 'No', value: false}
                                        ]}
                                        selectedValue={formData.staffMember}
                                        onChange={(value) => handleRadioChange('staffMember', value)}
                                    />
                                </div>


                            )}

                            {formData.userType === 'user' && formData.staffMember === true && (
                                <div className="flex w-full flex-col space-y-6 items-center">
                                    <div className="flex flex-col gap-[0.625rem] w-full">
                                        <label htmlFor="roleId"
                                               className="w-full block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                                            Supervisor(s)
                                        </label>
                                        <CustomSelect
                                            options={supervisorSelectOptions}
                                            isMulti={true}
                                            onChange={(selectedOptions) => {
                                                const selectedValues = selectedOptions.map((option: any) => option.value);
                                                setFormData((prevData: any) => ({
                                                    ...prevData,
                                                    supervisorIds: selectedValues
                                                }));
                                            }}
                                            value={formData.supervisorIds}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <PhoneNumberInput
                                            id="phoneNumber"
                                            label="Mobile phone"
                                            value={formData.phoneNumber || ""}
                                            onChange={handleChange}
                                            phoneNumber={phoneNumber}
                                            setPhoneNumber={setPhoneNumber}
                                        />
                                    </div>
                                    <div className="w-full ">
                                        <SSNInput
                                            id="ssn"
                                            setSSN={setSSN}
                                            ssn={ssn}
                                            label="SSN"
                                            value={formData.ssn || ""}
                                            onChange={(e) => handleChange(e)}
                                        />

                                    </div>
                                    <div className="w-full">
                                        <div className="flex flex-col gap-[0.625rem] w-full">
                                            <label htmlFor="roleId"
                                                   className="w-full block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                                                Applicable location(s)
                                            </label>
                                            <CustomSelect
                                                options={locationOptions}
                                                isMulti={true}
                                                onChange={(selectedOptions) => {
                                                    const selectedValues = selectedOptions.map((option: any) => option.value);
                                                    setFormData((prevData: any) => ({
                                                        ...prevData,
                                                        locationIds: selectedValues
                                                    }));
                                                }}
                                                value={formData.locationIds}
                                            />
                                        </div>
                                    </div>


                                    <div className="flex w-full justify-between items-center mb-3">
                                        <h3 className="text-slate-800 text-sm font-semibold dark:text-neutral-200">
                                            Permissions
                                        </h3>
                                        <div
                                            className="flex  justify-center items-center space-x-8 text-xs pr-3 font-normal text-slate-400 dark:text-neutral-200">
                                            <span>View</span>
                                            <span>Create</span>
                                            <span>Update</span>
                                            <span>Delete</span>
                                        </div>
                                    </div>
                                    <div
                                        className="bg-white dark:bg-neutral-800 w-full rounded-lg  border divide-y divide-slate-200 ">

                                        {permissionSchemeGroups.map((permissionSchemeGroup: PermissionSchemeGroup) => (
                                            <div key={permissionSchemeGroup.subjectClass}
                                                 className="flex justify-between items-center mb-3 space-y-3 ">
                                                        <span
                                                            className="text-xs font-normal pl-3 pt-3 text-slate-600 dark:text-neutral-200">
                                                            {permissionSchemeGroup.nameAlias}
                                                        </span>
                                                <div className="flex space-x-14 pr-6  ">
                                                    {['view', 'create', 'update', 'delete'].map((action) => (
                                                        <div key={action} className="flex items-center">
                                                            <input
                                                                type="checkbox"
                                                                id={`${permissionSchemeGroup.subjectClass}_permission_${action}`}
                                                                name={`permission_${action}`}
                                                                checked={!!formData.role?.permissions?.find((p: Permission) => p.subjectClass === permissionSchemeGroup.subjectClass && p.action === action && !p._destroy)}
                                                                onChange={handleCheckboxChange}
                                                                disabled={!!formData.role?.permissions?.find((p: Permission) => p.subjectClass === permissionSchemeGroup.subjectClass && p.action === 'manage' && !p._destroy) && action !== 'manage'}
                                                                className="border-gray-200 rounded text-cyan-400 focus:ring-cyan-300 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="w-full">
                                        <RadioInputHorizontal
                                            label="Mark as supervisor?"
                                            options={[
                                                {id: 'isSupervisorYes', label: 'Yes', value: true},
                                                {id: 'isSupervisorNo', label: 'No', value: false}
                                            ]}
                                            selectedValue={formData.isSupervisor}
                                            onChange={(value) =>
                                                handleRadioChange('isSupervisor', value)
                                            }
                                        />
                                    </div>

                                </div>
                            )}


                        </div>

                    </form>
                </div>
            </div>
            <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
                <div className="border-t border-slate-200 w-full">
                    <div
                        className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <SubmitButton onClick={handleSubmit}
                                      disabled={status === 'loading' || !isFormValid || isSubmitting}
                                      data-hs-overlay="#hs-basic-modal"
                                      label={'Create'}
                        />
                    </div>
                    <div
                        className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <button type="submit" onClick={closeDrawer}
                                className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                            Never mind
                        </button>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default InvitationsForm;
