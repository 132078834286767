import { UserMedicalDocumentService } from 'services/userMedicalDocumentService';
import { createEntityThunks } from 'utils/createEntityThunks';
import { UserMedicalDocument } from 'types/userMedicalDocument';

const userMedicalDocumentFactory = (data: any) => new UserMedicalDocument(data);

export const UserMedicalDocumentThunks = createEntityThunks<UserMedicalDocument>(
    'userMedicalDocument',
    UserMedicalDocumentService,
    { activate: false, deactivate: false },
    userMedicalDocumentFactory
)
