import React from 'react';
import TextInput from 'components/inputs/TextInput';
import {ElementProps, TextInputElement} from "types/documentTemplateElement";

interface TextInputElementProps extends ElementProps<TextInputElement> {}

const TextInputElementComponent: React.FC<TextInputElementProps> = ({ element, updateElement }) => {
    return (
        <div className="w-full m-8">
            <TextInput
                id={element.id}
                type="text"
                label={element.showLabel ? element.label : ""}
                value=""
                placeholder={element.placeholder}
                required={element.required}
                onChange={() => {}}
            />
        </div>
    );
};

export default TextInputElementComponent;
