import  React from 'react';


const ColumnsIcon = () => (

    <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="columns">
            <path id="Vector"
                  d="M11.0833 1.75H2.91667C2.27233 1.75 1.75 2.27233 1.75 2.91667V11.0833C1.75 11.7277 2.27233 12.25 2.91667 12.25H11.0833C11.7277 12.25 12.25 11.7277 12.25 11.0833V2.91667C12.25 2.27233 11.7277 1.75 11.0833 1.75Z"
                  stroke="#1F2937" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_2" d="M7 1.75V12.25" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
    </svg>
);
export default ColumnsIcon;
