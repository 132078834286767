import React, { useState, useRef, useEffect } from "react";
import { createPopper } from '@popperjs/core';
import SortableIcon from "assets/images/icons/SortableIcon";
import ArrowTopIcon from "assets/images/icons/ArrowTopIcon";
import ArrowBottomIcon from "assets/images/icons/ArrowBottomIcon";
import { useSorting } from "hooks/useTableSorting";

interface TableHeaderProps {
    label: string;
    sortBy?: string;
}

const TableHeader: React.FC<TableHeaderProps> = ({ label, sortBy }) => {
    const { sortField, sortDirection, setSortField, setSortDirection } = useSorting();
    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (buttonRef.current && dropdownRef.current) {
            createPopper(buttonRef.current, dropdownRef.current, {
                placement: 'bottom-start',
            });
        }

        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && buttonRef.current &&
                !dropdownRef.current.contains(event.target as Node) &&
                !buttonRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);


    const handleSort = (direction: 'asc' | 'desc') => () => {
        if (sortBy) {
            setSortField(sortBy);
            setSortDirection(direction);
        }
        setIsOpen(false);
    };

    const toggleDropdown = () => {
        setIsOpen((prevIsOpen) => !prevIsOpen);
    };

    const dropdownId = `dropdown-${label.replace(/\s+/g, '-').toLowerCase()}`;

    return (
        <th scope="col" className="whitespace-nowrap  px-1 text-left text-sm font-medium text-zinc-400 tracking-normal leading-5 dark:text-neutral-300">

            <div className="hs-dropdown relative inline-flex w-full cursor-pointer">

                <button
                    id={dropdownId}
                    type="button"
                    className="px-5 py-2.5 text-start w-full flex items-center gap-x-1 text-sm font-normal text-gray-400 focus:outline-none dark:text-neutral-500 dark:focus:bg-neutral-700"
                    onClick={toggleDropdown}
                    ref={buttonRef}
                >
                    {label}
                    {sortBy && (
                        <>
                            {sortField === sortBy && sortDirection === "asc" ? (
                                <ArrowTopIcon />
                            ) : sortField === sortBy && sortDirection === "desc" ? (
                                <ArrowBottomIcon />
                            ) : (
                                <SortableIcon />
                            )}
                        </>
                    )}
                </button>
                {isOpen && sortBy && (
                    <div
                        className="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-100 z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]
                                    dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)] dark:bg-neutral-900 block"
                        aria-labelledby={dropdownId}
                        ref={dropdownRef}
                    >
                        <div className="p-1">
                            <button
                                type="button"
                                className="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal text-gray-800 hover:bg-gray-100
                                           disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                                onClick={handleSort('asc')}
                            >
                                <ArrowTopIcon />
                                Sort ascending
                            </button>
                            <button
                                type="button"
                                className="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal text-gray-800 hover:bg-gray-100
                                           disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                                onClick={handleSort('desc')}
                            >
                                <ArrowBottomIcon />
                                Sort descending
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </th>
    );
};

export default TableHeader;
