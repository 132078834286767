import axios from 'axios';
import { showToast } from 'components/ToastContainer';
import { toCamelCase, toSnakeCase } from "utils/caseConverter";
import {jwtDecode} from 'jwt-decode';

const baseURL = process.env.REACT_APP_API_URL || 'https://newhope-qa-ebbc3a03dc72.herokuapp.com';

export const instance = axios.create({
    baseURL: baseURL,
});

const renewToken = async () => {
    const token = localStorage.getItem('token');
    if (token) {
        try {
            const response = await axios.post(`${baseURL}/api/v1/renew_token`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            });
            const newToken = response.data.token;
            localStorage.setItem('token', newToken);
            return newToken;
        } catch (error) {
            localStorage.removeItem('token');
            window.location.href = '/login';
        }
    }
    return null;
};

if (process.env.NODE_ENV !== 'test') {
    instance.interceptors.request.use(
        async (config) => {
            let token = localStorage.getItem('token');
            if (token) {
                const decodedToken = jwtDecode(token);
                const now = Date.now().valueOf() / 1000;
                if(decodedToken === undefined) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                    return Promise.reject();
                }
                if (decodedToken.exp && decodedToken.exp - now < 300) {
                    token = await renewToken();
                }

                if (token) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.config.url.includes('/login') || error.config.url.includes('/verify_otp')) {
                        showToast(error.response.data.message, 'error');
                    } else {
                        localStorage.removeItem('token');
                        window.location.href = '/login';
                    }
                } else {
                    const message = error.response.data.message || 'Something went wrong';
                    showToast(message, 'error');
                }
            } else {
                showToast('Network Error', 'error');
            }
            return Promise.reject(error);
        }
    );

    instance.interceptors.request.use((config) => {
        if (config.data) {
            config.data = toSnakeCase(config.data);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    instance.interceptors.response.use((response) => {
        if (response.data) {
            response.data = toCamelCase(response.data);
        }
        return response;
    }, (error) => {
        return Promise.reject(error);
    });
}

export default instance;
