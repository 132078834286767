import React from 'react';
import DateInput from 'components/inputs/DateInput';
import {ElementProps, DateInputElement} from "types/documentTemplateElement";


interface DateInputElementProps extends ElementProps<DateInputElement> {}

const DateInputElementComponent: React.FC<DateInputElementProps> = ({ element, updateElement }) => {
    return (
        <div className="w-full m-8">
            <DateInput
                id={element.id}
                label={element.showLabel ? element.label : ""}
                type="date"
                value=""
                isClickDisabled={true}
                onChange={() => {}}
                required={element.required}
                placeholder="MM/DD/YYYY"
                allowPastDates={element.allowPastDates}
            />
        </div>
    );
};

export default DateInputElementComponent;
