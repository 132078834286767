import React from "react";
import LogoNewHope from "assets/images/LogoNewHope.png";
import SidePanel from "components/SidePanel";
import useMinHeight from "hooks/useMinHeight";
import {Outlet} from "react-router-dom";

interface AuthLayoutProps {
  backgroundSrc: string;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({backgroundSrc}) => {
  const {minHeight, contentRef, minContentHeight} = useMinHeight();

  return (
    <div className="flex p-8">
      <div
        className="flex w-full"
        style={{height: minContentHeight}}
        ref={contentRef}>
        <div
          className="flex flex-col xl:w-[42%] lg:w-[50%] md:w-[60%] max-md:w-full"
          style={{height: minContentHeight}}>
          <div className="flex flex-col" style={{height: minContentHeight}}>
            <img
              className="relative w-[140.85px] h-[33px] object-cover"
              src={LogoNewHope}
              alt="Logo"
            />
            <div
              className="w-full flex justify-center"
              style={{height: minContentHeight}}>
              <Outlet />
            </div>
          </div>
        </div>
        <div
          className="flex justify-end  2xl:justify-center xl:w-[58%] lg:w-[50%] md:w-[40%] w-0"
          style={{minHeight}}>
          <SidePanel src={backgroundSrc} alt="Background de inicio de sesión" />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
