import React, {useState} from "react";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import TableCell from "components/table/TableCell";
import {UserMedicalDocument} from "types/userMedicalDocument";
import Table from "components/table/Table";
import {SearchType} from "types/search";
import {UserMedicalDocumentThunks} from "./userMedicalDocumentThunks";
import {
    setIsEditing,
    setUserMedicalDocument
} from "features/userMedicalDocument/userMedicalDocumentSlice";
import {setActiveTab} from "features/hr/hrSlice";
import {useDropdownActionMenu} from "hooks/useDropdownActionMenu";
import {useNavigate, useParams} from "react-router-dom";
import WhiteButton from "components/WhiteButton";
import DownloadIcon from "assets/images/icons/DownloadIcon";
import TrashIcon from "assets/images/icons/TrashIcon";
import CheckCircleIcon from "assets/images/icons/CheckCircleIcon";
import XCircleIcon from "assets/images/icons/XCircleIcon";
import DotsMenu from "components/DotsMenu";
import useAttachmentModal from "hooks/useAttachmentModal";
import Skeleton from "react-loading-skeleton";

const UserMedicalDocumentTable: React.FC<{ openDrawer: () => void }> = ({openDrawer}) => {
    const userMedicalDocuments = useSelector((state: RootState) => state.userMedicalDocument.userMedicalDocuments);
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.userMedicalDocument.pagy);
    const {setDotsMenuIsOpen} = useDropdownActionMenu();
    const navigate = useNavigate();
    const [selectedAttachmentUrl, setSelectedAttachmentUrl] = useState<string | null>(null);
    const params = useParams<{ userId: string }>();
    const loading = useSelector((state: RootState) => state.userMedicalDocument.status === 'loading');

    const {AttachmentModal, handleNameClick} = useAttachmentModal<UserMedicalDocument>({
        openDrawer,
        fetchItemThunk: UserMedicalDocumentThunks.show,
    });

    const [visibleColumns, setVisibleColumns] = useState({
        name: true,
        agency: true,
        status: true,
        expirationDate: true,
        remindUser: true,
        notes: true
    });

    interface VisibleColumnsType {
        name: boolean,
        agency: boolean,
        status: boolean,
        expirationDate: boolean,
        remindUser: boolean,
        notes: boolean
    }

    const fetchData = async ({page, search, sortField, sortDirection, rowsPerPage}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        const {userId} = params;

        search.where.user_id = {
            operator: '==',
            value: userId as string
        };
        await dispatch(UserMedicalDocumentThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
    };

    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };

    const handleOpenDrawer = (id: string) => async () => {
        dispatch(setIsEditing(false));
        openDrawer();
        await dispatch(UserMedicalDocumentThunks.show(id));
    };

    const searchByOptions = [
        {label: 'Document', value: 'name', operator: 'like'},
        {label: 'Agency', value: 'agency', operator: 'like'},
    ];

    const columnOptions = [
        {label: 'Document', value: 'name', isVisible: visibleColumns.name},
        {label: 'Agency', value: 'agency', isVisible: visibleColumns.agency},
        {label: 'Status', value: 'status', isVisible: visibleColumns.status},
        {label: 'Exp date', value: 'expirationDate', isVisible: visibleColumns.expirationDate},
        {label: 'Reminder', value: 'reminder', isVisible: visibleColumns.remindUser},
        {label: 'Notes', value: 'notes', isVisible: visibleColumns.notes}
    ];

    const handleDelete = async (id: string) => {
        const newMedicalDocument = new UserMedicalDocument ({id, attachment: null, deleteAttachment: true});
        await dispatch(UserMedicalDocumentThunks.update(newMedicalDocument));
        setDotsMenuIsOpen(null);
    };

    const handleEdit = async (id: string) => {
        await dispatch(UserMedicalDocumentThunks.show(id));
        dispatch(setIsEditing(true));
        openDrawer();
    };

    const handleDownload = (attachmentUrl: string) => {
        if (attachmentUrl) {
            window.open(attachmentUrl, '_blank')
        }
    };

    const handleSelectDownload = () => {
        if (selectedAttachmentUrl) {
            window.open(selectedAttachmentUrl, '_blank');
        }
    };

    return (
        <>
            <Table
                tabs={['HR Documents', 'Certifications', 'Trainings', 'Background Documents', 'Medical Documents']}
                activeTab={'Medical Documents'}
                setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
                fetchData={fetchData}
                pagy={pagy}
                pageable={true}
                recordsName={'Documents'}
                searchable={true}
                columns={true}
                columnOptions={columnOptions}
                onColumnToggle={handleColumnToggle}
                searchByOptions={searchByOptions}
                loading={loading}
                blueButton={<WhiteButton onClick={handleSelectDownload} label={''} icon={<DownloadIcon/>}/>}
            >
                <thead>
                <tr>
                    <th scope="col" className="px-3  pt-3.5  flex justify-center">
                        <input type="checkbox"
                               className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"/>
                    </th>
                    {visibleColumns.name && <TableHeader label="Document" sortBy="name"/>}
                    {visibleColumns.agency && <TableHeader label="Agency" sortBy="agency"/>}
                    {visibleColumns.status && <TableHeader label="Status" sortBy="status"/>}
                    {visibleColumns.expirationDate && <TableHeader label="Exp date" sortBy="expDate"/>}
                    {visibleColumns.remindUser && <TableHeader label="Reminder" sortBy="remindUser"/>}
                    {visibleColumns.notes && <TableHeader label="Notes"/>}
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                {userMedicalDocuments.length !== 0 && userMedicalDocuments.map((userMedicalDocument: UserMedicalDocument) => {
                        const userOptions = [
                            {
                                label: 'Edit',
                                onClick: () => handleEdit(userMedicalDocument.id),
                                hoverClass: "hover:bg-cyan-100 ",
                            },
                            ...(userMedicalDocument.status === 'Complete'
                                ? [
                                    {
                                        label: 'Download',
                                        onClick: () => handleDownload(userMedicalDocument.attachmentUrl || ''),
                                        hoverClass: "hover:bg-cyan-100",
                                    },
                                    {
                                        label: 'Remove attached document',
                                        onClick: () => handleDelete(userMedicalDocument.id),
                                        hoverClass: "hover:bg-red-100 hover:text-red-800",
                                    },
                                ]
                                : []),
                            ...(userMedicalDocument.status === 'Missing'
                                ? [
                                    {
                                        label: 'Upload',
                                        onClick: () => handleEdit(userMedicalDocument.id),
                                        hoverClass: "hover:bg-cyan-100 ",
                                    },
                                ]
                                : []),

                        ];
                        if (loading) {
                            return (
                                <TableRow key={userMedicalDocument.id}>
                                    <td colSpan={6}>
                                        <Skeleton count={1} height={40}/>
                                    </td>
                                </TableRow>
                            );
                        } else {

                            return (

                                <TableRow
                                    key={userMedicalDocument.id}
                                >
                                    <td scope="col" className="pt-3.5 flex justify-center">
                                        <input
                                            type="checkbox"
                                            className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                            onClick={(e) => e.stopPropagation()}/>
                                    </td>
                                    {visibleColumns.name &&
                                        <TableCell>
                                            <div className=" relative group">
                                                <div className="w-full flex items-center gap-x-3">
                                                    <div className="flex-grow">
                                        <span
                                            className={`text-sm  font-medium dark:text-neutral-200 ${
                                                userMedicalDocument.status === 'Complete' ? 'underline text-cyan-600 cursor-pointer' : ''}`}
                                            onClick={userMedicalDocument.status === 'Complete' ? () => handleNameClick(userMedicalDocument) : undefined}
                                        >
                                            {userMedicalDocument.name}

                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </TableCell>
                                    }
                                    {visibleColumns.agency && <TableCell>{userMedicalDocument.agency}</TableCell>}
                                    {visibleColumns.status &&
                                        <TableCell>
                                <span
                                    className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-28
                                    ${userMedicalDocument.status === 'Complete' ? 'bg-green-100 text-green-800' : ''}
                                    ${userMedicalDocument.status === 'Missing' ? 'bg-red-100 text-red-800' : ''}
                                    `}
                                >
                                    {userMedicalDocument.status === 'Complete' &&
                                        <CheckCircleIcon className="mr-2"/>}
                                    {userMedicalDocument.status === 'Missing' && <XCircleIcon className="mr-2"/>}

                                    <div className="pl-1">
                                        {userMedicalDocument.status}
                                    </div>
                                </span>
                                        </TableCell>
                                    }
                                    {visibleColumns.expirationDate &&
                                        <TableCell>{userMedicalDocument.expirationDate}</TableCell>}
                                    {visibleColumns.remindUser && <TableCell>{userMedicalDocument.remindUser ? 'Yes' : 'No'}</TableCell>}
                                    {visibleColumns.notes &&
                                        <TableCell className="whitespace-nowrap overflow-hidden text-ellipsis">
                                            <div style={{maxWidth: '150px'}}
                                                 className="whitespace-nowrap overflow-hidden text-ellipsis">
                                                {userMedicalDocument.notes}
                                            </div>
                                        </TableCell>}
                                    <TableCell>
                                        <DotsMenu options={userOptions}/>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    }
                )}
                </tbody>
            </Table>
            <AttachmentModal/>
        </>
    );
};

export default UserMedicalDocumentTable;
