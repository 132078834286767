import { createEntityService } from 'utils/createEntityService';
import { UserBackgroundDocument } from 'types/userBackgroundDocument';

export const UserBackgroundDocumentService = createEntityService<UserBackgroundDocument>({
    baseUrl: 'api/v1/user_background_documents',
    entityName: 'userBackgroundDocument',
    methodConfigs: {
        create: {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
        update: {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    }
});
