import {User, UserInterface} from "./user";
import {Permission, PermissionInterface} from "./permission";

export interface RoleInterface {
    id: string;
    name?: string;
    status?: string;
    nameAlias?: string;
    description?: string;
    users?: UserInterface[];
    permissions?: PermissionInterface[];
    permissionsAttributes?: PermissionInterface[];
    selectable?: boolean;
    createdAt?: string;

}

export class Role implements RoleInterface {
    id: string;
    name: string;
    status?: string;
    nameAlias?: string;
    description?: string;
    users?: User[];
    permissions?: Permission[];
    selectable?: boolean;
    createdAt?: string;

    constructor(data: Partial<RoleInterface>) {
        this.id = data.id!;
        this.name = data.name!;
        this.status = data.status;
        this.nameAlias = data.nameAlias;
        this.description = data.description;
        this.permissions = data.permissions?.map(permission => new Permission(permission)) || [];
        this.users = data.users?.map(user => new User(user)) || [];
        this.selectable = data.selectable;
        this.createdAt = data.createdAt;
    }

    isOwner(): boolean {
        return this.name === 'owner'
    }

    toJson(): RoleInterface {
        return {
            id: this.id,
            nameAlias: this.nameAlias,
            status: this.status?.toLowerCase(),
            description: this.description,
            permissionsAttributes: this.permissions?.map(permission => permission.toJson()),
            selectable: this.selectable,

        };
    }
}
