import {createEntitySlice} from "utils/createEntitySlice";
import {Payer} from "types/payer";
import {PayerThunks} from "features/payer/payerThunks";

export interface PayerState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    payers?: any;
    pagy?: any;
    payer?: any;
    isEditing?: boolean;
    activeTab?: string;

}

const initialState: PayerState = {
    status: 'idle',
    error: null,
    payers: [],
    payer: {name: '', nameAlias: ''},
    pagy: {},
    isEditing: false,
    activeTab: 'All'
};
const payerSlice = createEntitySlice<Payer>({
    name: 'payer',
    initialState,
    thunks: PayerThunks,
    entityKey: 'payer',
    entitiesKey: 'payers',
    reducers: {
    },
});


export const {setEntity:setPayer, setIsEditing, setActiveTab} = payerSlice.actions;
export {initialState as payerInitialState};
export default payerSlice.reducer;
