import React, {useContext, useEffect, useState} from "react";
import DocumentTemplateTable from "features/documentTemplate/DocumentTemplateTable";
import {TitleContext} from "../../components/TitleContext";



const DocumentTemplatesPage: React.FC = () => {
    const openDrawer = () => {  };
    const { setTitle } = useContext(TitleContext);

    useEffect(() => {
        setTitle(
            <div>
                <div className="text-slate-600 text-lg font-medium font-['Inter']  ">
                    Document Builder
                </div>
                <div
                    className="text-slate-400 text-sm font-light font-['Inter'] leading-normal ">
                    Manage custom document templates
                </div>
            </div>
        );
    }, []);

    return (
        <div className="">
            <div className="relative overflow-hidden">
                <div className="relative">
                    <DocumentTemplateTable openDrawer={openDrawer}/>
                </div>
            </div>
        </div>
    );
};
export default DocumentTemplatesPage;
