
import {UserCredentialThunks} from "features/userCredential/userCredentialThunks";
import {createEntitySlice} from "utils/createEntitySlice";
import {UserCredential} from "types/userCredential";

export interface UserCredentialState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    userCredentials?: any;
    pagy?: any;
    userCredential?: any;
    isEditing?: boolean;
}

const initialState: UserCredentialState = {
    status: 'idle',
    error: null,
    userCredentials: [],
    userCredential: {name: '', nameAlias: ''},
    pagy: {},
    isEditing: false
};

const userCredentialSlice = createEntitySlice<UserCredential>({
    name: 'Certification',
    initialState,
    thunks: UserCredentialThunks,
    entityKey: 'userCredential',
    entitiesKey: 'userCredentials',
    reducers: {
    },
});

export const { setEntity: setUserCredential, setIsEditing, setActiveTab } = userCredentialSlice.actions;
export {initialState as userBackgroundDocumentInitialState};
export default userCredentialSlice.reducer;
