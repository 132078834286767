import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import React, {useEffect, useState} from "react";
import TextInput from "components/inputs/TextInput";
import {UserBackgroundDocument} from "types/userBackgroundDocument";
import SubmitButton from "components/SubmitButton";
import {UserBackgroundDocumentThunks} from "features/userBackgroundDocument/userBackgroundDocumentThunks";
import XIcon from "assets/images/icons/XIcon";
import DateInput from "components/inputs/DateInput";
import DescriptionInput from "components/inputs/DescriptionInput";
import RadioInput from "components/inputs/RadioInput";
import {Training} from "types/training";
import FileInput from "components/inputs/FileInput";
import {Credential} from "types/credential";
import FileDetailBox from "components/FileDetailBox";

interface UserBackgroundDocumentFormProps {
    id?: string,
    closeDrawer: () => void,
    isDrawerOpen?: boolean
}

const UserBackgroundDocumentForm: React.FC<UserBackgroundDocumentFormProps> = ({id, closeDrawer, isDrawerOpen}) => {
    const userBackgroundDocument = useSelector((state: RootState) => state.userBackgroundDocument.userBackgroundDocument);
    const dispatch = useDispatch<AppDispatch>();
    const [updatedUserBackgroundDocument, setUpdatedUserBackgroundDocument] = useState({...userBackgroundDocument});
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [isLoadingFile, setIsLoadingFile] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleFileChange = (files: FileList | null) => {
        if (files && files.length > 0) {
            const attachment = files[0];
            setUploadedFile(attachment);
            setUpdatedUserBackgroundDocument((prevState: UserBackgroundDocument) => ({
                ...prevState,
                attachment: attachment,
            }));
            setIsLoadingFile(true);
        }
    };

    useEffect(() => {
        if (!isLoadingFile) return;

        const loadingDuration = 2000;
        const timer = setTimeout(() => {
            setIsLoadingFile(false);
        }, loadingDuration);

        return () => clearTimeout(timer);
    }, [isLoadingFile]);
    
    
    useEffect(() => {
        setUpdatedUserBackgroundDocument(userBackgroundDocument);
        setIsSaveDisabled(!userBackgroundDocument?.name);
    }, [userBackgroundDocument]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement| HTMLTextAreaElement>) => {
        const {id, value} = e.target;
        setUpdatedUserBackgroundDocument({...updatedUserBackgroundDocument, [id]: value});
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (userBackgroundDocument?.id) {
            await dispatch(UserBackgroundDocumentThunks.update(new UserBackgroundDocument(updatedUserBackgroundDocument))).then(() => closeDrawer());
        } else {
            await dispatch(UserBackgroundDocumentThunks.create(new UserBackgroundDocument(updatedUserBackgroundDocument))).then(() => closeDrawer());
        }
    };
    useEffect(() => {
        const isFormValid =
            (uploadedFile !== null || userBackgroundDocument?.attachmentUrl) && !isLoadingFile;

        setIsSaveDisabled(!isFormValid);
    }, [uploadedFile, updatedUserBackgroundDocument, isLoadingFile]);
    
    const handleSelectChange = (value: string) => {
        setUpdatedUserBackgroundDocument((prevState: Credential) => ({
            ...prevState,
           userBackgroundDocumentId: value,
        }));
        setIsSaveDisabled(!value);
    };

    useEffect(() => {
        const isFormValid =
            (uploadedFile !== null || userBackgroundDocument?.attachmentUrl) && !isLoadingFile;

        setIsSaveDisabled(!isFormValid);
    }, [uploadedFile, updatedUserBackgroundDocument, isLoadingFile]);

    const handleRadioChange = (field: string, value: boolean) => {
        setUpdatedUserBackgroundDocument((prevState: Training) => ({
            ...prevState,
            [field]: value,
        }));
    };
    useEffect(() => {
        if (!isDrawerOpen)
            handleClose()
    }, [isDrawerOpen]);
    
    const handleClose = () => {
        setUpdatedUserBackgroundDocument({
            id: '',
            notes: '',
        })

        setUploadedFile(null);
        setIsSaveDisabled(true);
        setIsSubmitting(false);
    };

    const handleDelete = () => {
        setUploadedFile(null);
        setUpdatedUserBackgroundDocument((prevState: UserBackgroundDocument) => ({
            ...prevState,
            attachment: null,
            deleteAttachment: true,
            attachmentUrl: null
        }));
    };

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-1 flex flex-col">
                <div className="h-auto p-6 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
                    <div className="flex flex-col justify-start items-start gap-1">
                        <div className="text-lg font-semibold tracking-normal text-cyan-800">
                            {`Upload ${userBackgroundDocument?.name}`}
                        </div>

                        <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                            {`Add an employees required document so you can keep track of their status and remain compliant.`}
                        </div>
                    </div>
                    <button onClick={closeDrawer} className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
                        <XIcon/>
                    </button>
                </div>
                <div className="h-full px-6 pb-6  ">
                    <div className="mt-5">
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col space-y-6 items-center mt-7">
                                <div className="w-full">
                                    <FileInput
                                        onFileChange={handleFileChange}
                                        label="Drop your files here or"
                                        labelBlue="browse"
                                        subtitle="Maximum size: 50MB"
                                        disabled={isLoadingFile}
                                    />
                                </div>
                                {userBackgroundDocument?.attachmentUrl && uploadedFile === null && updatedUserBackgroundDocument.attachmentUrl !== null && (
                                    <div className="w-full">
                                        <FileDetailBox uploadedFile={null} fileOwner={userBackgroundDocument}
                                                       handleDelete={handleDelete}/>
                                    </div>

                                )}
                                {uploadedFile && (
                                    <div className="w-full">
                                        <FileDetailBox uploadedFile={uploadedFile} fileOwner={userBackgroundDocument}
                                                       handleDelete={handleDelete} isLoading={isLoadingFile} loadingTime={2000}/>
                                    </div>
                                )}
                                
                                <div className="w-full">
                                    <TextInput
                                        id="name"
                                        type="text"
                                        label="Name"
                                        value={updatedUserBackgroundDocument?.name}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="w-full">
                                    <TextInput
                                        id="agency"
                                        type="text"
                                        label="Agency"
                                        value={updatedUserBackgroundDocument?.agency}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="w-full">
                                    <DescriptionInput
                                        id="notes"
                                        type="text"
                                        label="Notes"
                                        value={updatedUserBackgroundDocument?.notes}
                                        placeholder=""
                                        onChange={handleChange}
                                        optional="optional"
                                    />
                                </div>

                                <div className="w-full">
                                    <DateInput
                                        id="expirationDate"
                                        type="text"
                                        label="Expiration date"
                                        value={updatedUserBackgroundDocument?.expirationDate}
                                        placeholder="MM/DD/YYYY"
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="w-full">
                                    <RadioInput
                                        label="Reminder"
                                        options={[
                                            {id: 'remindUserYes', label: 'Yes', value: true},
                                            {id: 'remindUserNo', label: 'No', value: false}
                                        ]}
                                        selectedValue={updatedUserBackgroundDocument?.remindUser}
                                        onChange={(value) =>
                                            handleRadioChange('remindUser', value)
                                        }


                                    />
                                </div>


                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <footer className="flex-shrink-0 px-6 pb-4 pt-5 flex justify-end gap-5">
                <div className="border-t border-slate-200 w-full">
                    <div
                        className="flex w-fulljustify-center items-center gap-x-2 pb-4">
                        <SubmitButton onClick={handleSubmit}
                                      label={`${isSubmitting ? 'Uploading' : 'Upload'}`}
                                      disabled={isSaveDisabled || isSubmitting}
                            />
                        </div>
                        <div
                            className="flex w-full justify-center items-center gap-x-2 pb-4">
                            <button type="submit" onClick={closeDrawer}
                                    className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                                Never mind
                            </button>
                        </div>
                    </div>
                </footer>
            </div>
    );
};

export default UserBackgroundDocumentForm;
