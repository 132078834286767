import React, {useState} from "react";
import Table from "components/table/Table";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import TableCell from "components/table/TableCell";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import {UserCredential} from "types/userCredential";
import {UserCredentialThunks} from "features/userCredential/userCredentialThunks";
import BlueButton from "components/BlueButton";
import PlusIcon from "assets/images/icons/PlusIcon";
import {SearchType} from "types/search";
import {useParams} from "react-router-dom";
import {setActiveTab} from "features/hr/hrSlice"
import {useDropdownActionMenu} from "hooks/useDropdownActionMenu";
import DotsMenu from "components/DotsMenu";
import {setUserCredential, setIsEditing} from "features/userCredential/userCredentialSlice";
import CheckCircleIcon from "assets/images/icons/CheckCircleIcon";
import XCircleIcon from "assets/images/icons/XCircleIcon";
import CircleAlertIconIcon from "assets/images/icons/CircleAlertIconIcon";
import TrashIcon from "assets/images/icons/TrashIcon";
import useAttachmentModal from "../../hooks/useAttachmentModal";

const UserCredentialTable: React.FC<{ openDrawer: () => void }> = ({openDrawer}) => {
    const credentials = useSelector((state: RootState) => state.userCredential.userCredentials);
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.userCredential.pagy);
    const params = useParams<{ userId: string }>();
    const {setDotsMenuIsOpen} = useDropdownActionMenu();
    const {AttachmentModal, handleNameClick} = useAttachmentModal<UserCredential>({
        openDrawer,
        fetchItemThunk: UserCredentialThunks.show,
    });

    interface VisibleColumnsType {
        credential: boolean;
        licensingAuthority: boolean;
        status: boolean;
        expDate: boolean;
        reminder: boolean;
        notes: boolean;
    }

    const [visibleColumns, setVisibleColumns] = useState({
        credential: true,
        licensingAuthority: true,
        status: true,
        expDate: true,
        reminder: true,
        notes: true
    });

    const searchByOptions = [
        {label: 'Certification', value: 'name', operator: 'like'},
    ];

    const columnOptions = [
        {label: 'Certification', value: 'credential', isVisible: visibleColumns.credential},
        {label: 'Licensing Authority', value: 'licensingAuthority', isVisible: visibleColumns.licensingAuthority},
        {label: 'Status', value: 'status', isVisible: visibleColumns.status},
        {label: 'Exp Date', value: 'expirationDate', isVisible: visibleColumns.expDate},
        {label: 'Reminder', value: 'remindUserAt', isVisible: visibleColumns.reminder},
        {label: 'Notes', value: 'notes', isVisible: visibleColumns.notes}
    ];

    const fetchData = async ({page, search, sortField, sortDirection, rowsPerPage}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        const {userId} = params;

        search.where.user_id = {
            operator: '==',
            value: userId as string
        };

        await dispatch(UserCredentialThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
    };

    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };
    const handleOpenDrawer = async (id: string) => {
        await dispatch(UserCredentialThunks.show(id));
        dispatch(setIsEditing(true));
        openDrawer();
    };

    const handleOpenNewUserCredentialDrawer = () => {
        dispatch(setUserCredential({
            id: '',
            name: '',
            licensingAuthority: '',
            expirationDate: '',
            reminderDate: '',
            notes: '',
            userId: params.userId
        }));
        dispatch(setIsEditing(true));
        openDrawer();
    };


    const handleDelete = async (id: string) => {
        const newCredentials = new UserCredential( {id, attachment: null, deleteAttachment: true});
        await dispatch(UserCredentialThunks.update(newCredentials));
        setDotsMenuIsOpen(null);
    };

    const handleDownload = (attachmentUrl: string) => {
        if (attachmentUrl) {
            window.open(attachmentUrl, '_blank')
        }
    };


    return (
        <>
            <Table
                tabs={['HR Documents', 'Certifications', 'Trainings', 'Background Documents', 'Medical Documents']}
                activeTab={'Certifications'}
                fetchData={fetchData}
                setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
                pagy={pagy}
                recordsName={'Certifications'}
                columns={true}
                blueButton={<BlueButton onClick={handleOpenNewUserCredentialDrawer} label={''} icon={<PlusIcon/>}/>}
                columnOptions={columnOptions}
                onColumnToggle={handleColumnToggle}
                searchByOptions={searchByOptions}
            >
                <thead>
                <tr>
                    <th scope="col" className="px-3  pt-3.5  flex justify-center">
                        <input type="checkbox"
                               className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"/>
                    </th>
                    {visibleColumns.credential && <TableHeader label="Certification" sortBy="name"/>}
                    {visibleColumns.licensingAuthority &&
                        <TableHeader label="Licensing Authority" sortBy="licensingAuthority"/>}
                    {visibleColumns.status && <TableHeader label="Status" sortBy="status"/>}
                    {visibleColumns.expDate && <TableHeader label="Exp Date" sortBy="expDate"/>}
                    {visibleColumns.reminder && <TableHeader label="Reminder" sortBy="reminder"/>}
                    {visibleColumns.notes && <TableHeader label="Notes"/>}
                    <TableHeader label=""/>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                {credentials.map((cred: UserCredential) => {
                        const userOptions = [
                            {
                                label: 'Edit',
                                onClick: () => handleOpenDrawer(cred.id.toString()),
                                hoverClass: "hover:bg-cyan-100 "
                            },
                            ...(cred.attachmentUrl !== null
                                ? [
                                    {
                                        label: 'Download',
                                        onClick: () => handleDownload(cred.attachmentUrl || ''),
                                        hoverClass: "hover:bg-cyan-100",
                                    },
                                    {
                                        label: 'Remove attached document',
                                        onClick: () => handleDelete(cred.id),
                                        hoverClass: "hover:bg-red-100 hover:text-red-800"
                                    },
                                ]
                                : []),

                        ];
                        return (

                            <TableRow key={cred.id}>
                                <td scope="col" className="pt-3.5 flex justify-center">
                                    <input
                                        type="checkbox"
                                        className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                        onClick={(e) => e.stopPropagation()}/>
                                </td>
                                {visibleColumns.credential && (
                                    <TableCell>
                                        <div className="relative group">
                                            <div className="w-full flex items-center gap-x-3">
                                                <div className="flex-grow">
                                                 <span
                                                     className={`text-sm  font-medium dark:text-neutral-200 ${
                                                         cred.attachmentUrl ? 'underline text-cyan-600 cursor-pointer' : ''}`}
                                                     onClick={cred.attachmentUrl? () => handleNameClick(cred) : undefined}
                                                 >
                                            {cred.name}

                                        </span>
                                                </div>
                                            </div>
                                        </div>
                                    </TableCell>
                                )}
                                {visibleColumns.licensingAuthority && <TableCell>{cred.licensingAuthority}</TableCell>}
                                {visibleColumns.status &&
                                    <TableCell>
                                <span
                                    className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-28
                                    ${cred.status === 'Active' ? 'bg-green-100 text-green-800' : ''}
                                    ${cred.status === 'Expired' ? 'bg-red-100 text-red-800' : ''}
                                    ${cred.status === 'Expiring soon' ? 'bg-yellow-100 text-yellow-800' : ''}
                                    `}
                                >
                                    {cred.status === 'Active' && <CheckCircleIcon className="mr-2"/>}
                                    {cred.status === 'Expired' && <XCircleIcon className="mr-2"/>}
                                    {cred.status === 'Expiring soon' && <CircleAlertIconIcon className="mr-2"/>}

                                    <div className="pl-1">
                                        {cred.status}
                                    </div>
                                </span>
                                    </TableCell>
                                }
                                {visibleColumns.expDate && <TableCell>{cred.expirationDate}</TableCell>}
                                {visibleColumns.reminder && <TableCell>{cred.remindUserAt || 'None'}</TableCell>}
                                {visibleColumns.notes &&
                                    <TableCell className="whitespace-nowrap overflow-hidden text-ellipsis">
                                        <div style={{maxWidth: '150px'}}
                                             className="whitespace-nowrap overflow-hidden text-ellipsis">
                                            {cred.notes}
                                        </div>
                                    </TableCell>
                                }
                                <TableCell>
                                    <DotsMenu options={userOptions}/>
                                </TableCell>
                            </TableRow>
                        )
                    }
                )}
                </tbody>
            </Table>
            <AttachmentModal/>
        </>
    );
};

export default UserCredentialTable;
