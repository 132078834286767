import FileTextIcon from "../assets/images/icons/FileTextIcon";
import TrashIcon from "../assets/images/icons/TrashIcon";
import React, {useState, useEffect} from "react";
import PdfIcon from "../assets/images/icons/PdfIcon";
import WordIcon from "../assets/images/icons/WordIcon";
import XlsxIcon from "../assets/images/icons/XlsxIcon";
import JpgIcon from "../assets/images/icons/JpgIcon";
import PngIcon from "../assets/images/icons/PngIcon";
import PptIcon from "../assets/images/icons/PptIcon";
import TxtIcon from "../assets/images/icons/TxtIcon";

interface FileDetailBoxProps {
    uploadedFile: File | null;
    fileOwner: any;
    isLoading?: boolean;
    handleDelete: () => void;
    loadingTime?: number;
}

const FileDetailBox: React.FC<FileDetailBoxProps> = ({
                                                         uploadedFile,
                                                         fileOwner,
                                                         handleDelete,
                                                         isLoading = false,
                                                         loadingTime = 5000, // Tiempo de carga predeterminado: 5 segundos
                                                     }) => {
    const [uploadProgress, setUploadProgress] = useState(0);


    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (isLoading) {
            const increment = 100 / (loadingTime / 100);
            interval = setInterval(() => {
                setUploadProgress((prevProgress) => {
                    const newProgress = prevProgress + increment;
                    if (newProgress >= 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return newProgress;
                });
            }, 100);
        } else {
            setUploadProgress(100);
            setTimeout(() => {
                setUploadProgress(0);
            }, 1000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [isLoading, loadingTime]);

    const formatFileSize = (sizeInBytes: number) => {
        if (sizeInBytes === 0) return '0 bytes';
        const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
        const size = sizeInBytes / Math.pow(1024, i);
        return `${size.toFixed(2)} ${units[i]}`;
    };

    const getFileExtension = (fileName: string) => {
        return fileName.split('.').pop()?.toLowerCase() || '';
    };
    const fileName = uploadedFile?.name || fileOwner?.attachmentFilename || 'Unknown file';
    const fileExtension = getFileExtension(fileName);

    return (
        <div
            className="w-full h-auto p-5 bg-white rounded-md border border-slate-200 flex-col justify-start items-start inline-flex">
            <div className="self-stretch flex-col justify-start items-start gap-2.5 flex">
                <div className="self-stretch justify-start items-center gap-2 inline-flex">
                    <div
                        className="w-[48px] h-[48px] bg-white rounded-lg border border-slate-200 flex-col justify-center items-center inline-flex">
                        <div className="w-6 h-6 mt-1 ml-1 text-slate-400">
                            {(() => {
                                switch(fileExtension) {
                                    case 'pdf':
                                        return <PdfIcon/>;
                                    case 'doc':
                                    case 'docx':
                                        return <WordIcon/>;
                                    case 'ppt':
                                    case 'pptx':
                                        return <PptIcon/>;
                                    case 'xls':
                                    case 'xlsx':
                                        return <XlsxIcon/>;
                                    case 'txt':
                                        return <TxtIcon/>;
                                    case 'jpg':
                                        return <JpgIcon/>;
                                    case 'png':
                                        return <PngIcon/>;

                                    default:
                                        return <FileTextIcon/>;
                                }
                            })()}
                        </div>
                    </div>
                    <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                        <div
                            className="self-stretch max-w-48 text-slate-800 text-base font-semibold font-['Inter'] leading-normal tracking-tight truncate">
                            {uploadedFile?.name || fileOwner?.name}
                        </div>
                        <div
                            className="self-stretch text-slate-400 text-xs font-medium font-['Inter'] leading-none tracking-tight">
                            {(uploadedFile && uploadedFile.size !== undefined)
                                ? formatFileSize(uploadedFile.size)
                                : formatFileSize(fileOwner.attachmentSize || fileOwner.size)}
                        </div>
                    </div>
                    <div className="w-4 h-4 relative text-slate-400">
                        <button type="button" onClick={handleDelete}>
                            <TrashIcon/>
                        </button>
                    </div>
                </div>
                {isLoading && (
                    <div className="w-full flex items-center mt-2">
                        <div className="flex-1 h-1 bg-slate-200 rounded-lg relative">
                            <div
                                className="h-full bg-cyan-400 rounded-lg transition-all duration-100 ease-linear"
                                style={{width: `${uploadProgress}%`}}
                            ></div>
                        </div>
                        <div className="ml-2">
                            <p className="text-sm text-slate-600">
                                {isLoading ? `${Math.floor(uploadProgress)}%` : '100%'}
                            </p>
                        </div>
                    </div>
                )}


            </div>
        </div>
    );
};

export default FileDetailBox;
