import React, { createContext, useState } from 'react';

interface TitleContextProps {
    title: React.ReactNode;
    setTitle: (title: React.ReactNode) => void;
}

export const TitleContext = createContext<TitleContextProps>({
    title: null,
    setTitle: () => {},
});

export const TitleProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [title, setTitle] = useState<React.ReactNode>(null);
    const [subtitle, setSubtitle] = useState<React.ReactNode>(null);

    return (
        <TitleContext.Provider value={{ title, setTitle }}>
            {children}
        </TitleContext.Provider>
    );
};
