    import React, {useContext, useEffect, useState} from "react";
    import Drawer from "components/Drawer";
    import CredentialTable from "features/credential/CredentialTable";
    import CredentialForm from "features/credential/CredentialForm";
    import {useSelector} from "react-redux";
    import {RootState} from "store/store";
    import {TitleContext} from "components/TitleContext";

    const CertificationPage: React.FC = () => {
        const [isDrawerOpen, setIsDrawerOpen] = useState(false);
        const loading = useSelector((state: RootState) => state.credential.entityStatus === 'loading');
        const openDrawer = () => setIsDrawerOpen(true);
        const closeDrawer = () => setIsDrawerOpen(false);
        const { setTitle } = useContext(TitleContext);

        useEffect(() => {
            setTitle(
                <div>
                    <div className="text-slate-600 text-lg font-medium font-['Inter']  ">
                        Certifications
                    </div>
                    <div
                        className="text-slate-400 text-sm font-light font-['Inter'] leading-normal ">
                        Manage your company’s certifications
                    </div>
                </div>
            );
        }, [setTitle]);

        return (
            <div className="">
                <div className="relative overflow-hidden">
                    <div className="relative">
                        <CredentialTable openDrawer={openDrawer}/>
                        <Drawer isOpen={isDrawerOpen} onClose={closeDrawer} loading={loading}>
                            <CredentialForm closeDrawer={closeDrawer}/>
                        </Drawer>
                    </div>
                </div>
            </div>
        );
    };

    export default CertificationPage;
