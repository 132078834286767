export interface MedicalDocumentInterface {
    id: string;
    name: string;
    agency: string;
    expirationDate: string;
    remindUser: string;
    notes: string;
    status?: string;
    description: string;
}

export class MedicalDocument implements MedicalDocumentInterface {
    id: string;
    name: string;
    agency: string;
    expirationDate: string;
    remindUser: string;
    notes: string;
    status?: any;
    description: string;

    constructor(data: Partial<MedicalDocumentInterface>) {
        this.id = data.id!;
        this.name = data.name!;
        this.agency = data.agency!;
        this.expirationDate = data.expirationDate!;
        this.remindUser = data.remindUser!;
        this.notes = data.notes!;
        this.status = data.status || 'active';
        this.description = data.description!;
    }

    toJson(): MedicalDocumentInterface {
        return {
            id: this.id,
            name: this.name,
            agency: this.agency,
            expirationDate: this.expirationDate,
            remindUser: this.remindUser,
            notes: this.notes,
            status: this.status.toLowerCase(),
            description: this.description,
        };
    }
}
