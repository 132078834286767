import React, {useContext, useEffect, useState} from "react";
import Drawer from "components/Drawer";
import MedicalDocumentTable from "features/medicalDocument/MedicalDocumentTable";
import MedicalDocumentForm from "features/medicalDocument/MedicalDocumentForm";
import {TitleContext} from "../../components/TitleContext";

const MedicalDocumentsPage: React.FC = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);
    const { setTitle } = useContext(TitleContext);

    useEffect(() => {
        setTitle(
            <div>
                <div className="text-slate-600 text-lg font-medium font-['Inter']  ">
                    Medical Documents
                </div>
                <div
                    className="text-slate-400 text-sm font-light font-['Inter'] leading-normal ">
                    Manage your company’s medical documents
                </div>
            </div>
        );
    }, [setTitle]);
    return (
        <div className="">
            <div className="relative overflow-hidden">

                <div className="relative">
                    <MedicalDocumentTable openDrawer={openDrawer}/>
                    <Drawer isOpen={isDrawerOpen} onClose={closeDrawer}>
                        <MedicalDocumentForm closeDrawer={closeDrawer}/>
                    </Drawer>
                </div>
            </div>
        </div>
    );
};

export default MedicalDocumentsPage;
