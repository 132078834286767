import React from 'react';


const ChevronDownIcon = () => (
    <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="chevron-down">
            <path id="Vector" d="M3.5 5.25L7 8.75L10.5 5.25" stroke="currentColor" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </g>
    </svg>

);
export default ChevronDownIcon;
