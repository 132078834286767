import React from "react";
import SecondStepVerification from "features/auth/components/SecondStepVerification";
import SubmitButton from "components/SubmitButton";
import ArrowRightIcon from "assets/images/icons/ArrowRightIcon";
import useSecondStepVerification from "hooks/useSecondStepVerification";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import {showToast} from "../../components/ToastContainer";
import {useParams} from "react-router-dom";
import {AuthThunks} from "../../features/auth/authThunks";

const ConfirmEmailPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { token } = useParams<{ token: string }>();
    const { pin, firstInputRef, error, handlePinChange, handleKeyDown, handlePaste, handleSubmit, isFormValid, status } = useSecondStepVerification();
    const userEmail = useSelector((state: RootState) =>
        state.auth.userCredentials?.email ?? ""
    );
    const userPassword = useSelector((state: RootState) =>
        state.auth.userCredentials?.password ?? ""
    );
    const handleResend = async () => {
        if (!userEmail) {
            showToast('User email not available', 'error');
            return;
        }

        try {
            const email = userEmail.trim();
            const password = userPassword.trim();
            await dispatch(AuthThunks.login({ email, password })).then(
                (resultAction) => {
                    if (AuthThunks.login.fulfilled.match(resultAction)) {
                        showToast('Confirmation email resent', 'success');
                    }
                }
            )
        } catch (error) {
            showToast('Failed to resend confirmation email', 'error');
        }
    };



    return (
        <div className="flex flex-col justify-between">
            <div className="flex-grow flex flex-col md:pr-20 md:pl-20 pt-20 max-w-120 md:min-w-96 sm:min-w-64">
                <div className="w-80">
                    <div className="w-full text-xl font-semibold tracking-normal text-slate-800">
                        Confirm your company email
                    </div>
                    <div className="text-sm w-80 font-light tracking-normal leading-5 text-zinc-400">
                        <span className="leading-tight">Logging in to the New Hope app requires you have access to your company email.</span>
                    </div>
                    <div className="mt-2 text-sm font-light tracking-normal w-80 leading-5 text-zinc-400">
                        <span className="leading-tight">We’ve sent you a 6 digit confirmation code to </span>
                        <span className="text-cyan-400 leading-tight">{userEmail}</span>
                    </div>
                </div>

                <SecondStepVerification
                    pin={pin}
                    firstInputRef={firstInputRef}
                    error={error}
                    handlePinChange={handlePinChange}
                    handleKeyDown={handleKeyDown}
                    handlePaste={handlePaste}
                />

                <div className="flex justify-between mt-4">
                    <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                        <span className="leading-tight">Didn’t get the email? </span>
                        <span className="self-center mt-6 mb-6 text-sm font-light tracking-normal leading-5 text-center underline text-zinc-400 hover:text-cyan-400 cursor-pointer"
                              onClick={handleResend}>
                            Resend
                        </span>
                    </div>
                </div>
            </div>
                <footer className="grid grid-rows-2 justify-center w-full mt-8">
                    <SubmitButton
                        className={"w-80 py-2.5 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-cyan-400 text-white hover:bg-cyan-500 disabled:pointer-events-none disabled:bg-slate-100  disabled:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"}
                        onClick={handleSubmit}
                        label={<>{'Continue'} <ArrowRightIcon/></>}
                        disabled={status === 'loading' || !isFormValid}
                    />
                    <div className="flex justify-center mt-4 gap-x-3">
                        <div className="w-2 h-2 bg-slate-200 focus:bg-cyan-400 rounded-full"></div>
                        <div className="w-2 h-2 bg-slate-200 focus:bg-cyan-400 rounded-full"></div>
                        <div className="w-2 h-2 bg-slate-200 focus:bg-cyan-400 rounded-full"></div>
                        <div className="w-2 h-2 bg-slate-200 focus:bg-cyan-400 rounded-full"></div>
                        <div className="w-2 h-2 bg-cyan-400 focus:bg-cyan-400 rounded-full"></div>
                    </div>
                </footer>
        </div>
    );
};

export default ConfirmEmailPage;
