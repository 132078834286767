import { LocationService } from 'services/locationService';
import { createEntityThunks } from 'utils/createEntityThunks';
import { Location } from 'types/location';

const locationFactory = (data: any) => new Location(data);

export const LocationThunks = createEntityThunks<Location>(
    'location',
    LocationService,
    { activate: true, deactivate: true },
    locationFactory
)
