import React, { useState} from "react";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import TableCell from "components/table/TableCell";
import {BackgroundDocument} from "types/backgroundDocument";
import Table from "components/table/Table";
import {SearchType} from "types/search";
import {BackgroundDocumentThunks} from "./backgroundDocumentThunks";
import {setIsEditing, setBackgroundDocument,setActiveTab} from "features/backgroundDocument/backgroundDocumentSlice";
import BlueButton from "components/BlueButton";
import PlusIcon from "assets/images/icons/PlusIcon";
import CheckCircleIcon from "../../assets/images/icons/CheckCircleIcon";
import XCircleIcon from "../../assets/images/icons/XCircleIcon";
import {useDropdownActionMenu} from "hooks/useDropdownActionMenu";
import DotsMenu from "components/DotsMenu";
import TrashIcon from "../../assets/images/icons/TrashIcon";


const BackgroundDocumentTable: React.FC<{ openDrawer: () => void }> = ({openDrawer}) => {
    const backgroundDocuments = useSelector((state: RootState) => state.backgroundDocument.backgroundDocuments);
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.backgroundDocument.pagy);
    const activeTab = useSelector((state: RootState) => state.backgroundDocument.activeTab);
    const {setDotsMenuIsOpen} = useDropdownActionMenu();
    const loading = useSelector((state: RootState) => state.training.status === 'loading');

    const [visibleColumns, setVisibleColumns] = useState({
        name: true,
        description: true,
        status: true,

    });

    interface VisibleColumnsType {
        name: boolean,
        description: boolean,
        status: boolean,
    }

    const fetchData = async ({page, search, sortField, sortDirection, rowsPerPage}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        if (activeTab === 'Active' || activeTab === 'Inactive') {
            search.where.status = {
                operator: '==',
                value: activeTab.toLowerCase()
            };
        } else {
            delete search.where.status;
        }
        await dispatch(BackgroundDocumentThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
    };

    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };

    const handleOpenDrawer = (id: string) => async () => {
        dispatch(setIsEditing(false));
        openDrawer();
        await dispatch(BackgroundDocumentThunks.show(id));
    };

    const handleOpenNewBackgroundDocumentDrawer = () => {
        dispatch(setBackgroundDocument({
            name: '',
            description: '',
            status: '',

        }));
        dispatch(setIsEditing(true));
        openDrawer();
    };


    const searchByOptions = [
        {label: 'Name', value: 'name'},
    ];

    const columnOptions = [

        {label: 'Name', value: 'name',isVisible: visibleColumns.name},
        {label: 'Status', value: 'status',isVisible: visibleColumns.status},
        {label: 'Description', value: 'description',isVisible: visibleColumns.description},
    ];

    const handleDelete = async (id: string) => {
        await dispatch(BackgroundDocumentThunks.delete(id));
        setDotsMenuIsOpen(null);
    };
    const handleDeactivate = (backgroundDocumentId: string) => {
        const backgroundDocument = new BackgroundDocument({id: backgroundDocumentId, status: 'inactive'})
        dispatch(BackgroundDocumentThunks.deactivate(backgroundDocumentId));
        setDotsMenuIsOpen(null);
    };

    const handleReactivate = (backgroundDocumentId: string) => {
        const backgroundDocument = new BackgroundDocument({id: backgroundDocumentId, status: 'active'})
        dispatch(BackgroundDocumentThunks.activate(backgroundDocumentId));
        setDotsMenuIsOpen(null);
    };

    return (
        <Table
            fetchData={fetchData}
            pagy={pagy}
            pageable={true}
            recordsName={'Documents'}
            searchable={true}
            activeTab={activeTab}
            setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
            tabs={['All', 'Active', 'Inactive']}
            columns={true}
            columnOptions={columnOptions}
            onColumnToggle={handleColumnToggle}
            searchByOptions={searchByOptions}
            loading={loading}
            blueButton={<BlueButton onClick={handleOpenNewBackgroundDocumentDrawer} label="" icon={<PlusIcon/>}/>}
        >
            <thead>
            <tr>
                <th scope="col" className="px-3  pt-3.5  flex justify-center">
                    <input type="checkbox"
                           className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"/>
                </th>
                {visibleColumns.name && <TableHeader label="Name" sortBy="name"/>}
                {visibleColumns.status && <TableHeader label="Status" sortBy="status"/>}
                {visibleColumns.description && <TableHeader label="Description"/>}

            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
            {backgroundDocuments.map((backgroundDocument: BackgroundDocument) => {
                const userOptions = [];
                userOptions.push({
                    label: "Edit",
                    onClick: handleOpenDrawer(backgroundDocument.id),
                    hoverClass: "hover:bg-cyan-100",
                });
                if (backgroundDocument.status === "Active") {
                    userOptions.push({
                        label: "Deactivate",
                        onClick: () => handleDeactivate(backgroundDocument.id),
                        hoverClass: "hover:bg-red-100 hover:text-red-800",
                    });
                }
                if (backgroundDocument.status === "Inactive") {
                    userOptions.push({
                        label: "Activate",
                        onClick: () => handleReactivate(backgroundDocument.id),
                        hoverClass: "hover:bg-cyan-100",
                    });

                }
                userOptions.push({
                    label: "Delete",
                    onClick: () => handleDelete(backgroundDocument.id),
                    icon: <TrashIcon/>,
                    hoverClass: "hover:bg-red-100 hover:text-red-800",
                });
            return (
                <TableRow
                    key={backgroundDocument.id}
                >
                    <td scope="col" className="pt-3.5 flex justify-center">
                        <input
                            type="checkbox"
                            className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                            onClick={(e) => e.stopPropagation()}/>
                    </td>
                    {visibleColumns.name &&
                        <TableCell>
                            <div className=" relative group">
                                <div className="w-full flex items-center gap-x-3">
                                    <div className="flex-grow">
                                        <span
                                            className="text-sm  cursor-pointer font-medium dark:text-neutral-200 underline text-cyan-600"
                                            onClick={handleOpenDrawer(backgroundDocument.id)}
                                        >
                                            {backgroundDocument.name}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </TableCell>}
                    {visibleColumns.status && (
                        <TableCell>
                            <span
                                className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-28
                                    ${backgroundDocument.status === 'Active' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-700'}`}
                            >
                                {backgroundDocument.status === 'Active' ? <CheckCircleIcon className="mr-2"/> :
                                    <XCircleIcon className="mr-2"/>}

                                <div className="pl-1">
                                    {backgroundDocument.status === 'Active' ? backgroundDocument.status : 'Inactive'}
                                </div>
                            </span>
                        </TableCell>)}
                    {visibleColumns.description && (
                        <TableCell className="whitespace-nowrap overflow-hidden text-ellipsis">
                            <div style={{maxWidth: '150px'}}
                                 className="whitespace-nowrap overflow-hidden text-ellipsis">
                                {backgroundDocument.description}
                            </div>
                        </TableCell>
                    )}
                    <TableCell>
                        <DotsMenu options={userOptions}/>
                    </TableCell>
                </TableRow>
            )
            })}
            </tbody>
        </Table>
    );
};

export default BackgroundDocumentTable;
