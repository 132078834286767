import React from "react";

interface ChevronRightProps {
    className?: string
}

const ChevronRightIcon = ({className}: ChevronRightProps) => (
    <svg className="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24"
         height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
         stroke-linecap="round" stroke-linejoin="round">
        <path d="m9 18 6-6-6-6"/>
    </svg>
);
export default ChevronRightIcon;
