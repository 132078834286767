import { createEntityService } from 'utils/createEntityService';
import { DocumentTemplate } from 'types/documentTemplate';
import apiClient from "./apiClient";

export const BaseDocumentTemplateService = createEntityService<DocumentTemplate>({
    baseUrl: 'api/v1/document_templates',
    entityName: 'documentTemplate',
});

export class DocumentTemplateService extends BaseDocumentTemplateService {
    static async duplicate(id: string) {
        const response = await apiClient.patch(`api/v1/document_templates/${id}/duplicate`);
        return response.data;
    }
}
