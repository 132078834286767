import {createAsyncThunk} from "@reduxjs/toolkit";
import {PermissionSchemeGroupService} from "services/permissionSchemeGroupService";

export class PermissionSchemeGroupThunks {
  static index = createAsyncThunk(
    'permissionSchemeGroup/index',
    async () => {
      const response = await PermissionSchemeGroupService.index();
      return response.data;
    }
  );
}
