import { createEntityService } from 'utils/createEntityService';
import { UserMedicalDocument } from 'types/userMedicalDocument';

export const UserMedicalDocumentService = createEntityService<UserMedicalDocument>({
    baseUrl: 'api/v1/user_medical_documents',
    entityName: 'userMedicalDocument',
    methodConfigs: {
        create: {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
        update: {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    }
});
