import { UserBackgroundDocumentService } from 'services/userBackgroundDocumentService';
import { createEntityThunks } from 'utils/createEntityThunks';
import { UserBackgroundDocument } from 'types/userBackgroundDocument';

const userBackgroundDocumentFactory = (data: any) => new UserBackgroundDocument(data);

export const UserBackgroundDocumentThunks = createEntityThunks<UserBackgroundDocument>(
    'userBackgroundDocument',
    UserBackgroundDocumentService,
    { activate: false, deactivate: false },
    userBackgroundDocumentFactory
)
