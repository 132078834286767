import { createEntitySlice } from 'utils/createEntitySlice';
import { HrDocumentThunks } from './hrDocumentThunks';
import { HrDocument } from 'types/hrDocument';

export interface HrDocumentState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    hrDocuments: HrDocument[];
    hrDocument: HrDocument | null;
    isEditing: boolean;
    activeTab: string;
    pagy: any;
}

const initialState: Partial<HrDocumentState> = {
    hrDocuments: [],
    hrDocument: null,
    isEditing: false,
    activeTab: 'All',
    pagy: {},
};

const hrDocumentSlice = createEntitySlice<HrDocument>({
    name: 'Document',
    initialState,
    thunks: HrDocumentThunks,
    entityKey: 'hrDocument',
    entitiesKey: 'hrDocuments',
    reducers: {
    },
});

export const { setEntity: setHrDocument, setIsEditing, setActiveTab } = hrDocumentSlice.actions;
export default hrDocumentSlice.reducer;
