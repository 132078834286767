import { createEntitySlice } from 'utils/createEntitySlice';
import { MedicalDocumentThunks } from './medicalDocumentThunks';
import { MedicalDocument } from 'types/medicalDocument';

export interface MedicalDocumentState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    medicalDocuments: MedicalDocument[];
    medicalDocument: MedicalDocument | null;
    isEditing: boolean;
    activeTab: string;
    pagy: any;
}

const initialState: Partial<MedicalDocumentState> = {
    medicalDocuments: [],
    medicalDocument: null,
    isEditing: false,
    activeTab: 'All',
    pagy: {},
};

const medicalDocumentSlice = createEntitySlice<MedicalDocument>({
    name: 'Document',
    initialState,
    thunks: MedicalDocumentThunks,
    entityKey: 'medicalDocument',
    entitiesKey: 'medicalDocuments',
    reducers: {
    },
});

export const { setEntity: setMedicalDocument, setIsEditing, setActiveTab } = medicalDocumentSlice.actions;
export default medicalDocumentSlice.reducer;
