import React from 'react';
import Wysiwyg from 'components/Wysiwyg';
import {ElementProps, TextBlockElement} from "types/documentTemplateElement";

interface TextBlockElementProps extends ElementProps<TextBlockElement> {}

const TextBlockElementComponent: React.FC<TextBlockElementProps> = ({ element, updateElement }) => {
    const handleContentChange = (content: string) => {
        updateElement({ ...element, content });
    };

    return (
        <div className="w-full m-8">
            <Wysiwyg content={element.content} onContentChange={handleContentChange} />
        </div>
    );
};

export default TextBlockElementComponent;
