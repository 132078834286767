import React, {useState} from "react";
import SidebarContent from "features/sidebar/SidebarContent";
import CompressedSidebarContent from "features/sidebar/CompressedSidebarContent";
interface SidebarProps {
    setIsNewDrawerOpen: (isOpen: boolean) => void;
}
const Sidebar: React.FC<SidebarProps> = ({ setIsNewDrawerOpen }) => {

  return (
    <div className="z-10 flex-col justify-center items-start gap-[148px] inline-flex flex-grow">
      <div className="top-3 z-10"></div>
      <SidebarContent setIsNewDrawerOpen={setIsNewDrawerOpen} />
      <CompressedSidebarContent setIsNewDrawerOpen={setIsNewDrawerOpen} />
    </div>
  );
};

export default Sidebar;
