import React from "react";
import {useDropdownActionMenu} from "hooks/useDropdownActionMenu";
import DotsMenuIcon from "assets/images/icons/DotsMenuIcon";

interface DotsMenuProps {
  options: {
    label: string;
    icon?: React.ReactNode;
    onClick: () => void;
    hoverClass?: string;
  }[];
}

const DotsMenu: React.FC<DotsMenuProps> = ({options}) => {
  const {
    dotsMenuIsOpen,
    menuPosition,
    closeDropdown,
    toggleDropdown,
    dotsMenuRef,
    setDotsMenuIsOpen,
  } = useDropdownActionMenu();

  return (
    <div className="relative" ref={dotsMenuRef}>
      <button
        type="button"
        onClick={e => {
          e.stopPropagation();
          toggleDropdown("menu", e);
        }}
        className="size-[28px] inline-flex justify-center items-center gap-x-2 rounded-md border border-stone-200
                                               bg-white text-stone-800 hover:bg-stone-50 disabled:opacity-50 disabled:pointer-events-none
                                               focus:outline-none focus:bg-stone-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
        <DotsMenuIcon />
      </button>

      {dotsMenuIsOpen === "menu" && (
        <div
          className="fixed mt-1  bg-white rounded-l z-10"
          style={{
            top: `${menuPosition.top}px`,
            left: `${menuPosition.left - 115}px`,
          }}>
          <dl
            className="top-full  shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] p-1 w-[234px] absolute hs-dropdown-menu hs-dropdown-open:opacity-100 transition-[opacity,margin] duration opacity-100 z-10 bg-white rounded-xl
                                  dark:bg-neutral-900 block">
            {options.map((option, index) => (
              <button
                key={index}
                type="button"
                id={option.label}
                style={{cursor: "pointer"}}
                className={`w-full flex  items-center gap-x-3 py-1.5 px-3 rounded-lg text-[13px] text-gray-800 disabled:opacity-50
                            disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-gray-100 
                            dark:hover:bg-neutral-800 dark:focus:bg-neutral-800 ${option.hoverClass || "hover:bg-gray-100 hover:text-gray-800"} dark:text-neutral-300`}
                onClick={e => {
                  e.stopPropagation();
                  option.onClick();
                  closeDropdown();
                }}>
                <span>{option.label}</span>
                <div className="flex ml-auto ">{option.icon}</div>
              </button>
            ))}
          </dl>
        </div>
      )}
    </div>
  );
};

export default DotsMenu;
