import React from 'react';
import {ElementProps, MultiLineTextInputElement} from "types/documentTemplateElement";
import DescriptionInput from "../inputs/DescriptionInput";

interface MultiLineTextInputElementProps extends ElementProps<MultiLineTextInputElement> {}

const MultiLineTextInputElementComponent: React.FC<MultiLineTextInputElementProps> = ({ element, updateElement }) => {
    return (
        <div className="w-full m-8">
            <DescriptionInput
                id={element.id}
                type="text"
                label={element.showLabel ? element.label : ""}
                value=""
                placeholder={element.placeholder}
                required={element.required}
                onChange={() => {}}
            />
        </div>
    );
};

export default MultiLineTextInputElementComponent;
