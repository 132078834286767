import React, {useContext, useEffect, useState} from "react";
import UserTable from "features/user/UserTable";
import {TitleContext} from "../../components/TitleContext";
import {SearchType} from "../../types/search";
import {UserThunks} from "../../features/user/userThunks";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import ExpiringHrDocumentsTable from "../../features/dashboard/ExpiringHrDocumentsTable";
import UserEditForm from "../../features/user/UserEditForm";
import Drawer from "../../components/Drawer";
import UserHrDocumentForm from "../../features/userHrDocument/UserHrDocumentForm";

const DashboardPage: React.FC = () => {
    const {setTitle} = useContext(TitleContext);
    const dispatch = useDispatch<AppDispatch>();
    const activeTab = useSelector((state: RootState) => state.user.activeTab);
    const [isEditUserOpenDrawer, setIsEditUserOpenDrawer] = useState(false);
    const [isEditDocumentOpenDrawer, setIsEditDocumentOpenDrawer] = useState(false);
    const closeEditUserDrawer = () => setIsEditUserOpenDrawer(false);
    const closeEditDocumentDrawer = () => setIsEditDocumentOpenDrawer(false);
    const openDrawer = () => setIsEditUserOpenDrawer(true);
    const openDocumentDrawer = () => setIsEditDocumentOpenDrawer(true);


    const customFetchUserData = async ({page, search, sortField, sortDirection, rowsPerPage,}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string | string [] } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        if (activeTab === 'All') {
            search.where.status = {
                operator: 'in',
                valueArray: ['onboarding', 'non_compliant', 'inactive']
            };
        } else if (activeTab === 'Onboarding') {
            search.where.status = {
                operator: '==',
                value: 'onboarding'
            }
        } else if (activeTab === 'Non compliant') {
            search.where.status = {
                operator: '==',
                value: 'non_compliant'
            }

        } else if (activeTab === 'Inactive') {
            search.where.status = {
                operator: '==',
                value: 'inactive'
            }
        } else {
            delete search.where.status;
        }

        await dispatch(UserThunks.index({page, search, sortField, sortDirection, rowsPerPage,}));
    };

    useEffect(() => {
        setTitle(
            <div>
                <div className="text-slate-600 text-lg font-medium font-['Inter']  ">
                    Dashboard
                </div>
                <div
                    className="text-slate-400 text-sm font-light font-['Inter'] leading-normal ">
                    Quick access to most active features
                </div>
            </div>
        );
    }, [setTitle]);

    return (
        <div className="">
            <div className="relative overflow-hidden">
                <div className="">
                    <div className="relative overflow-hidden">

                        <UserTable showBlueButton={false}
                                   tabs={['All', 'Onboarding', 'Non compliant', 'Inactive']}
                                   customFetchUserData={customFetchUserData}
                                   openDrawer={openDrawer}
                        />
                        <Drawer isOpen={isEditUserOpenDrawer} onClose={closeEditUserDrawer}>
                            <UserEditForm onClose={closeEditUserDrawer}/>
                        </Drawer>

                        <div className="mt-8">
                         <ExpiringHrDocumentsTable openDrawer={openDocumentDrawer} />
                            <Drawer isOpen={isEditDocumentOpenDrawer} onClose={closeEditDocumentDrawer} >
                                <UserHrDocumentForm closeDrawer={closeEditDocumentDrawer} isDrawerOpen={isEditDocumentOpenDrawer} />
                            </Drawer>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default DashboardPage;
