export interface ProgramInterface {
    id: string;
    name: string;
    abbreviationName?: string;
    description: string;
    status: string;
    showUnapproved?: boolean;
    allowAlternateReports?: boolean;
    noteMinuteIncrement?: number;
    blockOverlappingNotes?: boolean;
    includeObjectivesOnNotes?: boolean;
    requireCurrentAuthorizations?: boolean;
    enableEventReminders?: boolean;
    locationIds?: string[];
}

export class Program implements ProgramInterface {
    id: string;
    name: string;
    abbreviationName?: string;
    description: string;
    status: string;
    showUnapproved?: boolean;
    allowAlternateReports?: boolean;
    noteMinuteIncrement?: number;
    blockOverlappingNotes?: boolean;
    includeObjectivesOnNotes?: boolean;
    requireCurrentAuthorizations?: boolean;
    enableEventReminders?: boolean;
    locationIds?: string[];

    constructor(data: Partial<ProgramInterface>) {
        this.id = data.id!;
        this.name = data.name!;
        this.abbreviationName = data.abbreviationName;
        this.description = data.description!;
        this.status = data.status!;
        this.showUnapproved = data.showUnapproved;
        this.allowAlternateReports = data.allowAlternateReports;
        this.noteMinuteIncrement = data.noteMinuteIncrement;
        this.blockOverlappingNotes = data.blockOverlappingNotes;
        this.includeObjectivesOnNotes = data.includeObjectivesOnNotes;
        this.requireCurrentAuthorizations = data.requireCurrentAuthorizations;
        this.enableEventReminders = data.enableEventReminders;
        this.locationIds = data.locationIds;
    }

    toJson(): ProgramInterface {
        return {
            id: this.id,
            name: this.name,
            abbreviationName: this.abbreviationName,
            description: this.description,
            status: this.status.toLowerCase(),
            showUnapproved: this.showUnapproved,
            allowAlternateReports: this.allowAlternateReports,
            noteMinuteIncrement: this.noteMinuteIncrement,
            blockOverlappingNotes: this.blockOverlappingNotes,
            includeObjectivesOnNotes: this.includeObjectivesOnNotes,
            requireCurrentAuthorizations: this.requireCurrentAuthorizations,
            enableEventReminders: this.enableEventReminders,
            locationIds: this.locationIds
        };
    }
}
