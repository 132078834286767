import React from "react";

const TableRow: React.FC<{ children: React.ReactNode, className?: string, onClick?: () => void }> = ({
                                                                                                         children,
                                                                                                         className = "",
                                                                                                         onClick
                                                                                                     }) => (
    <tr
        className={` ${className}`}
        onClick={onClick}
    >
        {children}
    </tr>
);

export default TableRow;
