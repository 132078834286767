import { UserHrDocumentService } from 'services/userHrDocumentService';
import { createEntityThunks } from 'utils/createEntityThunks';
import { UserHrDocument } from 'types/userHrDocument';

const userHrDocumentFactory = (data: any) => new UserHrDocument(data);

export const UserHrDocumentThunks = createEntityThunks<UserHrDocument>(
    'userHrDocument',
    UserHrDocumentService,
    { activate: false, deactivate: false },
    userHrDocumentFactory
)
