import { createEntityService } from 'utils/createEntityService';
import { UserCredential } from 'types/userCredential';

export const UserCredentialService = createEntityService<UserCredential>({
    baseUrl: 'api/v1/user_credentials',
    entityName: 'userCredential',
    methodConfigs: {
        create: {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
        update: {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    }
});
