export interface BackgroundDocumentInterface {
    id: string;
    name?: string;
    agency: string;
    submissionDate: string;
    expirationDate: string;
    notes: string;
    reminder: string;
    description: string;
    status?: string;
}

export class BackgroundDocument implements BackgroundDocumentInterface {
    id: string;
    name?: string;
    agency: string;
    submissionDate: string;
    expirationDate: string;
    notes: string;
    reminder: string;
    description: string;
    status?: any;

    constructor(data: Partial<BackgroundDocumentInterface>) {
        this.id = data.id!;
        this.name = data.name!;
        this.agency = data.agency!;
        this.submissionDate = data.submissionDate!;
        this.expirationDate = data.expirationDate!;
        this.notes = data.notes!;
        this.reminder = data.reminder!;
        this.description = data.description!;
        this.status = data.status || 'active';
    }

    toJson(): BackgroundDocumentInterface {
        return {
            id: this.id,
            name: this.name,
            agency: this.agency,
            submissionDate: this.submissionDate,
            expirationDate: this.expirationDate,
            notes: this.notes,
            reminder: this.reminder,
            description: this.description,
            status: this.status.toLowerCase(),
        };
    }
}
