import {createSlice} from '@reduxjs/toolkit';
import {showToast} from "components/ToastContainer";
import {UserInvitationThunks} from 'features/userInvitation/userInvitationThunk';
import {UserThunks} from "../user/userThunks";
import {createEntitySlice} from "../../utils/createEntitySlice";
import {UserInvitation} from "../../types/userInvitation";

export interface UserInvitationState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    invitation?: any;
    userInvitations?: any;
    pagy?: any;
    userInvitation?: any;
}

const initialState: UserInvitationState = {
    status: 'idle',
    error: null,
    invitation: null,
    userInvitations: [],
    pagy: null,
    userInvitation: null
};

const userInvitationSlice = createEntitySlice<UserInvitation>({
    name: 'invitation',
    initialState,
    thunks: UserInvitationThunks,
    reducers: {},
    entityKey: 'invitaion',
    entitiesKey: 'userInvitations',
    extraReducers: {
        [UserInvitationThunks.showByToken.pending.type]: (state, action) => {

            state.status = 'loading';
        },

        [UserInvitationThunks.showByToken.fulfilled.type]: (state, action) => {
            state.invitation = action.payload;
            state.status = 'succeeded';
        },
        [UserInvitationThunks.showByToken.rejected.type]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload as string;
        },
        [UserInvitationThunks.cancel.fulfilled.type]: (state) => {
            state.status = 'succeeded';
            showToast('Invitation cancelled', 'success');
        },
        [UserInvitationThunks.resend.fulfilled.type]: (state, action) => {
            state.status = 'succeeded';
            const index = state.userInvitations.findIndex((item: any) => item.id === action.payload.id);
            if (index !== -1) {
                state.userInvitations[index] = action.payload;
            }
            showToast('Invitation resent', 'success');
        }

    }
});

export const { setEntity: setUserInvitation, setIsEditing, setActiveTab } = userInvitationSlice.actions;

export {initialState as userInvitationInitialState};
export default userInvitationSlice.reducer;
