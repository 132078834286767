import React, {useContext, useEffect, useState} from "react";
import Drawer from "components/Drawer";
import JobRoleTable from "features/jobRole/JobRoleTable";
import JobRoleForm from "features/jobRole/JobRoleForm";
import {TitleContext} from "../../components/TitleContext";

const JobRolesPage: React.FC = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);
    const { setTitle } = useContext(TitleContext);

    useEffect(() => {
        setTitle(
            <div>
                <div className="text-slate-600 text-lg font-medium font-['Inter']  ">
                    Job Roles
                </div>
                <div
                    className="text-slate-400 text-sm font-light font-['Inter'] leading-normal ">
                    Manage your company’s roles
                </div>
            </div>
        );
    }, [setTitle]);
    return (
        <div className="">
            <div className="relative overflow-hidden">
                <div className="relative">
                    <JobRoleTable openDrawer={openDrawer}/>
                    <Drawer isOpen={isDrawerOpen} onClose={closeDrawer}>
                        <JobRoleForm closeDrawer={closeDrawer}/>
                    </Drawer>
                </div>
            </div>
        </div>
    );
};

export default JobRolesPage;
