import React, {useContext, useEffect, useState} from "react";
import {LocationThunks} from "features/location/locationThunks";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import Drawer from "components/Drawer";
import LocationTable from "features/location/LocationTable";
import LocationForm from "features/location/LocationForm";
import {TitleContext} from "../../components/TitleContext";


const LocationsPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);
    const { setTitle } = useContext(TitleContext);

    useEffect(() => {
        setTitle(
            <div>
                <div className="text-slate-600 text-lg font-medium font-['Inter']  ">
                    Locations
                </div>
                <div
                    className="text-slate-400 text-sm font-light font-['Inter'] leading-normal ">
                    Manage your company’s locations
                </div>
            </div>
        );
    }, [setTitle]);


    useEffect(() => {
        const loadLocations = async () => {
            try {
                await dispatch(LocationThunks.index({
                    page: 1,
                    search: {where: {}},
                    sortField: '',
                    sortDirection: '',
                    rowsPerPage: 10
                }));
            } catch (err) {
                console.error(err);
            }
        }
        loadLocations();
    }, [dispatch]);

    return (

        <div className="">
            <div className="relative overflow-hidden">
                <div className="relative">
                    <LocationTable openDrawer={openDrawer}/>
                    <Drawer isOpen={isDrawerOpen} onClose={closeDrawer}>
                        <LocationForm closeDrawer={closeDrawer}/>
                    </Drawer>
                </div>
            </div>
        </div>
    );
};

export default LocationsPage;
