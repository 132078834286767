import React, {useContext, useEffect} from "react";
import {TitleContext} from "../components/TitleContext";
import Wysiwyg from "../components/Wysiwyg";

const Home: React.FC = () => {
  const {setTitle} = useContext(TitleContext);
  useEffect(() => {
    setTitle(
      <div>
        <div className="text-slate-600 text-lg font-medium font-['Inter']  ">
          Home
        </div>
        <div className="text-slate-400 text-sm font-light font-['Inter'] leading-normal ">
          Welcome to the dashboard
        </div>
      </div>
    );
  }, []);
  return <div></div>;
};

export default Home;
