import React from 'react';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import {ElementProps, PhoneNumberInputElement} from "types/documentTemplateElement";

interface PhoneNumberInputElementProps extends ElementProps<PhoneNumberInputElement> {}

const PhoneNumberInputElementComponent: React.FC<PhoneNumberInputElementProps> = ({ element, updateElement }) => {
    return (
        <div className="w-full m-8">
            <PhoneNumberInput
                id={element.id}
                label={element.showLabel ? element.label : ""}
                value=""
                placeholder={element.placeholder}
                required={element.required}
                onChange={() => {}}
                phoneNumber={element.phoneNumber}
                setPhoneNumber={() => {}}
                isClickDisabled={true}

            />
        </div>
    );
};

export default PhoneNumberInputElementComponent;
