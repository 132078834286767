import { createEntityService } from 'utils/createEntityService';
import { UserTraining } from 'types/userTraining';

export const UserTrainingService = createEntityService<UserTraining>({
    baseUrl: 'api/v1/user_trainings',
    entityName: 'userTraining',
    methodConfigs: {
        create: {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
        update: {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    }
});
