    import React, {useContext, useEffect, useState} from "react";
    import Drawer from "components/Drawer";
    import HrDocumentTable from "features/hrDocument/HrDocumentTable";
    import HrDocumentForm from "features/hrDocument/HrDocumentForm";
    import {TitleContext} from "../../components/TitleContext";


    const HrDocumentsPage: React.FC = () => {
        const [isDrawerOpen, setIsDrawerOpen] = useState(false);
        const openDrawer = () => setIsDrawerOpen(true);
        const closeDrawer = () => setIsDrawerOpen(false);
        const { setTitle } = useContext(TitleContext);

        useEffect(() => {
            setTitle(
                <div>
                    <div className="text-slate-600 text-lg font-medium font-['Inter']  ">
                        HR Documents
                    </div>
                    <div
                        className="text-slate-400 text-sm font-light font-['Inter'] leading-normal ">
                        Manage your company’s HR documents
                    </div>
                </div>
            );
        }, []);

        return (
            <div className="">
                <div className="relative overflow-hidden">
                    <div className="relative">
                        <HrDocumentTable openDrawer={openDrawer}/>
                        <Drawer isOpen={isDrawerOpen} onClose={closeDrawer}>
                            <HrDocumentForm closeDrawer={closeDrawer}/>
                        </Drawer>
                    </div>
                </div>
            </div>
        );
    };

    export default HrDocumentsPage;
