import { useSelector } from 'react-redux';
import { hasAnyPermission } from '../utils/permissions';
import {RootState} from "../store/store";

const usePermissions = () => {
    const user = useSelector((state: RootState) => state.auth.user);

    const checkPermission = (actions: string[] | string, subjectClasses: string[] | string) => {
        if (!user || !user.role || !user.role.permissions) {
            return false;
        }

        if (user.role.name === 'owner') {
            return true;
        }

        return hasAnyPermission(user.role.permissions, actions, subjectClasses);
    };

    return { checkPermission };
};

export default usePermissions;
