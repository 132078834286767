import React from 'react';

interface BlockedProps {
    className?: string
}

const BlockedIcon = ({className}: BlockedProps) => (

    <svg width="14" height="14" viewBox="0 0 24 24" fill="none" strokeWidth="1.5" xmlns="http://www.w3.org/2000/svg">
        <g id="ban">
            <path id="Vector"
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_2" d="M4.8999 4.8999L19.0999 19.0999" stroke="currentColor" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </g>
    </svg>
);
export default BlockedIcon;
