import React from 'react';

interface CircleDashedIconProps {
    className?: string
}

const CircleDashedIcon = ({className}: CircleDashedIconProps) => (
    <svg width="14" height="14" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="circle-dashed" clip-path="url(#clip0_684_1327)">
            <path id="Vector" d="M4.20837 0.908339C4.73123 0.806412 5.26885 0.806412 5.79171 0.908339" stroke="currentColor"
                  stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_2" d="M7.33337 1.54584C7.77599 1.84511 8.15658 2.22712 8.45421 2.67084" stroke="currentColor"
                  stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_3" d="M9.09167 4.20833C9.1936 4.73118 9.1936 5.2688 9.09167 5.79166" stroke="currentColor"
                  stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_4" d="M8.45422 7.33333C8.15495 7.77594 7.77294 8.15653 7.32922 8.45416" stroke="currentColor"
                  stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_5" d="M5.79171 9.09166C5.26884 9.19348 4.73124 9.19348 4.20837 9.09166" stroke="currentColor"
                  stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_6" d="M2.66661 8.45416C2.224 8.15489 1.8434 7.77288 1.54578 7.32916" stroke="currentColor"
                  stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_7" d="M0.908354 5.79166C0.806428 5.2688 0.806428 4.73118 0.908354 4.20833" stroke="currentColor"
                  stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_8" d="M1.54578 2.66667C1.84505 2.22406 2.22706 1.84347 2.67078 1.54584" stroke="currentColor"
                  stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_684_1327">
                <rect width="10" height="10" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);
export default CircleDashedIcon;
