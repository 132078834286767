import React, {useState} from 'react';
import {setActiveTab} from "../user/userSlice";
import Table from "../../components/table/Table";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {useDropdownActionMenu} from "../../hooks/useDropdownActionMenu";
import {SearchType} from "../../types/search";
import { setIsEditing} from "../hrDocument/hrDocumentSlice";
import TableHeader from "../../components/table/TableHeader";
import TableRow from "../../components/table/TableRow";
import TableCell from "../../components/table/TableCell";
import CheckCircleIcon from "../../assets/images/icons/CheckCircleIcon";
import XCircleIcon from "../../assets/images/icons/XCircleIcon";
import DotsMenu from "../../components/DotsMenu";
import {useParams} from "react-router-dom";
import useAttachmentModal from "../../hooks/useAttachmentModal";
import {UserHrDocument} from "../../types/userHrDocument";
import {UserHrDocumentThunks} from "../userHrDocument/userHrDocumentThunks";
import Skeleton from "react-loading-skeleton";

const ExpiringHrDocumentsTable: React.FC<{ openDrawer: () => void }> = ({openDrawer}) => {
    const userHrDocuments = useSelector((state: RootState) => state.userHrDocument.userHrDocuments);
    const loading = useSelector((state: RootState) => state.userHrDocument.status === 'loading');
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.userHrDocument.pagy);
    const params = useParams<{ userId: string }>();
    const [activeTab, setActiveTab] = useState('All');

    const {AttachmentModal, handleNameClick} = useAttachmentModal<UserHrDocument>({
        openDrawer,
        fetchItemThunk: UserHrDocumentThunks.show,

    });

    interface VisibleColumnsType {
        name: boolean;
        expDate: boolean;
        userName: boolean;
        status: boolean;
        jobRole: boolean;
        supervisor: boolean;
        email: boolean;
    }

    const [visibleColumns, setVisibleColumns] = useState({
        name: true,
        expDate: true,
        userName: true,
        status: true,
        jobRole: true,
        supervisor: true,
        email: true,
    });

    const searchByOptions = [
        {label: 'Document', value: 'name', operator: 'like'},
        {label: 'Employee', value: 'user_name', operator: 'like'},
    ];

    const columnOptions = [
        {label: 'Document', value: 'name', isVisible: visibleColumns.name},
        {label: 'Exp Date', value: 'expDate', isVisible: visibleColumns.expDate},
        {label: 'Status', value: 'status', isVisible: visibleColumns.status},
        {label: 'Employee', value: 'userName', isVisible: visibleColumns.userName},
        {label: 'Job Role', value: 'jobRole', isVisible: visibleColumns.jobRole},
        {label: 'Supervisor', value: 'supervisor', isVisible: visibleColumns.supervisor},
        {label: 'Email', value: 'email', isVisible: visibleColumns.email},
    ];

    const fetchData = async ({page, search, sortField, sortDirection, rowsPerPage}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        const {userId} = params;

        if (activeTab === 'All') {
            search.where.status = {
                operator: 'in',
                valueArray: ['missing', 'expired', 'expiring_soon']
            };
        } else if (activeTab === 'Missing Documents') {
            search.where.status = {
                operator: '==',
                value: 'missing'
            }
        } else if (activeTab === 'Expiring Documents') {
            search.where.status = {
                operator: '==',
                value: 'expiring_soon'
            }
        }
        else if (activeTab === 'Expired Documents') {
            search.where.status = {
                operator: '==',
                value: 'expired'
            }

        } else {
            delete search.where.status;
        }

        await dispatch(UserHrDocumentThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
    };


    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };

    const handleEdit = async (id: string) => {
        await dispatch(UserHrDocumentThunks.show(id));
        dispatch(setIsEditing(true));
        openDrawer();
    }


    return (
        <>
            <Table
                tabs={['All','Missing Documents', 'Expiring Documents', 'Expired Documents']}
                activeTab={'All'}
                fetchData={fetchData}
                setActiveTab={(tab: string) => setActiveTab(tab)}
                pagy={pagy}
                loading={loading}
                recordsName={'Documents'}
                columns={true}
                blueButton={false}
                columnOptions={columnOptions}
                onColumnToggle={handleColumnToggle}
                searchByOptions={searchByOptions}
            >
                <thead>
                <tr>
                    <th scope="col" className="px-3  pt-3.5  flex justify-center">
                        <input type="checkbox"
                               className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500
                                      dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"/>
                    </th>
                    {visibleColumns.name && <TableHeader label="Document" sortBy="name"/>}
                    {visibleColumns.expDate && <TableHeader label="Exp Date" sortBy="expDate"/>}
                    {visibleColumns.userName && <TableHeader label="Employee" sortBy="userName"/>}
                    {visibleColumns.status && <TableHeader label="Status" sortBy="status"/>}
                    {visibleColumns.jobRole && <TableHeader label="Job Role" sortBy="jobRole"/>}
                    {visibleColumns.supervisor && <TableHeader label="Supervisor" sortBy="supervisor"/>}
                    {visibleColumns.email && <TableHeader label="Email" sortBy="email"/>}

                    <TableHeader label=""/>
                </tr>
                </thead>
                <tbody>

                {userHrDocuments.length !== 0 && userHrDocuments.map((userHrDocument: UserHrDocument) => {
                        const userOptions = [
                            {
                                label: 'Edit',
                                onClick: () => handleEdit(userHrDocument.id),
                                hoverClass: "hover:bg-cyan-100"
                            },

                            ...(userHrDocument.status === 'Missing' ? [{
                                label: 'Upload',
                                onClick: () => handleEdit(userHrDocument.id),
                                hoverClass: "hover:bg-cyan-100"
                            }] : []),

                        ];
                        if (loading) {
                            return (
                                <TableRow key={userHrDocument.id}>
                                    <td colSpan={6}>
                                        <Skeleton count={1} height={40}/>
                                    </td>
                                </TableRow>
                            );
                        } else {
                            return (
                                <TableRow key={userHrDocument.id} className="border-t border-slate-200">
                                    <td scope="col" className="pt-3.5 flex justify-center">
                                        <input
                                            type="checkbox"
                                            className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600
                                           dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                            onClick={(e) => e.stopPropagation()}/>
                                    </td>
                                    {visibleColumns.name && <TableCell>{userHrDocument.name}  </TableCell>}
                                    {visibleColumns.expDate && <TableCell>{userHrDocument.expirationDate || 'NA'}</TableCell>}
                                    {visibleColumns.userName && <TableCell>{userHrDocument.user?.name}</TableCell>}
                                    {visibleColumns.status &&
                                        <TableCell>
                                <span
                                    className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-28
                                    ${userHrDocument.status === 'Complete' ? 'bg-green-100 text-green-800' : ''}
                                    ${userHrDocument.status === 'Missing' ? 'bg-red-100 text-red-800' : ''}
                                    ${userHrDocument.status === 'Expiring soon' ? 'bg-yellow-100 text-yellow-800' : ''}
                                    ${userHrDocument.status === 'Expired' ? 'bg-red-100 text-red-800' : ''}
                                    `}
                                >
                                    {userHrDocument.status === 'Complete' && <CheckCircleIcon className="mr-2"/>}
                                    {userHrDocument.status === 'Missing' && <XCircleIcon className="mr-2"/>}
                                    {userHrDocument.status === 'Expiring soon' && <XCircleIcon className="mr-2"/>}
                                    {userHrDocument.status === 'Expired' && <XCircleIcon className="mr-2"/>}

                                    <div className="pl-1">
                                        {userHrDocument.status}
                                    </div>
                                </span>
                                        </TableCell>
                                    }

                                    {visibleColumns.jobRole && <TableCell>{userHrDocument.user?.jobRoles}</TableCell>}
                                    {visibleColumns.supervisor && <TableCell>{userHrDocument.user?.supervisors}</TableCell>}
                                    {visibleColumns.email && <TableCell>{userHrDocument.user?.email}</TableCell>}

                                    <TableCell>
                                        <DotsMenu options={userOptions}/>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    }
                )}
                </tbody>
            </Table>
            <AttachmentModal/>
        </>
    );
};
export default ExpiringHrDocumentsTable;
