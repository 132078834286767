import React, {useEffect, useState} from "react";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import TableCell from "components/table/TableCell";
import {Credential} from "types/credential";
import Table from "components/table/Table";
import {SearchType} from "types/search";
import {CredentialThunks} from "features/credential/credentialThunks";
import {setIsEditing, setCredential,setActiveTab} from "features/credential/credentialSlice";
import BlueButton from "components/BlueButton";
import PlusIcon from "assets/images/icons/PlusIcon";
import TrashIcon from "assets/images/icons/TrashIcon";
import DotsMenu from "components/DotsMenu";
import {useDropdownActionMenu} from "hooks/useDropdownActionMenu";
import CheckCircleIcon from "assets/images/icons/CheckCircleIcon";
import XCircleIcon from "assets/images/icons/XCircleIcon";


const CredentialTable: React.FC<{ openDrawer: () => void }> = ({openDrawer}) => {
    const credentials = useSelector((state: RootState) => state.credential.credentials);
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.credential.pagy);
    const {setDotsMenuIsOpen} = useDropdownActionMenu();
    const activeTab = useSelector((state: RootState) => state.credential.activeTab);
    const loading = useSelector((state: RootState) => state.credential.status === 'loading');

    const [visibleColumns, setVisibleColumns] = useState({
        name: true,
        status: true,
        description: true,
        licensingAuthority: true,
        expires: true,
        remindHr: true,
        hrReminderDays: true,
        remindUser: true,
        userReminderDays: true,
        requiresTraining: true,
        trainingLinks: true

    });

    interface VisibleColumnsType {
        name: boolean,
        description: boolean,
        licensingAuthority: boolean,
        status: boolean,
        expires: boolean,
        remindHr: boolean,
        hrReminderDays: boolean,
        remindUser: boolean,
        userReminderDays: boolean,
        requiresTraining: boolean,
        trainingLinks: boolean
    }
    const searchByOptions = [
        {label: 'Name', value: 'name', operator: 'like'},

    ];

    const columnOptions = [
        {label: 'Certification', value: 'name', isVisible: visibleColumns.name},
        {label: 'Description', value: 'description', isVisible: visibleColumns.description},
        {label: 'Licensing authority', value: 'licensingAuthority', isVisible: visibleColumns.licensingAuthority},
        {label: 'Status', value: 'status', isVisible: visibleColumns.status},
        {label: 'Expires', value: 'expires', isVisible: visibleColumns.expires},
        {label: 'Requires training', value: 'requiresTraining', isVisible: visibleColumns.requiresTraining},
    ];

    const fetchData = async ({page, search, sortField, sortDirection, rowsPerPage}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        if (activeTab === 'Active' || activeTab === 'Inactive') {
            search.where.status = {
                operator: '==',
                value: activeTab.toLowerCase()
            };
        } else {
            delete search.where.status;
        }
        await dispatch(CredentialThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
    };

    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };

    const handleOpenDrawer = (id: string) => async () => {
        openDrawer();
        await dispatch(CredentialThunks.show(id));
    };
    const handleDelete = async (id: string) => {
        await dispatch(CredentialThunks.delete(id));
        setDotsMenuIsOpen(null);
    };
    const handleDeactivate = (credentialId: string) => {
        const credential = new Credential({id: credentialId, status: 'inactive'})
        dispatch(CredentialThunks.deactivate(credentialId));
        setDotsMenuIsOpen(null);
    };

    const handleReactivate = (credentialId: string) => {
        const credential = new Credential({id: credentialId, status: 'active'})
        dispatch(CredentialThunks.activate(credentialId));
        setDotsMenuIsOpen(null);
    };

    const handleOpenNewCredentialDrawer = () => {
        dispatch(setCredential({
            name: '',
            description: '',
            status: '',
            licensingAuthority: '',
            expires: '',
            remindHr: '',
            hrReminderDays: '',
            remindUser: '',
            userReminderDays: '',
            requiresTraining: '',
            trainingLinks: [],
        }));
        dispatch(setIsEditing(true));
        openDrawer();
    };

    return (
        <Table
            fetchData={fetchData}
            pagy={pagy}
            pageable={true}
            recordsName={'Credentials'}
            activeTab={activeTab}
            loading={loading}
            setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
            tabs={['All', 'Active', 'Inactive']}
            searchable={true}
            columns={true}
            columnOptions={columnOptions}
            onColumnToggle={handleColumnToggle}
            searchByOptions={searchByOptions}
            blueButton={<BlueButton onClick={handleOpenNewCredentialDrawer} label="" icon={<PlusIcon/>}/>}
        >
            <thead>
            <tr>
                <th scope="col" className="px-3  pt-3.5  flex justify-center">
                    <input type="checkbox"
                           className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600
                                      dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"/>
                </th>
                {visibleColumns.name && <TableHeader label="Certification" sortBy="name"/>}
                {visibleColumns.description && <TableHeader label="Description"/>}
                {visibleColumns.licensingAuthority && <TableHeader label="Licensing authority" sortBy="licensingAuthority"/>}
                {visibleColumns.status && <TableHeader label="Status" sortBy="status"/>}
                {visibleColumns.expires && <TableHeader label="Expires" sortBy="expires"/>}
                {visibleColumns.requiresTraining && <TableHeader label="Requires training" sortBy="requiresTraining"/>}
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
            {credentials.map((credential: Credential) => {
                const userOptions = [];
                userOptions.push({
                    label: "Edit",
                    onClick: handleOpenDrawer(credential.id),
                    hoverClass: "hover:bg-cyan-100",
                });
                if (credential.status === "Active") {
                    userOptions.push({
                        label: "Deactivate",
                        onClick: () => handleDeactivate(credential.id),
                        hoverClass: "hover:bg-red-100 hover:text-red-800",
                    });
                }
                if (credential.status === "Inactive") {
                    userOptions.push({
                        label: "Activate",
                        onClick: () => handleReactivate(credential.id),
                        hoverClass: "hover:bg-cyan-100",
                    });

                }
                userOptions.push({
                    label: "Delete",
                    onClick: () => handleDelete(credential.id),
                    icon: <TrashIcon/>,
                    hoverClass: "hover:bg-red-100 hover:text-red-800",
                });
                    return (
                <TableRow
                    key={credential.id}
                >
                    <td scope="col" className="pt-3.5 flex justify-center">
                        <input
                            type="checkbox"
                            className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600
                                       dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                            onClick={(e) => e.stopPropagation()}/>
                    </td>
                    {visibleColumns.name &&
                    <TableCell>
                        <div className=" relative group">
                            <div className="w-full flex items-center gap-x-3">
                                <div className="flex-grow">
                                        <span className="text-sm  cursor-pointer font-medium dark:text-neutral-200 underline text-cyan-600"
                                              onClick={handleOpenDrawer(credential.id)}
                                        >
                                            {credential.name}
                                        </span>
                                </div>
                            </div>
                        </div>
                    </TableCell>}
                    {visibleColumns.description && (
                        <TableCell className="whitespace-nowrap overflow-hidden text-ellipsis">
                            <div style={{ maxWidth: '150px' }} className="whitespace-nowrap overflow-hidden text-ellipsis">
                                {credential.description}
                            </div>
                        </TableCell>
                    )}
                    {visibleColumns.licensingAuthority && <TableCell>{credential.licensingAuthority}</TableCell>}
                    {visibleColumns.status && (
                        <TableCell>
                            <span
                                className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-28
                                    ${credential.status === 'Active' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-700'}`}
                            >
                                {credential.status === 'Active' ? <CheckCircleIcon className="mr-2"/> :
                                    <XCircleIcon className="mr-2"/>}

                                <div className="pl-1">
                                    {credential.status === 'Active' ? credential.status : 'Inactive'}
                                </div>
                            </span>
                        </TableCell>)}
                    {visibleColumns.expires && <TableCell>{credential.expires ? 'Yes' : 'No'}</TableCell>}
                    {visibleColumns.requiresTraining && <TableCell>{credential.requiresTraining ? 'Yes' : 'No'}</TableCell>}
                    <TableCell>
                        <DotsMenu options={userOptions}/>
                    </TableCell>
                </TableRow>

                    )}
            )}
            </tbody>
        </Table>
    );
};

export default CredentialTable;
