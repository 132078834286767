import React from 'react';

const XIcon: React.FC = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="close">
                <path id="icon" d="M4.26667 12.6667L3.33333 11.7333L7.06667 8.00001L3.33333 4.26668L4.26667 3.33334L8 7.06668L11.7333 3.33334L12.6667 4.26668L8.93333 8.00001L12.6667 11.7333L11.7333 12.6667L8 8.93334L4.26667 12.6667Z" fill="currentColor"/>
            </g>
        </svg>
    );
};
export default XIcon;
