import React, {useContext, useEffect, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store/store';
import {DocumentTemplateThunks} from 'features/documentTemplate/documentTemplateThunks';
import TextInput from 'components/inputs/TextInput';
import CustomSelect from 'components/inputs/CustomSelect';
import SubmitButton from 'components/SubmitButton';
import PlusIcon from "assets/images/icons/PlusIcon";
import {DocumentTemplate} from "types/documentTemplate";
import {TitleContext} from "components/TitleContext";
import {DocumentTemplateRow} from "types/documentTemplateRow";
import {
    DocumentElementType, DateInputElement, DropdownElement,
    DocumentTemplateElement, MultiLineTextInputElement,
    PhoneNumberInputElement, TextBlockElement, TextInputElement, YesNoInputElement
} from "types/documentTemplateElement";
import {DocumentTemplateColumn} from "types/documentTemplateColumn";
import LogoNewHope from "assets/images/LogoNewHope.png";
import RenderElement from "../../components/documentTemplate/RenderElement";
import RenderElementProperties from "../../components/documentTemplate/RenderElementProperties";

const DocumentTemplateFormPage: React.FC = () => {
    const {id} = useParams<{ id: string }>();
    const dispatch = useDispatch<AppDispatch>();
    const tabs = ['Builder', 'Preview'];
    const [activeTab, setActiveTab] = React.useState('Builder');
    const documentTemplate = useSelector((state: RootState) => state.documentTemplate.documentTemplate);
    const [updatedDocumentTemplate, setUpdatedDocumentTemplate] = React.useState({...documentTemplate});
    const [addRowIsOpen, setAddRowIsOpen] = React.useState(false);
    const [menuPosition, setMenuPosition] = React.useState({top: 0, left: 0});
    const addRowButtonRef = React.useRef<HTMLButtonElement>(null);
    const addRowDropdownRef = React.useRef<HTMLDivElement>(null);
    const {setTitle} = useContext(TitleContext);
    const selectedElementRef = useRef<HTMLDivElement>(null);
    const propertiesElementRef = useRef<HTMLDivElement>(null);
    const [selectedElement, setSelectedElement] = React.useState<{
        element: DocumentTemplateElement;
        rowId: string;
        colId: string;
    } | null>(null);


    const [openDropdown, setOpenDropdown] = React.useState<{ rowId: string; colId: string } | null>(null);

    const columnDropdownRef = useRef<HTMLDivElement>(null);

    const inputOptions: any =
        {
            'default': [
                {name: 'Text Block', value: 'TextBlock'},
            ],
            'advanced': [
                {name: 'Phone Number', value: 'PhoneNumberInput', category: 'advanced'},
                {name: 'Date', value: 'DateInput', category: 'advanced'},

            ],
            'basic': [
                {name: 'Single-line text', value: 'TextInput', category: 'basic'},
                {name: 'Multi-line text', value: 'MultiLineTextInput', category: 'basic'},
                {name: 'Yes/No', value: 'YesNoInput', category: 'basic'},
                {name: 'Dropdown', value: 'Dropdown'}
            ]
        };

    useEffect(() => {
        setTitle(
            <div>
                <div className="text-slate-600 text-lg font-medium font-['Inter']">
                    Document Builder
                </div>
                <div className="text-slate-400 text-sm font-light font-['Inter'] leading-normal">
                    Manage custom document templates
                </div>
            </div>
        );
    }, [setTitle]);

    useEffect(() => {
        if (id) {
            dispatch(DocumentTemplateThunks.show(id));
        }
    }, [id, dispatch]);

    useEffect(() => {
        setUpdatedDocumentTemplate({...documentTemplate});
    }, [documentTemplate]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const {id, value} = e.target;
        setUpdatedDocumentTemplate({...updatedDocumentTemplate, [id]: value});
    };

    const toggleDropdown = (dropdown: 'addRow', event: React.MouseEvent<HTMLButtonElement>) => {
        if (dropdown === 'addRow') {
            setAddRowIsOpen(!addRowIsOpen);
        }

        const buttonRect = event.currentTarget.getBoundingClientRect();
        setMenuPosition({
            top: buttonRect.top + buttonRect.height,
            left: buttonRect.left
        });
    };

    const handlePublish = () => {
        if (id) {
            dispatch(DocumentTemplateThunks.update(new DocumentTemplate(updatedDocumentTemplate)));
        } else {
            dispatch(DocumentTemplateThunks.create(new DocumentTemplate(updatedDocumentTemplate)));
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            addRowDropdownRef.current &&
            !addRowDropdownRef.current.contains(event.target as Node) &&
            addRowButtonRef.current &&
            !addRowButtonRef.current.contains(event.target as Node)
        ) {
            setAddRowIsOpen(false);
        }
        if (
            columnDropdownRef.current &&
            !columnDropdownRef.current.contains(event.target as Node)
            || !columnDropdownRef.current
        ) {
            setOpenDropdown(null);
        }
        if (
            selectedElementRef.current &&
            !selectedElementRef.current.contains(event.target as Node) &&
            propertiesElementRef.current &&
            !propertiesElementRef.current.contains(event.target as Node)
        ) {
            setSelectedElement(null);
        }

    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleAddRow = (columnsCount: number) => {
        if (!updatedDocumentTemplate.rows) {
            setUpdatedDocumentTemplate((prevTemplate: any) => ({
                ...prevTemplate,
                rows: [],
            }));
        }
        const newRow = {
            id: `row-${Date.now()}`,
            columns: Array.from({length: columnsCount}).map((_, index) => ({
                id: `col-${Date.now()}-${index}`,
                width: 1 / columnsCount,
                element: null,
            })),
        };

        setUpdatedDocumentTemplate((prevTemplate: any) => ({
            ...prevTemplate,
            rows: [...prevTemplate.rows, newRow],
        }));
        setAddRowIsOpen(false);
    };

    const handleSelectElement = (
        rowId: string,
        colId: string,
        elementType: string
    ) => {
        elementType = elementType.replace(' ', '');
        let newElement: DocumentTemplateElement;
        switch (elementType) {
            case 'TextBlock':
                newElement = {
                    id: `element-${Date.now()}`,
                    type: 'TextBlock',
                    content: '',
                } as TextBlockElement;
                break;
            case 'TextInput':
                newElement = {
                    id: `element-${Date.now()}`,
                    type: 'TextInput',
                    label: 'Label',
                    required: false,
                } as TextInputElement;
                break;
            case 'MultiLineTextInput':
                newElement = {
                    id: `element-${Date.now()}`,
                    type: 'MultiLineTextInput',
                    label: 'Label',
                    required: false,
                } as MultiLineTextInputElement;
                break;
            case 'PhoneNumberInput':
                newElement = {
                    id: `element-${Date.now()}`,
                    type: 'PhoneNumberInput',
                    label: 'Label',
                    required: false,
                } as PhoneNumberInputElement;
                break;
            case 'YesNoInput':
                newElement = {
                    id: `element-${Date.now()}`,
                    type: 'YesNoInput',
                    label: 'Label',
                    required: false,
                } as YesNoInputElement;
                break;
            case 'DateInput':
                newElement = {
                    id: `element-${Date.now()}`,
                    type: 'DateInput',
                    label: 'Label',
                    required: false,
                    placeholder: 'Placeholder',
                    allowPastDates: false,
                } as DateInputElement;
                break;
            case 'Dropdown':
                newElement = {
                    id: `element-${Date.now()}`,
                    type: 'Dropdown',
                    label: 'Label',
                    isMulti: false,
                    required: false,
                    options: [],
                    showLabel: false,
                } as DropdownElement;
                break;
            default:
                return;
        }

        setUpdatedDocumentTemplate((prevTemplate: any) => {
            const updatedRows = prevTemplate.rows.map((row: { id: string; columns: { id: string; }[]; }) => {
                if (row.id !== rowId) return row;
                return {
                    ...row,
                    columns: row.columns.map((col: { id: string; }) => {
                        if (col.id !== colId) return col;
                        return {
                            ...col,
                            element: newElement,
                        };
                    }),
                };
            });
            return {...prevTemplate, rows: updatedRows};
        });
        setOpenDropdown(null);
        setSelectedElement(
            {
                element: newElement,
                rowId,
                colId,
            }
        );
    };

    return (
        <div className="flex flex-col min-h-[94vh]">
            <div className="relative overflow-hidden">
                <div className="relative">
                    <div
                        className="mb-8 text-sm font-medium text-center text-slate-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                        <ul className="flex flex-wrap -mb-px">
                            {tabs.map((tab) => (
                                <li
                                    key={tab}
                                    className={`me-2 cursor-pointer inline-block p-2 border-b-2 text-slate-400 ${
                                        activeTab === tab
                                            ? 'border-cyan-400 text-slate-600 dark:text-gray-200 dark:border-cyan-400'
                                            : 'border-transparent'
                                    } rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300`}
                                    onClick={() => setActiveTab(tab)}
                                    aria-current={activeTab === tab ? 'page' : undefined}
                                >
                                    {tab}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div
                        className="p-5 space-x-4 flex bg-white border border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
                        <div className="flex-grow">
                            <TextInput
                                id="name"
                                type="text"
                                label="Document Name"
                                value={updatedDocumentTemplate.name || ''}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="w-40">
                            <div className="flex flex-col gap-[0.625rem] w-full">
                                <label
                                    htmlFor="enableHeader"
                                    className="w-full block text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white"
                                >
                                    Enable header
                                </label>
                                <CustomSelect
                                    menuPortalTarget={document.body}
                                    options={[
                                        {value: true, label: 'Yes'},
                                        {value: false, label: 'No'},
                                    ]}
                                    value={updatedDocumentTemplate.enableHeader}
                                    onChange={(selectedOption) => {
                                        setUpdatedDocumentTemplate((prevData: any) => ({
                                            ...prevData,
                                            enableHeader: selectedOption.value,
                                        }));
                                    }}
                                />
                            </div>
                        </div>

                        <div className="w-40 mt-1.5">
                            <SubmitButton onClick={handlePublish} label="Publish" disabled={false}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-1 pt-8 overflow-hidden">
                <div
                    className="w-9/12 mr-4 p-5 bg-white border border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700 overflow-auto">
                    <>
                        {updatedDocumentTemplate.enableHeader && (
                            <div className="flex mb-4">
                                <div className={`flex-1 px-2 relative`}>
                                    <div
                                        className="w-full h-full min-h-20 border-2 border-dashed border-gray-300 flex items-center justify-start">
                                        <div className="text-slate-600 text-lg font-medium font-['Inter']">
                                            <img
                                                className="pl-3 w-24 h-auto"
                                                src={LogoNewHope}
                                                alt="Logo"
                                            />
                                        </div>
                                        <div
                                            className="text-slate-400 text-md font-light font-['Inter'] leading-normal ml-1 mr-1">
                                            |
                                        </div>
                                        <div
                                            className="text-slate-400 text-md font-light font-['Inter'] leading-normal">
                                            {updatedDocumentTemplate.name}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* Renderizar las filas */}
                        {updatedDocumentTemplate.rows?.map((row: DocumentTemplateRow) => {
                            return (
                                <div key={row.id} className="flex mb-4">
                                    {row.columns.map((col: DocumentTemplateColumn) => {
                                        return (
                                            <div key={col.id} className={`flex-1 px-2 relative`}>
                                                {col.element ? (
                                                    <div
                                                        ref={
                                                            selectedElement &&
                                                            selectedElement.rowId === row.id &&
                                                            selectedElement.colId === col.id
                                                                ? selectedElementRef
                                                                : null
                                                        }
                                                        className={`w-full h-full min-h-20 border-2 border-dashed ${selectedElement &&
                                                        selectedElement.rowId === row.id &&
                                                        selectedElement.colId === col.id
                                                            ? 'border-slate-500'
                                                            : 'border-slate-200'
                                                        } flex items-center justify-center`}
                                                        onClick={() =>
                                                            setSelectedElement({
                                                                element: col.element,
                                                                rowId: row.id,
                                                                colId: col.id
                                                            })
                                                        }>
                                                        <RenderElement
                                                            element={col.element}
                                                            updateElement={(updatedElement) => {
                                                                setUpdatedDocumentTemplate((prevTemplate: any) => {
                                                                    const updatedRows = prevTemplate.rows.map((rowItem: DocumentTemplateRow) => {
                                                                        if (rowItem.id !== row.id) return rowItem;
                                                                        return {
                                                                            ...rowItem,
                                                                            columns: rowItem.columns.map((column: DocumentTemplateColumn) => {
                                                                                if (column.id !== col.id) return column;
                                                                                return {
                                                                                    ...column,
                                                                                    element: updatedElement,
                                                                                };
                                                                            }),
                                                                        };
                                                                    });
                                                                    return {...prevTemplate, rows: updatedRows};
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <button
                                                        className="w-full h-full min-h-20 border-2 border-dashed border-gray-300 flex items-center justify-center"
                                                        onClick={() => {
                                                            // Mostrar dropdown para seleccionar tipo de elemento.
                                                            setOpenDropdown({rowId: row.id, colId: col.id});
                                                        }}
                                                    >
                                                        <PlusIcon/>
                                                    </button>
                                                )}

                                                {/* Dropdown para seleccionar el tipo de elemento */}
                                                {openDropdown &&
                                                    openDropdown.rowId === row.id &&
                                                    openDropdown.colId === col.id && (
                                                        <div
                                                            ref={columnDropdownRef}
                                                            className="absolute mt-2 w-60 bg-white rounded-xl shadow-lg z-10 dark:bg-neutral-900"
                                                        >
                                                            <div className="p-1">
                                                                {/* Basic Input Elements Section */}
                                                                {inputOptions['default']
                                                                    .map((type: any) => (
                                                                        <button
                                                                            key={type.value}
                                                                            type="button"
                                                                            onClick={() =>
                                                                                handleSelectElement(
                                                                                    row.id,
                                                                                    col.id,
                                                                                    type.value as DocumentElementType
                                                                                )
                                                                            }
                                                                            className="w-full flex items-center gap-x-3 py-2 px-3 rounded-lg text-[13px] text-gray-800 hover:bg-cyan-100"
                                                                        >
                                                                            {type.name}
                                                                        </button>
                                                                    ))}
                                                                <div
                                                                    className="border-t border-slate-200 py-2 mt-2 mx-3">
                                                                </div>
                                                                <div
                                                                    className="text-slate-400 text-xs font-semibold px-3 py-1">
                                                                    BASIC INPUT ELEMENTS
                                                                </div>
                                                                {inputOptions['basic']
                                                                    .map((type: any) => (
                                                                        <button
                                                                            key={type.value}
                                                                            type="button"
                                                                            onClick={() =>
                                                                                handleSelectElement(
                                                                                    row.id,
                                                                                    col.id,
                                                                                    type.value as DocumentElementType
                                                                                )
                                                                            }
                                                                            className="w-full flex items-center gap-x-3 py-2 px-3 rounded-lg text-[13px] text-gray-800 hover:bg-cyan-100"
                                                                        >
                                                                            {type.name}
                                                                        </button>
                                                                    ))}

                                                                {/* Advanced Input Elements Section */}
                                                                <div
                                                                    className="border-t border-slate-200 py-2 mt-2 mx-3">
                                                                </div>
                                                                <div
                                                                    className="text-slate-400 text-xs font-semibold px-3 py-1">
                                                                    ADVANCED INPUT ELEMENTS
                                                                </div>
                                                                {inputOptions['advanced']
                                                                    .map((type: any) => (
                                                                        <button
                                                                            key={type.value}
                                                                            type="button"
                                                                            onClick={() =>
                                                                                handleSelectElement(
                                                                                    row.id,
                                                                                    col.id,
                                                                                    type.value as DocumentElementType
                                                                                )
                                                                            }
                                                                            className="w-full flex items-center gap-x-3 py-1.5 px-3 rounded-lg text-[13px] text-gray-800 hover:bg-cyan-100"
                                                                        >
                                                                            {type.name}
                                                                        </button>
                                                                    ))}
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}


                        {/* Botón para agregar fila */}
                        <div className="flex justify-center">
                            <div className="hs-dropdown [--auto-close:true] relative inline-flex">
                                <button
                                    id="hs-dropdown-menu"
                                    type="button"
                                    onClick={(event) => toggleDropdown('addRow', event)}
                                    className="py-4 px-5 inline-flex items-center gap-x-1.5 text-xs rounded-lg border border-slate-200 bg-white text-gray-800
                  hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700
                  dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 relative"
                                    ref={addRowButtonRef}
                                >
                                    <PlusIcon/>
                                    Add Row
                                </button>

                                {addRowIsOpen && (
                                    <div
                                        className="fixed bg-white rounded-l z-10"
                                        style={{
                                            top: `${menuPosition.top}px`,
                                            left: `${menuPosition.left}px`,
                                        }}
                                    >
                                        <div
                                            className="top-full mt-1 absolute hs-dropdown-menu shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)] hs-dropdown-open:opacity-100 w-[110px] transition-[opacity,margin] duration opacity-100 z-10 bg-white rounded-xl
                      dark:bg-neutral-900 block"
                                            ref={addRowDropdownRef}
                                            aria-labelledby="hs-dropdown-menu"
                                        >
                                            <div className="p-1">
                                                {[1, 2, 3, 4].map((columns) => (
                                                    <button
                                                        key={columns}
                                                        type="button"
                                                        className="w-full flex gap-x-3 py-1.5 px-3 rounded-lg text-[13px] text-gray-800 hover:bg-cyan-100 disabled:opacity-50
                            disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                                                        onClick={() => handleAddRow(columns)}
                                                    >
                                                        {columns} column{columns > 1 ? 's' : ''}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>

                </div>

                {selectedElement ? (
                    <div
                        ref={propertiesElementRef}
                        className="w-3/12 p-5 bg-white border border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700 overflow-auto flex justify-center">
                        <RenderElementProperties
                            element={selectedElement.element}
                            updateElement={(updatedElement) => {
                                // Actualizar el elemento en el estado global
                                setUpdatedDocumentTemplate((prevTemplate: any) => {
                                    const updatedRows = prevTemplate.rows.map((rowItem: DocumentTemplateRow) => {
                                        if (rowItem.id !== selectedElement.rowId) return rowItem;
                                        return {
                                            ...rowItem,
                                            columns: rowItem.columns.map((column) => {
                                                if (column.id !== selectedElement.colId) return column;
                                                return {
                                                    ...column,
                                                    element: updatedElement,
                                                };
                                            }),
                                        };
                                    });
                                    return {...prevTemplate, rows: updatedRows};
                                });

                                // Actualizar el elemento seleccionado
                                setSelectedElement({
                                    ...selectedElement,
                                    element: updatedElement,
                                });
                            }}
                        />
                    </div>
                ) : (
                    <div
                        className="w-3/12 p-5 bg-white border border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700 overflow-auto flex items-center justify-center">
                        <p className="text-slate-400 text-sm text-center font-medium">
                            Create and select an element on the left to edit its properties here
                        </p>
                    </div>

                )}
            </div>
        </div>
    );
};

export default DocumentTemplateFormPage;
