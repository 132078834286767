import React from 'react';


const ArrowLeftLineIcon = () => (

    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor"
         stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
         className="lucide lucide-arrow-left-from-line">
        <path d="m9 6-6 6 6 6"/>
        <path d="M3 12h14"/>
        <path d="M21 19V5"/>
    </svg>
);
export default ArrowLeftLineIcon;
