import { BackgroundDocumentService } from 'services/backgroundDocumentService';
import { createEntityThunks } from 'utils/createEntityThunks';
import { BackgroundDocument } from 'types/backgroundDocument';

const backgroundDocumentFactory = (data: any) => new BackgroundDocument(data);

export const BackgroundDocumentThunks = createEntityThunks<BackgroundDocument>(
    'backgroundDocument',
    BackgroundDocumentService,
    { activate: true, deactivate: true },
    backgroundDocumentFactory
)
