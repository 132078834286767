import React from "react";

interface RadioGroupHorizontalProps {
    label: string;
    options: { id: string; label: string; value: boolean }[];
    selectedValue: boolean | undefined;
    onChange: (value: boolean) => void;
}
const RadioInputHorizontal: React.FC<RadioGroupHorizontalProps> = ({ label, options, selectedValue, onChange }) => {
    return (
        <div className="flex items-center  justify-between">
            <label className="text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                {label}
            </label>
            <div className="flex gap-4 items-center">
                {options.map((option) => (
                    <div key={option.id} className="flex items-center gap-x-2">
                        <input
                            type="radio"
                            id={option.id}
                            checked={selectedValue === option.value}
                            onChange={() => onChange(option.value)}
                            className="bg-transparent border-gray-200 focus:ring-white focus:ring-offset-0 dark:border-neutral-700 dark:focus:ring-neutral-800"
                        />
                        <label htmlFor={option.id} className="text-slate-800 text-sm font-medium dark:text-white">
                            {option.label}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};


export default RadioInputHorizontal;
