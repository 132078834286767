import React from 'react';
import {FaUser} from "react-icons/fa";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import {UserInvitation} from "types/userInvitation";
import LoadingSpinner from "components/LoadingSpinner";
import {UserInvitationThunks} from "features/userInvitation/userInvitationThunk";
import ChevronRightIcon from "assets/images/icons/ChevronRightIcon";
import SSNDisplay from "components/SSNDisplay";
import PhoneNumberDisplay from "components/PhoneNumberDisplay";


interface UserInvitationDetailsProps {
    onClose: () => void;
}

const UserInvitationDetails: React.FC<UserInvitationDetailsProps> = ({onClose}) => {
    const dispatch = useDispatch<AppDispatch>();
    const userInvitation = useSelector((state: RootState) => state.userInvitation.userInvitation as UserInvitation);

    const handleResend = () => {
        dispatch(UserInvitationThunks.create(userInvitation));
    }

    const handleCancelInvitation = () => {
        dispatch(UserInvitationThunks.cancel(userInvitation.id));
    }

    return (
        <div className="flex flex-col h-screen">
            {userInvitation !== null &&
                <div className="flex-1 flex flex-col">
                    <div className="flex-1 ">
                        <div
                            className="pt-8 pb-10 bg-sky-50 border-b border-sky-200 flex-col justify-start items-end gap-2 inline-flex w-full">
                            <div className="pl-6 self-stretch flex flex-row  gap-3">
                                <div
                                    className="flex-shrink-0 w-14 h-14 mt-1.5 bg-cyan-400 rounded-lg flex justify-center items-center">
                                    <FaUser className="text-white size-8"/>
                                </div>
                                <div className="flex flex-col w-full">
                                    <div className="w-full text-lg font-semibold tracking-normal text-cyan-800">
                                        {userInvitation?.firstName} {userInvitation?.lastName}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="py-2 px-4 sm:px-6">
                            <div className="h-full ">
                                    <dl className="py-4 grid grid-cols-3 gap-x-4 dark:border-neutral-700">
                                        <dt className="col-span-1">
                                            <p className="text-sm text-gray-500 dark:text-neutral-500">
                                                Employee ID:
                                            </p>
                                        </dt>
                                        <dd className="col-span-2 flex items-center">
                                            <p className="text-sm text-gray-800 dark:text-neutral-200">
                                                {userInvitation?.id}
                                            </p>
                                        </dd>
                                    </dl>
                                <dl className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                                    <dt className="col-span-1 flex items-center">
                                        <p className="text-sm text-gray-500 dark:text-neutral-500">
                                            Email:
                                        </p>
                                    </dt>
                                    <dd className="col-span-2 flex items-center">
                                        <p className="text-sm text-gray-800 dark:text-neutral-200">
                                            {userInvitation?.email}
                                        </p>
                                    </dd>
                                </dl>
                                <dl className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                                    <dt className="col-span-1 flex items-center">
                                        <p className="text-sm text-gray-500 dark:text-neutral-500">
                                            Personal Email:
                                        </p>
                                    </dt>
                                    <dd className="col-span-2 flex items-center ">
                                        <p className="text-sm text-gray-800 dark:text-neutral-200">
                                            {userInvitation?.personalEmail}
                                        </p>
                                    </dd>
                                </dl>
                                <dl className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                                    <dt className="col-span-1 flex items-center">
                                        <p className="text-sm text-gray-500 dark:text-neutral-500">
                                            SSN:
                                        </p>
                                    </dt>
                                    <dd className="col-span-2">
                                        <div className="flex items-center gap-x-2">
                                                <span className="text-sm text-gray-800 dark:text-neutral-200">
                                                    <SSNDisplay value={userInvitation?.ssn}/>
                                                </span>
                                        </div>
                                    </dd>
                                </dl>
                                <dl className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                                    <dt className="col-span-1 flex items-center">
                                        <p className="text-sm text-gray-500 dark:text-neutral-500">
                                            Phone number:
                                        </p>
                                    </dt>
                                    <dd className="col-span-2 flex items-center">
                                        <p className="text-sm text-gray-800 dark:text-neutral-200">
                                            <PhoneNumberDisplay value={userInvitation?.phoneNumber}/>
                                        </p>
                                    </dd>
                                </dl>
                                <dl className="py-4 grid grid-cols-3 items-center gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                                    <dt className="col-span-1 flex items-center">
                                        <p className="text-sm text-gray-500 dark:text-neutral-500">
                                            Role:
                                        </p>
                                    </dt>
                                    <dd className="col-span-2 flex items-center">
                                        <p className="text-sm text-gray-800 dark:text-neutral-200">
                                            {userInvitation?.role?.name}
                                        </p>
                                    </dd>
                                </dl>
                                <dl className="py-4 grid grid-cols-3 items-center gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                                    <dt className="col-span-1 flex items-center">
                                        <p className="text-sm text-gray-500 dark:text-neutral-500">
                                            Status:
                                        </p>
                                    </dt>
                                    <dd className="col-span-2 flex items-center">
                                        <p className="text-sm text-gray-800 dark:text-neutral-200">
                                            {userInvitation?.status}
                                        </p>
                                    </dd>
                                </dl>
                                {userInvitation?.supervisors?.map(supervisor => (
                                    <dl key={supervisor.id}
                                        className="py-4 grid grid-cols-3 items-center gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                                        <dt className="col-span-1 flex items-center">
                                            <p className="text-sm text-gray-500 dark:text-neutral-500">
                                                {supervisor.role.nameAlias}:
                                            </p>
                                        </dt>
                                        <dd className="col-span-2 flex items-center">
                                            <p className="text-sm text-gray-800 dark:text-neutral-200">
                                                {supervisor.user.name}
                                            </p>
                                        </dd>
                                    </dl>
                                ))}
                                <dl className="py-2 justify-start gap-2 border-t border-gray-200 dark:border-neutral-700">
                                    <div
                                        className="mt-6 mb-6 gap-1 flex items-center text-sm font-light tracking-normal leading-5 underline text-zinc-400 hover:text-cyan-400"
                                        style={{cursor: 'pointer'}} onClick={handleResend}>
                                        Resend invitation <ChevronRightIcon/>
                                    </div>
                                    <div
                                        className="mt-6 mb-6 gap-1 flex items-center text-sm font-light tracking-normal leading-5 underline text-zinc-400 hover:text-cyan-400"
                                        style={{cursor: 'pointer'}} onClick={handleCancelInvitation}>
                                        Cancel invitation <ChevronRightIcon/>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                    <footer className="flex-shrink-0 px-7 pb-4 flex justify-end gap-5">
                        <div className="border-t border-slate-200 w-full">
                            <button type="submit" onClick={onClose}
                                    className="mt-6 py-2.5 px-3 w-full inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-gray-200 bg-white
                                               text-gray-500 disabled:pointer-events-none disabled:bg-zinc-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                Close
                            </button>
                        </div>
                    </footer>
                </div>
            }
            {userInvitation === null &&
                <div className="h-full"><LoadingSpinner/></div>
            }
        </div>
    );
};

export default UserInvitationDetails;
