import React from 'react';

export const ArrowRightLineIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor"
         stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-arrow-right-from-line">
        <path d="M3 5v14"/>
        <path d="M21 12H7"/>
        <path d="m15 18 6-6-6-6"/>
    </svg>
);

export default ArrowRightLineIcon;
