import { createAsyncThunk } from '@reduxjs/toolkit';

interface EntityFactory<EntityType> {
    (data: any): EntityType;
}

interface CreateEntityThunksOptions {
    activate?: boolean;
    deactivate?: boolean;
}

export function createEntityThunks<EntityType>(
    entityName: string,
    service: any,
    options?: CreateEntityThunksOptions,
    entityFactory?: EntityFactory<EntityType>
) {
    class EntityThunks {
        static create = createAsyncThunk(
            `${entityName}/create`,
            async (data: any) => {
                return await service.create(data);
            }
        );

        static index = createAsyncThunk(
            `${entityName}/index`,
            async ({
                       page,
                       search,
                       sortField,
                       sortDirection,
                       rowsPerPage,
                   }: {
                page?: number;
                search?: any;
                sortField?: string;
                sortDirection?: string;
                rowsPerPage?: number;
            } = {}) => {
                return await service.index(
                    page,
                    search,
                    sortField,
                    sortDirection,
                    rowsPerPage
                );
            }
        );

        static show = createAsyncThunk(
            `${entityName}/fetchById`,
            async (id: string) => {
                return await service.show(id);
            }
        );

        static update = createAsyncThunk(
            `${entityName}/update`,
            async (data: any) => {
                return await service.update(data);
            }
        );

        static delete = createAsyncThunk(
            `${entityName}/delete`,
            async (id: string) => {
                return await service.delete(id);
            }
        );

        static activate = createAsyncThunk(
            `${entityName}/activate`,
            async (id: string) => {
                if (!options?.activate) {
                    throw new Error(`Activate not implemented for ${entityName}`);
                }
                const data = entityFactory
                    ? entityFactory({ id, status: 'active' })
                    : { id, status: 'active' };
                return await service.update(data);
            }
        );

        static deactivate = createAsyncThunk(
            `${entityName}/deactivate`,
            async (id: string) => {
                if (!options?.deactivate) {
                    throw new Error(`Deactivate not implemented for ${entityName}`);
                }
                const data = entityFactory
                    ? entityFactory({ id, status: 'inactive' })
                    : { id, status: 'inactive' };
                return await service.update(data);
            }
        );
    }

    return EntityThunks;
}
